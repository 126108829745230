import React, { useEffect, useState } from "react";
import Downshift from 'downshift';
import PropTypes from "prop-types";
import { getStringShortcodeLocale } from "utils";

const items = [
    { value: 'apple' },
    { value: 'pear' },
    { value: 'orange' },
    { value: 'grape' },
    { value: 'banana' },
];

function AutoComplete({ trainings, onHandleTrainingAutoComplete }) {
    return (
        <Downshift
            onChange={selection =>
                // alert(selection ? `You selected ${selection._id}` : 'Selection Cleared')
                onHandleTrainingAutoComplete(selection)
            }
            itemToString={item => (item ? item.value : '')}
        >
            {({
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem,
                getRootProps,
            }) => (
                <div>
                    <div
                        style={{ display: 'inline-block', width: '100%' }}
                        {...getRootProps({}, { suppressRefError: true })}
                    >
                        <input {...getInputProps()} type="text" className="form-control w-100" placeholder="Buscar..." />
                    </div>
                    <ul {...getMenuProps()} className="ul-downshift-list">
                        {isOpen && inputValue && inputValue.length >= 3
                            ? trainings
                                .filter(item => !inputValue || item.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()))
                                .map((item, index) => {
                                    // console.log('item', item);
                                    return (<li key={item._id}
                                        {...getItemProps({
                                            key: item.value,
                                            index,
                                            item,
                                            style: {
                                                backgroundColor:
                                                    highlightedIndex === index ? 'lightgray' : 'white',
                                                fontWeight: selectedItem === item ? 'bold' : 'normal',
                                            },
                                        })}
                                    >
                                        {getStringShortcodeLocale(item.name, 'pt')}
                                    </li>);
                                })
                            : null}
                    </ul>
                </div>
            )}
        </Downshift>
    );
}

AutoComplete.propTypes = {
    trainings: PropTypes.array,
    onHandleTrainingAutoComplete: PropTypes.func,
}


export default AutoComplete;