/* RECIPES */
export const GET_RECIPES = "GET_RECIPES"
export const GET_RECIPES_SUCCESS = "GET_RECIPES_SUCCESS"
export const GET_RECIPES_FAIL = "GET_RECIPES_FAIL"

/**
 * add RECIPES
 */
 export const ADD_NEW_RECIPES = "ADD_NEW_RECIPES"
 export const ADD_RECIPES_SUCCESS = "ADD_RECIPES_SUCCESS"
 export const ADD_RECIPES_FAIL = "ADD_RECIPES_FAIL"
 
 /**
  * Edit RECIPES
  */
 export const UPDATE_RECIPES = "UPDATE_RECIPES"
 export const UPDATE_RECIPES_SUCCESS = "UPDATE_RECIPES_SUCCESS"
 export const UPDATE_RECIPES_FAIL = "UPDATE_RECIPES_FAIL"
 
 /**
  * Delete RECIPES
  */
 export const DELETE_RECIPES = "DELETE_RECIPES"
 export const DELETE_RECIPES_SUCCESS = "DELETE_RECIPES_SUCCESS"
 export const DELETE_RECIPES_FAIL = "DELETE_RECIPES_FAIL"