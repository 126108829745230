import { 
  GET_EQUIPMENTS, 
  GET_EQUIPMENTS_SUCCESS, 
  GET_EQUIPMENTS_FAIL, 
  ADD_NEW_EQUIPMENTS, 
  ADD_EQUIPMENTS_FAIL, 
  ADD_EQUIPMENTS_SUCCESS, 
  UPDATE_EQUIPMENTS, 
  UPDATE_EQUIPMENTS_FAIL, 
  UPDATE_EQUIPMENTS_SUCCESS, 
  DELETE_EQUIPMENTS, 
  DELETE_EQUIPMENTS_SUCCESS, 
  DELETE_EQUIPMENTS_FAIL 
} from "./actionTypes"

export const getEquipments = () => ({
  type: GET_EQUIPMENTS,
})

export const getEquipmentsSuccess = tasks => ({
  type: GET_EQUIPMENTS_SUCCESS,
  payload: tasks,
})

export const getEquipmentsFail = error => ({
  type: GET_EQUIPMENTS_FAIL,
  payload: error,
})

export const addNewEquipments = category => ({
  type: ADD_NEW_EQUIPMENTS,
  payload: category,
})

export const addEquipmentsSuccess = category => ({
  type: ADD_EQUIPMENTS_SUCCESS,
  payload: category,
})

export const addEquipmentsFail = error => ({
  type: ADD_EQUIPMENTS_FAIL,
  payload: error,
})

export const updateEquipments = category => ({
  type: UPDATE_EQUIPMENTS,
  payload: category,
})

export const updateEquipmentsSuccess = category => ({
  type: UPDATE_EQUIPMENTS_SUCCESS,
  payload: category,
})

export const updateEquipmentsFail = error => ({
  type: UPDATE_EQUIPMENTS_FAIL,
  payload: error,
})

export const deleteEquipments = category => ({
  type: DELETE_EQUIPMENTS,
  payload: category,
})

export const deleteEquipmentsSuccess = category => ({
  type: DELETE_EQUIPMENTS_SUCCESS,
  payload: category,
})

export const deleteEquipmentsFail = error => ({
  type: DELETE_EQUIPMENTS_FAIL,
  payload: error,
})
