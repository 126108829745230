/* RECIPES */
export const GET_STRETCHING = "GET_STRETCHING"
export const GET_STRETCHING_SUCCESS = "GET_STRETCHING_SUCCESS"
export const GET_STRETCHING_FAIL = "GET_STRETCHING_FAIL"

/**
 * add STRETCHING
 */
 export const ADD_NEW_STRETCHING = "ADD_NEW_STRETCHING"
 export const ADD_STRETCHING_SUCCESS = "ADD_STRETCHING_SUCCESS"
 export const ADD_STRETCHING_FAIL = "ADD_STRETCHING_FAIL"
 
 /**
  * Edit STRETCHING
  */
 export const UPDATE_STRETCHING = "UPDATE_STRETCHING"
 export const UPDATE_STRETCHING_SUCCESS = "UPDATE_STRETCHING_SUCCESS"
 export const UPDATE_STRETCHING_FAIL = "UPDATE_STRETCHING_FAIL"
 
 /**
  * Delete STRETCHING
  */
 export const DELETE_STRETCHING = "DELETE_STRETCHING"
 export const DELETE_STRETCHING_SUCCESS = "DELETE_STRETCHING_SUCCESS"
 export const DELETE_STRETCHING_FAIL = "DELETE_STRETCHING_FAIL"