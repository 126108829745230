import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_WORKOUTS, GET_WORKOUTS_SEARCH, GET_TOTAL_WORKOUTS, } from "./actionTypes"
import { getWorkoutsFail, getWorkoutsSuccess, getWorkoutsSearchSuccess,
  getWorkoutsSearchFail,  getTotalWorkoutsSuccess,
  getTotalWorkoutsFail, } from "./actions"

import {
  getWorkouts, getWorkoutsSearch, getTotalWorkouts
} from '../../api/workouts';

function* fetchTotalWorkouts() {
  try {
     const response = yield call(getTotalWorkouts);
     yield put(getTotalWorkoutsSuccess(response.data.total));
  } catch (error) {
     yield put(getTotalWorkoutsFail(error));
  }
}

function* fetchWorkouts() {  
  try {
    const response = yield call(getWorkouts)
    yield put(getWorkoutsSuccess(response.data.workouts))
  } catch (error) {
    yield put(getWorkoutsFail(error))
  }
}

function* fetchWorkoutsSearch({ search }) {
  try {
     const response = yield call(getWorkoutsSearch, search);
     yield put(getWorkoutsSearchSuccess(response.data.workoutsSearch));
  } catch (error) {
     yield put(getWorkoutsSearchFail(error));
  }
}

function* tasksSaga() {    
  yield takeEvery(GET_TOTAL_WORKOUTS, fetchTotalWorkouts);
  yield takeEvery(GET_WORKOUTS, fetchWorkouts);
  yield takeEvery(GET_WORKOUTS_SEARCH, fetchWorkoutsSearch);
  
}

export default tasksSaga
