/* Workouts */
export const GET_WORKOUTS = "GET_WORKOUTS"
export const GET_WORKOUTS_SUCCESS = "GET_WORKOUTS_SUCCESS"
export const GET_WORKOUTS_FAIL = "GET_WORKOUTS_FAIL"

/* TOTAL WORKOUTS */
export const GET_TOTAL_WORKOUTS = "GET_TOTAL_WORKOUTS"
export const GET_TOTAL_WORKOUTS_SUCCESS = "GET_TOTAL_WORKOUTS_SUCCESS"
export const GET_TOTAL_WORKOUTS_FAIL = "GET_TOTAL_WORKOUTS_FAIL"

/**
 * add Workouts
 */
export const ADD_NEW_WORKOUTS = "ADD_NEW_WORKOUTS"
export const ADD_WORKOUTS_SUCCESS = "ADD_WORKOUTS_SUCCESS"
export const ADD_WORKOUTS_FAIL = "ADD_WORKOUTS_FAIL"

/**
 * Edit Workouts
 */
export const UPDATE_WORKOUTS = "UPDATE_WORKOUTS"
export const UPDATE_WORKOUTS_SUCCESS = "UPDATE_WORKOUTS_SUCCESS"
export const UPDATE_WORKOUTS_FAIL = "UPDATE_WORKOUTS_FAIL"

/**
 * Delete Workouts
 */
export const DELETE_WORKOUTS = "DELETE_WORKOUTS"
export const DELETE_WORKOUTS_SUCCESS = "DELETE_WORKOUTS_SUCCESS"
export const DELETE_WORKOUTS_FAIL = "DELETE_WORKOUTS_FAIL"

/* Workouts SEARCH*/
export const GET_WORKOUTS_SEARCH = "GET_WORKOUTS_SEARCH"
export const GET_WORKOUTS_SEARCH_SUCCESS = "GET_WORKOUTS_SEARCH_SUCCESS"
export const GET_WORKOUTS_SEARCH_FAIL = "GET_WORKOUTS_SEARCH_FAIL"
export const GET_WORKOUTS_SEARCH_CLEAN = "GET_WORKOUTS_SEARCH_CLEAN"