import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
   Container,
   Row,
   Col,
   Card,
   Alert,
   CardBody,
   Media,
   Button,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/icon-logo.png";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"

const UserProfile = props => {
   const dispatch = useDispatch()

   const { error, success } = useSelector(state => ({
      error: state.Profile.error,
      success: state.Profile.success,
   }))

   const [email, setemail] = useState("")
   const [name, setname] = useState("")
   const [idx, setidx] = useState(1)

   useEffect(() => {
      if (localStorage.getItem("authUser")) {
         const obj = JSON.parse(localStorage.getItem("authUser"))
         if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            setname(obj.displayName)
            setemail(obj.email)
            setidx(obj.uid)
         } else if (
            process.env.REACT_APP_DEFAULTAUTH === "fake" ||
            process.env.REACT_APP_DEFAULTAUTH === "jwt"
         ) {
            setname(obj.username)
            setemail(obj.email)
            setidx(obj.uid)
         }
         setTimeout(() => {
            dispatch(resetProfileFlag())
         }, 3000)
      }
   }, [dispatch, success])

   function handleValidSubmit(event, values) {
      dispatch(editProfile(values))
   }

   return (
      <React.Fragment>
         <div className="page-content">
            <MetaTags>
               <title>Perfil | Suore</title>
            </MetaTags>
            <Container fluid>
               {/* Render Breadcrumb */}
               <Breadcrumb title="Suore" breadcrumbItem="Perfil" />

               <Row>
                  <Col lg="12">
                     {error && error ? <Alert color="danger">{error}</Alert> : null}
                     {success ? <Alert color="success">{success}</Alert> : null}

                     <Card>
                        <CardBody>
                           <Media>
                              <div className="ms-3 mr-6">
                                 <img
                                    src={avatar}
                                    alt=""
                                    className="avatar-md rounded-circle img-thumbnail"
                                 />
                              </div>
                              <Media body className="align-self-center">
                                 <div className="text-muted">
                                    <h5>{name}</h5>
                                    <p className="mb-1">{email}</p>
                                    <p className="mb-0">Id no: #{idx}</p>
                                 </div>
                              </Media>
                           </Media>
                        </CardBody>
                     </Card>
                  </Col>
               </Row>

               <h4 className="card-title mb-4">Atualizar usuário</h4>

               <Card>
                  <CardBody>
                     <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                           handleValidSubmit(e, v)
                        }}
                     >
                        <div className="form-group">
                           <AvField
                              name="username"
                              label="Email"
                              value={email}
                              className="form-control"
                              placeholder="Digite novo email"
                              type="text"
                              required
                           />
                           <AvField name="idx" value={idx} type="hidden" />
                        </div>
                        <div className="text-center mt-4">
                           <Button type="submit" color="danger">
                              Atualizar usuário
                           </Button>
                        </div>
                     </AvForm>
                  </CardBody>
               </Card>
            </Container>
         </div>
      </React.Fragment>
   )
}

export default withRouter(UserProfile)
