import { 
    GET_RECIPES, 
    GET_RECIPES_SUCCESS, 
    GET_RECIPES_FAIL, 
    ADD_NEW_RECIPES, 
    ADD_RECIPES_FAIL, 
    ADD_RECIPES_SUCCESS, 
    UPDATE_RECIPES, 
    UPDATE_RECIPES_FAIL, 
    UPDATE_RECIPES_SUCCESS, 
    DELETE_RECIPES, 
    DELETE_RECIPES_SUCCESS, 
    DELETE_RECIPES_FAIL 
  } from "./actionTypes"
  
  export const getRecipes = () => ({
    type: GET_RECIPES,
  })
  
  export const getRecipesSuccess = tasks => ({
    type: GET_RECIPES_SUCCESS,
    payload: tasks,
  })
  
  export const getRecipesFail = error => ({
    type: GET_RECIPES_FAIL,
    payload: error,
  })
  
  export const addNewRecipes = category => ({
    type: ADD_NEW_RECIPES,
    payload: category,
  })
  
  export const addRecipesSuccess = category => ({
    type: ADD_RECIPES_SUCCESS,
    payload: category,
  })
  
  export const addRecipesFail = error => ({
    type: ADD_RECIPES_FAIL,
    payload: error,
  })
  
  export const updateRecipes = category => ({
    type: UPDATE_RECIPES,
    payload: category,
  })
  
  export const updateRecipesSuccess = category => ({
    type: UPDATE_RECIPES_SUCCESS,
    payload: category,
  })
  
  export const updateRecipesFail = error => ({
    type: UPDATE_RECIPES_FAIL,
    payload: error,
  })
  
  export const deleteRecipes = category => ({
    type: DELETE_RECIPES,
    payload: category,
  })
  
  export const deleteRecipesSuccess = category => ({
    type: DELETE_RECIPES_SUCCESS,
    payload: category,
  })
  
  export const deleteRecipesFail = error => ({
    type: DELETE_RECIPES_FAIL,
    payload: error,
  })