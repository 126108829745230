import {
   GET_TRAININGS_FAIL,
   GET_TRAININGS_SUCCESS,
   ADD_TRAINING_SUCCESS,
   ADD_TRAINING_FAIL,
   UPDATE_TRAINING_SUCCESS,
   UPDATE_TRAINING_FAIL,
   DELETE_TRAINING_SUCCESS,
   DELETE_TRAINING_FAIL,
} from "./actionTypes"

const INIT_STATE = {
   trainings: [],
   error: {},
}

const Training = (state = INIT_STATE, action) => {
   switch (action.type) {

      case GET_TRAININGS_SUCCESS:
         return {
            ...state,
            trainings: action.payload,
         }

      case GET_TRAININGS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case ADD_TRAINING_SUCCESS:
         return {
            ...state,
            trainings: [...state.trainings, action.payload],
         }

      case ADD_TRAINING_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case UPDATE_TRAINING_SUCCESS:
         return {
            ...state,
            trainings: state.trainings.map(training =>
               trainer.id.toString() === action.payload.id.toString()
                  ? { training, ...action.payload }
                  : training
            ),
         }

      case UPDATE_TRAINING_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case UPDATE_TRAINING_SUCCESS:
         return {
            ...state,
            trainings: state.trainings.filter(
               customer => customer.id.toString() !== action.payload.id.toString()
            ),
         }

      case DELETE_TRAINING_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      default:
         return state
   }
}

export default Training;
