import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_WORKOUTSLEVELS } from "./actionTypes"
import { getWorkoutsLevelsFail, getWorkoutsLevelsSuccess } from "./actions"

import {
  getWorkoutsLevels,
} from '../../api/workoutsLevels';

function* fetchWorkoutsLevels() {  
  try {
    const response = yield call(getWorkoutsLevels)    
    yield put(getWorkoutsLevelsSuccess(response.data.levels))
  } catch (error) {
    yield put(getWorkoutsLevelsFail(error))
  }
}

function* tasksSaga() {   
  yield takeEvery(GET_WORKOUTSLEVELS, fetchWorkoutsLevels)
}

export default tasksSaga
