import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Label
} from "reactstrap";

import {
    getWorkouts as onGetWorkouts,
 } from "store/workouts/actions";

import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { editWorkoutsText } from "api/workouts";
import { useDispatch } from "react-redux";
import { getStringShortcodeLocale } from "utils";
import SuccessMessage from "components/SuccessMessage";
import Loading from "components/Loading";



const FormTextWorkouts  = ({ isEdit, toggle, workoutsItem })=> {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const dispatch = useDispatch();
 
    const [, setIsEditLocal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
 
    useEffect(() => {
       setIsEditLocal(isEdit);      

       const text_intro = workoutsItem?.text_intro && workoutsItem?.text_intro.indexOf("[pt]") > -1 ? getStringShortcodeLocale(workoutsItem?.text_intro, 'pt'):workoutsItem?.text_intro;
       const text_introEn = workoutsItem?.text_intro && workoutsItem?.text_intro.indexOf("[en]") > -1 ? getStringShortcodeLocale(workoutsItem?.text_intro, 'en'):"";
       const text_introEs = workoutsItem?.text_intro && workoutsItem?.text_intro.indexOf("[es]") > -1 ? getStringShortcodeLocale(workoutsItem?.text_intro, 'es'):"";

       reset({         
         text_intro: isEdit ? text_intro:'',
         text_introEn: isEdit ? text_introEn:'',
         text_introEs: isEdit ? text_introEs:'', 
              
       });
    }, [isEdit, workoutsItem]);   
 
    /**
     * Handling submit customer on customer form
     */
    const handleValidCustomerSubmit = async (data) => { 
      setSuccessMessage(false);
       if (data.text_intro) {   
         setIsLoading(true);     
         const textFinal = `[pt]${data.text_intro}[:pt][en]${data.text_introEn}[:en][es]${data.text_introEs}[:es]`;
         
          if(!isEdit)
          {
             await addWorkoutsText({                
                text_intro: textFinal              
             });
          } else {
             await editWorkoutsText({
                _id: workoutsItem._id,                
                text_intro: textFinal                
             });
          }
          setIsLoading(false);
          setSuccessMessage(true);
          dispatch(onGetWorkouts());  
         //  toggle();        
       }
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(handleValidCustomerSubmit)}
            >
                <Row form>
                <Col className="col-12">
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="text_intro" className="">Texto Introdutorio</label>
                           <textarea {...register("text_intro", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.text_intro && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="text_introEn" className="">Texto Introdutorio Ingles</label>
                           <textarea {...register("text_introEn", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.text_introEn && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="text_introEs" className="">Texto Introdutorio Espanhol</label>
                           <textarea {...register("text_introEs", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.text_introEs && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>                     
                  </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {successMessage && <SuccessMessage />}
                        <div className="text-end">
                            {!isLoading && <button
                                type="submit"
                                className="btn btn-success save-customer"
                            >
                                Salvar
                            </button>}
                            {isLoading && <Loading />}
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    );
}

FormTextWorkouts.propTypes = {
    toggle: PropTypes.func,
    isEdit: PropTypes.bool,
    workoutsItem: PropTypes.object,
 }

export default FormTextWorkouts;