import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Users
import users from "./users/reducer"

//categories workouts
import categoriesWorkouts from "./categoriesWorkouts/reducer"

//categories
import categories from "./categories/reducer"

//trainers
import trainers from "./trainers/reducer"

//trainings
import trainings from "./trainings/reducer"

//modalities
import modalities from "./modalities/reducer"

//Recipes 
import recipes from "./recipes/reducer";

//Workout 
import workouts from "./workouts/reducer";

//Workout 
import workoutsDays from "./workoutsDays/reducer";

//Workout 
import workoutsLevels from "./workoutsLevels/reducer";

//equipments 
import equipaments from "./equipments/reducer";

//stretching 
import stretching from "./stretching/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

const rootReducer = combineReducers({
  // public
  Login,
  Account,
  ForgetPassword,
  Profile,
  categoriesWorkouts,
  trainers,
  Dashboard,
  modalities,
  Layout,
  users,
  trainings,
  categories,
  recipes,  
  workouts,
  workoutsDays,
  workoutsLevels,
  equipaments,
  stretching
})

export default rootReducer
