import {post, get, del} from './index';

async function getCategories() {
   return await get('/gerenciador/categories');
}

async function getCategoriesByType(type) {
   return await get(`/gerenciador/categories/by/type/${type}`);
}

async function addCategories(obj) {
   return await post(`/gerenciador/categories/add`, obj);
}

async function editCategories(obj) {
   return await post(`/gerenciador/categories/edit`, obj);
}

async function deleteCategories(obj) {
   return await del(`/gerenciador/categories/delete`, obj);
}

export {
    getCategories,
    addCategories,
    editCategories,
    deleteCategories,
    getCategoriesByType
}