import React from 'react';

const Loading = () => {
  return (
    <div className="loading">
        <img src="/loading.svg" alt="Loading" width={45} height={45} />
    </div>
  );
}

export default Loading;