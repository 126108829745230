import React, { useEffect, useState } from "react";
import {
    Col,
    Row,    
} from "reactstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { relationsWorkout } from "api/workouts";
import Loading from "components/Loading";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useSelector } from "react-redux";
import SuccessMessage from "components/SuccessMessage";

import {
    getWorkouts as onGetWorkouts,
 } from "store/workouts/actions"
 
import { useDispatch } from "react-redux";

const animatedComponents = makeAnimated();

const options = [
    {
        options: []
    }
];
const optionsWorkout = [
    {
        options: []
    }
];
const optionsTrainer = [
    {
        options: []
    }
];
const optionsModality = [
    {
        options: []
    }
];
const optionsLevels = [
    {
        options: []
    }
];

const FormCategoriasWorkouts = ({ isEdit, workoutsItem, toggle }) => {
    const dispatch = useDispatch();    
    

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

    const categoriesSelected = workoutsItem && !workoutsItem.categories ? null:
    workoutsItem.categories.map(item=>{
            return (
                {
                    label: item.name,
                    value: item._id
                }
            )           
        })
        
    const categoriesWorkoutSelected = workoutsItem && !workoutsItem.categories_workouts ? null:       
    workoutsItem.categories_workouts.map(item=>{                       
                return (
                    {
                        label: item.name,
                        value: item._id
                    }
                )           
            }) 

    const trainersSelected = workoutsItem && !workoutsItem.trainers_workouts ? null:
            workoutsItem.trainers_workouts.map(item=>{
                    return (
                        {
                            label: item.name,
                            value: item._id
                        }
                    )           
                }) 

    const modalitySelected = workoutsItem && !workoutsItem.modality_workouts ? null:
                workoutsItem.modality_workouts.map(item=>{
                        return (
                            {
                                label: item.name,
                                value: item._id
                            }
                        )           
                    })  
                    
    const levelsSelected = workoutsItem && !workoutsItem.workoutlevels_workouts ? null:
    workoutsItem.workoutlevels_workouts.map(item=>{
            return (
                {
                    label: item.name,
                    value: item._id
                }
            )           
        })

    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [selectedValue, setSelectedValue] = useState(categoriesSelected ? categoriesSelected:null);
    const [selectedCategorieValue, setSelectedCategorieValue] = useState(categoriesWorkoutSelected ? categoriesWorkoutSelected:null);
    const [trainersSelectedValue, setSelectedTrainersValue] = useState(trainersSelected ? trainersSelected:null);
    const [selectedModalityValue, setSelectedModalityValue] = useState(modalitySelected ? modalitySelected:null);
    const [selectedLevelValue, setSelectedLevelValue] = useState(levelsSelected ? levelsSelected:null);
  
    
    const { categories } = useSelector(state => ({
        categories: state.categories.categories,
    })); 
    
    const { categoriesWorkouts } = useSelector(state => ({
        categoriesWorkouts: state.categoriesWorkouts.categoriesWorkouts,
     }))     

    const { trainers } = useSelector(state => ({
        trainers: state.trainers.trainers,
     }))

     const { modalities } = useSelector(state => ({
        modalities: state.modalities.modalities,
     }))

     const { workoutsLevels } = useSelector(state => ({
        workoutsLevels: state.workoutsLevels.workoutsLevels,
     }))
     
     useEffect(()=>{
        if(workoutsLevels) {
            optionsLevels[0].options = [];
            workoutsLevels.map(item=>{                
                    optionsLevels[0].options.push(
                        {
                            label: item.name,
                            value: item._id,
                        }
                    );                             
            });
        }       
    },[workoutsLevels]);
    

    useEffect(()=>{
        if(categories) {
            options[0].options = [];
            categories.map(item=>{
                if(item.type === 'workouts') {
                    options[0].options.push(
                        {
                            label: item.name,
                            value: item._id,
                        }
                    );
                }               
            });
        }       
    },[categories]);

    useEffect(()=>{
        if(categoriesWorkouts) {
            optionsWorkout[0].options = [];
            categoriesWorkouts.map(item=>{               
                optionsWorkout[0].options.push(
                        {
                            label: item.name,
                            value: item._id,
                        }
                    );                            
            });
        }       
    },[categoriesWorkouts]);

    useEffect(()=>{
        if(trainers) {
            optionsTrainer[0].options = [];
            trainers.map(item=>{               
                optionsTrainer[0].options.push(
                        {
                            label: item.name,
                            value: item._id,
                        }
                    );
                            
            });
        }       
    },[trainers]);

    useEffect(()=>{
        if(modalities) {
            optionsModality[0].options = [];
            modalities.map(item=>{               
                optionsModality[0].options.push(
                        {
                            label: item.name,
                            value: item._id,
                        }
                    );
                            
            });
        }       
    },[modalities]);    

    const handleValidCustomerSubmit = async (data) => {
        setSuccessMessage(false);

        if (selectedValue) {                      
            setIsLoading(true);

            await relationsWorkout({
                _id: workoutsItem._id,
                categories: selectedValue,
                categoriesWorkouts: selectedCategorieValue,
                trainers: trainersSelectedValue,
                modalities: selectedModalityValue,
                workoutsLevels: selectedLevelValue               
            });        
            setIsLoading(false);
            setSuccessMessage(true);
            dispatch(onGetWorkouts());           
        }      
     
    }

    function handleMulti(selected) {
        setSelectedValue(selected);
    }

    function handleMultiWorkout(selected) {
        setSelectedCategorieValue(selected);
    }          
   
    return (
        <>
            <form
                onSubmit={handleSubmit(handleValidCustomerSubmit)}
            >
                <Row form>
                    <Col className="col-12">
                        <div className="mt-3">
                            <div className="form-group">
                                <div className="mb-3 templating-select select2-container">
                                    <label htmlFor="control-label">Adicione as categorias</label>
                                    <Select
                                        value={selectedValue}
                                        isMulti={true}
                                        onChange={(event)=>handleMulti(event)}
                                        options={options}
                                        classNamePrefix="select2-selection"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                    />
                                </div>
                                <div className="mb-3 templating-select select2-container">
                                    <label htmlFor="control-label">Adicione Local do Treino</label>
                                    <Select
                                        value={selectedCategorieValue}
                                        isMulti={true}
                                        onChange={(event)=>handleMultiWorkout(event)}
                                        options={optionsWorkout}
                                        classNamePrefix="select2-selection"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                    />
                                </div>
                                <div className="mb-3 templating-select select2-container">
                                    <label htmlFor="control-label">Adicione o Treinador(a)</label>
                                    <Select
                                        value={trainersSelectedValue}
                                        isMulti={false}
                                        onChange={(selected) => setSelectedTrainersValue([selected])}
                                        options={optionsTrainer}
                                        classNamePrefix="select2-selection"
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                    />
                                </div>
                                <div className="mb-3 templating-select select2-container">
                                    <label htmlFor="control-label">Adicione a Modalidade</label>
                                    <Select
                                        value={selectedModalityValue}
                                        isMulti={false}
                                        onChange={(selected) => setSelectedModalityValue([selected])}
                                        options={optionsModality}
                                        classNamePrefix="select2-selection"
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                    />
                                </div> 
                                <div className="mb-3 templating-select select2-container">
                                    <label htmlFor="control-label">Adicione o Level</label>
                                    <Select
                                        value={selectedLevelValue}
                                        isMulti={false}
                                        onChange={(selected) => setSelectedLevelValue([selected])}
                                        options={optionsLevels}
                                        classNamePrefix="select2-selection"
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                    />
                                </div>                               
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {successMessage && <SuccessMessage />}
                        <div className="text-end">
                            {!isLoading && <button
                                type="submit"
                                className="btn btn-success save-customer"
                            >
                                Salvar
                            </button>}
                            {isLoading && <Loading />}
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    );
}

FormCategoriasWorkouts.propTypes = {
    toggle: PropTypes.func,
    isEdit: PropTypes.bool,
    workoutsItem: PropTypes.object,
}

export default FormCategoriasWorkouts;