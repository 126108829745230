import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
   return (
      <React.Fragment>
         <footer className="footer">
            <div className="container-fluid">
               <Row>
                  <Col sm={6}>{new Date().getFullYear()} © Suore.</Col>
               </Row>
            </div>
         </footer>
      </React.Fragment>
   )
}

export default Footer
