import { 
  GET_WORKOUTSLEVELS,
  GET_WORKOUTSLEVELS_FAIL,
  GET_WORKOUTSLEVELS_SUCCESS,
  ADD_NEW_WORKOUTSLEVELS,
  ADD_WORKOUTSLEVELS_SUCCESS,
  ADD_WORKOUTSLEVELS_FAIL,
  UPDATE_WORKOUTSLEVELS,
  UPDATE_WORKOUTSLEVELS_SUCCESS,
  UPDATE_WORKOUTSLEVELS_FAIL,
  DELETE_WORKOUTSLEVELS,
  DELETE_WORKOUTSLEVELS_SUCCESS,
  DELETE_WORKOUTSLEVELS_FAIL,
 } from "./actionTypes"
 
 export const getWorkoutsLevels = () => ({
   type: GET_WORKOUTSLEVELS,
 })
 
 export const getWorkoutsLevelsSuccess = tasks => ({
   type: GET_WORKOUTSLEVELS_SUCCESS,
   payload: tasks,
 })
 
 export const getWorkoutsLevelsFail = error => ({
   type: GET_WORKOUTSLEVELS_FAIL,
   payload: error,
 })
 
 export const addNewWorkoutsLevels = workoutsLevels => ({
   type: ADD_NEW_WORKOUTSLEVELS,
   payload: workoutsLevels,
 })
 
 export const addWorkoutsLevelsSuccess = workoutsLevels => ({
   type: ADD_WORKOUTSLEVELS_SUCCESS,
   payload: workoutsLevels,
 })
 
 export const addWorkoutsLevelsFail = error => ({
   type: ADD_WORKOUTSLEVELS_FAIL,
   payload: error,
 })
 
 export const updateWorkoutsLevels = workoutsLevels => ({
   type: UPDATE_WORKOUTSLEVELS,
   payload: workoutsLevels,
 })
 
 export const updateWorkoutsLevelsSuccess = workoutsLevels => ({
   type: UPDATE_WORKOUTSLEVELS_SUCCESS,
   payload: workoutsLevels,
 })
 
 export const updateWorkoutsLevelsFail = error => ({
   type: UPDATE_WORKOUTSLEVELS_FAIL,
   payload: error,
 })
 
 export const deleteWorkoutsLevels = workoutsLevels => ({
   type: DELETE_WORKOUTSLEVELS,
   payload: workoutsLevels,
 })
 
 export const deleteWorkoutsLevelsSuccess = workoutsLevels => ({
   type: DELETE_WORKOUTSLEVELS_SUCCESS,
   payload: workoutsLevels,
 })
 
 export const deleteWorkoutsLevelsFail = error => ({
   type: DELETE_WORKOUTSLEVELS_FAIL,
   payload: error,
 })