import {
   GET_TRAININGS,
   GET_TRAININGS_FAIL,
   GET_TRAININGS_SUCCESS,
   ADD_NEW_TRAINING,
   ADD_TRAINING_SUCCESS,
   ADD_TRAINING_FAIL,
   UPDATE_TRAINING,
   UPDATE_TRAINING_SUCCESS,
   UPDATE_TRAINING_FAIL,
   DELETE_TRAINING,
   DELETE_TRAINING_SUCCESS,
   DELETE_TRAINING_FAIL,
} from "./actionTypes"

export const getTrainings = () => ({
   type: GET_TRAININGS,
})

export const getTrainingsSuccess = Trainings => ({
   type: GET_TRAININGS_SUCCESS,
   payload: Trainings,
})

export const getTrainingsFail = error => ({
   type: GET_TRAININGS_FAIL,
   payload: error,
})

export const addNewTraining = customer => ({
   type: ADD_NEW_TRAINING,
   payload: customer,
})

export const addTrainerSuccess = customer => ({
   type: ADD_TRAINING_SUCCESS,
   payload: customer,
})

export const addTrainingFail = error => ({
   type: ADD_TRAINING_FAIL,
   payload: error,
})

export const updateTraining = customer => ({
   type: UPDATE_TRAINING,
   payload: customer,
})

export const updateTrainingSuccess = customer => ({
   type: UPDATE_TRAINING_SUCCESS,
   payload: customer,
})

export const updateTrainingFail = error => ({
   type: UPDATE_TRAINING_FAIL,
   payload: error,
})

export const deleteCustomer = customer => ({
   type: DELETE_TRAINING,
   payload: customer,
})

export const deleteTrainingSuccess = customer => ({
   type: DELETE_TRAINING_SUCCESS,
   payload: customer,
})

export const deleteCustomerFail = error => ({
   type: DELETE_TRAINING_FAIL,
   payload: error,
})