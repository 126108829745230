/* Categories Workouts */
export const GET_MODALITIES = "GET_MODALITIES"
export const GET_MODALITIES_SUCCESS = "GET_MODALITIES_SUCCESS"
export const GET_MODALITIES_FAIL = "GET_MODALITIES_FAIL"

/**
 * add Categories Workouts
 */
 export const ADD_NEW_MODALITIES = "ADD_NEW_MODALITIES"
 export const ADD_MODALITIES_SUCCESS = "ADD_MODALITIES_SUCCESS"
 export const ADD_MODALITIES_FAIL = "ADD_MODALITIES_FAIL"
 
 /**
  * Edit Categories Workouts
  */
 export const UPDATE_MODALITIES = "UPDATE_MODALITIES"
 export const UPDATE_MODALITIES_SUCCESS = "UPDATE_MODALITIES_SUCCESS"
 export const UPDATE_MODALITIES_FAIL = "UPDATE_MODALITIES_FAIL"
 
 /**
  * Delete Categories Workouts
  */
 export const DELETE_MODALITIES = "DELETE_MODALITIES"
 export const DELETE_MODALITIES_SUCCESS = "DELETE_MODALITIES_SUCCESS"
 export const DELETE_MODALITIES_FAIL = "DELETE_MODALITIES_FAIL"