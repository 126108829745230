import { 
    GET_STRETCHING, 
    GET_STRETCHING_SUCCESS, 
    GET_STRETCHING_FAIL, 
    ADD_NEW_STRETCHING, 
    ADD_STRETCHING_FAIL, 
    ADD_STRETCHING_SUCCESS, 
    UPDATE_STRETCHING, 
    UPDATE_STRETCHING_FAIL, 
    UPDATE_STRETCHING_SUCCESS, 
    DELETE_STRETCHING, 
    DELETE_STRETCHING_SUCCESS, 
    DELETE_STRETCHING_FAIL 
  } from "./actionTypes"
  
  export const getStretching = () => ({
    type: GET_STRETCHING,
  })
  
  export const getStretchingSuccess = tasks => ({
    type: GET_STRETCHING_SUCCESS,
    payload: tasks,
  })
  
  export const getStretchingFail = error => ({
    type: GET_STRETCHING_FAIL,
    payload: error,
  })
  
  export const addNewStretching = stretching => ({
    type: ADD_NEW_STRETCHING,
    payload: stretching,
  })
  
  export const addStretchingSuccess = stretching => ({
    type: ADD_STRETCHING_SUCCESS,
    payload: stretching,
  })
  
  export const addStretchingFail = error => ({
    type: ADD_STRETCHING_FAIL,
    payload: error,
  })
  
  export const updateStretching = stretching => ({
    type: UPDATE_STRETCHING,
    payload: stretching,
  })
  
  export const updateStretchingSuccess = stretching => ({
    type: UPDATE_STRETCHING_SUCCESS,
    payload: stretching,
  })
  
  export const updateStretchingFail = error => ({
    type: UPDATE_STRETCHING_FAIL,
    payload: error,
  })
  
  export const deleteStretching = stretching => ({
    type: DELETE_STRETCHING,
    payload: stretching,
  })
  
  export const deleteStretchingSuccess = stretching => ({
    type: DELETE_STRETCHING_SUCCESS,
    payload: stretching,
  })
  
  export const deleteStretchingFail = error => ({
    type: DELETE_STRETCHING_FAIL,
    payload: error,
  })