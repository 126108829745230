import {
   GET_CUSTOMERS_FAIL,
   GET_CUSTOMERS_SUCCESS,
   ADD_CUSTOMER_SUCCESS,
   ADD_CUSTOMER_FAIL,
   UPDATE_CUSTOMER_SUCCESS,
   UPDATE_CUSTOMER_FAIL,
   DELETE_CUSTOMER_SUCCESS,
   DELETE_CUSTOMER_FAIL,
   GET_TOTAL_CUSTOMERS,
   GET_TOTAL_CUSTOMERS_SUCCESS,
   GET_TOTAL_CUSTOMERS_FAIL,
   GET_CUSTOMER_DETAIL,
   GET_CUSTOMER_DETAIL_SUCCESS,
   GET_CUSTOMER_DETAIL_FAIL,
   GET_CUSTOMER_PROPERTIES,
   GET_CUSTOMER_PROPERTIES_SUCCESS,
   GET_CUSTOMER_PROPERTIES_FAIL,
   GET_CUSTOMER_SCHEDULE,
   GET_CUSTOMER_SCHEDULE_SUCCESS,
   GET_CUSTOMER_SCHEDULE_FAIL,
   GET_CUSTOMER_MEDIAS,
   GET_CUSTOMER_MEDIAS_SUCCESS,
   GET_CUSTOMER_MEDIAS_FAIL,
   GET_CUSTOMER_TOKENS,
   GET_CUSTOMER_TOKENS_SUCCESS,
   GET_CUSTOMER_TOKENS_FAIL,
   GET_CUSTOMER_SUBSCRIBES,
   GET_CUSTOMER_SUBSCRIBES_SUCCESS,
   GET_CUSTOMER_SUBSCRIBES_FAIL,
   GET_CUSTOMERS_SEARCH,
   GET_CUSTOMERS_SEARCH_SUCCESS,
   GET_CUSTOMERS_SEARCH_FAIL,
   GET_CUSTOMERS_SEARCH_CLEAN,
   GET_CUSTOMER_SUBSCRIBES_ADD,
   GET_CUSTOMER_SUBSCRIBES_ADD_SUCCESS,
   GET_CUSTOMER_SUBSCRIBES_ADD_FAIL,
   GET_CUSTOMER_SUBSCRIBES_EDIT,
   GET_CUSTOMER_SUBSCRIBES_EDIT_SUCCESS,
   GET_CUSTOMER_SUBSCRIBES_EDIT_FAIL
} from "./actionTypes"

const INIT_STATE = {
   customers: [],
   customerDetail: {},
   customersSearch: [],
   subscribes: [],
   total: 0,
}

const Users = (state = INIT_STATE, action) => {
   switch (action.type) {

      case GET_TOTAL_CUSTOMERS:
         return {
            ...state,
            total: action.payload,
         }

      case GET_TOTAL_CUSTOMERS_SUCCESS:
         return {
            ...state,
            total: action.payload,
         }

      case GET_TOTAL_CUSTOMERS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case GET_CUSTOMERS_SUCCESS:
         return {
            ...state,
            customers: action.payload,
         }

      case GET_CUSTOMERS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case ADD_CUSTOMER_SUCCESS:
         return {
            ...state,
            customers: [...state.customers, action.payload],
         }

      case ADD_CUSTOMER_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case UPDATE_CUSTOMER_SUCCESS:
         return {
            ...state,
            customers: state.customers.map(customer =>
               customer.id.toString() === action.payload.id.toString()
                  ? { customer, ...action.payload }
                  : customer
            ),
         }

      case UPDATE_CUSTOMER_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case DELETE_CUSTOMER_SUCCESS:
         return {
            ...state,
            customers: state.customers.filter(
               customer => customer.id.toString() !== action.payload.id.toString()
            ),
         }

      case DELETE_CUSTOMER_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case GET_CUSTOMER_DETAIL:        
         return {
            ...state,
            customerDetail: action.payload,
         }
   
      case GET_CUSTOMER_DETAIL_SUCCESS:        
         return {
            ...state,
            customerDetail: { ...action.payload },
         }
   
      case GET_CUSTOMER_DETAIL_FAIL:
         return {
            ...state,
            error: action.payload,
         }

         case GET_CUSTOMER_PROPERTIES:            
            return {
               ...state,
               properties: action.payload,
            }
      
         case GET_CUSTOMER_PROPERTIES_SUCCESS:            
            return {
               ...state,
               properties: { ...action.payload },
            }
      
         case GET_CUSTOMER_PROPERTIES_FAIL:
            return {
               ...state,
               error: action.payload,
            }        
            case GET_CUSTOMER_SCHEDULE:              
               return {
                  ...state,
                  schedules: action.payload,
               }
         
            case GET_CUSTOMER_SCHEDULE_SUCCESS:               
               return {
                  ...state,
                  schedules: { ...action.payload },
               }
         
            case GET_CUSTOMER_SCHEDULE_FAIL:
               return {
                  ...state,
                  error: action.payload,
               } 
               
               case GET_CUSTOMER_MEDIAS:              
               return {
                  ...state,
                  medias: action.payload,
               }
         
            case GET_CUSTOMER_MEDIAS_SUCCESS:               
               return {
                  ...state,
                  medias: { ...action.payload },
               }
         
            case GET_CUSTOMER_MEDIAS_FAIL:
               return {
                  ...state,
                  error: action.payload,
               } 
               
               case GET_CUSTOMER_TOKENS:              
               return {
                  ...state,
                  tokens: action.payload,
               }
         
            case GET_CUSTOMER_TOKENS_SUCCESS:               
               return {
                  ...state,
                  tokens: { ...action.payload },
               }
         
            case GET_CUSTOMER_TOKENS_FAIL:
               return {
                  ...state,
                  error: action.payload,
               }    

      case GET_CUSTOMER_SUBSCRIBES:
         return {
            ...state,
            subscribes: action.payload,
         }
      
      case GET_CUSTOMER_SUBSCRIBES_SUCCESS:
         return {
            ...state,
            subscribes: [...action.payload],
         }
   
      case GET_CUSTOMER_SUBSCRIBES_FAIL:
         return {
            ...state,
            error: action.payload,
         }
      
      case GET_CUSTOMERS_SEARCH:
         return {
            ...state,
            customersSearch: action.payload,
         }
      
      case GET_CUSTOMERS_SEARCH_SUCCESS:
         return {
            ...state,
            customersSearch: [...action.payload],
         }
   
      case GET_CUSTOMERS_SEARCH_FAIL:
         return {
            ...state,
            error: action.payload,
         }
         
      case GET_CUSTOMERS_SEARCH_CLEAN:
         return {
            ...state,
            customersSearch: [],
         }

      case GET_CUSTOMER_SUBSCRIBES_ADD:
         return state

      case GET_CUSTOMER_SUBSCRIBES_ADD_SUCCESS:
            return {
               ...state,
               subscribes: [action.payload, ...state.subscribes],
            }
   
      case GET_CUSTOMER_SUBSCRIBES_ADD_FAIL:
         return {
            ...state,
            error: action.payload,
         }
      
      case GET_CUSTOMER_SUBSCRIBES_EDIT:
         return state

      case GET_CUSTOMER_SUBSCRIBES_EDIT_SUCCESS:
            const subscribesNew = state.subscribes.map(subscribe => {
               return subscribe._id.toString() === action.payload._id.toString()
                  ? action.payload
                  : subscribe
            });
            return {
               ...state,
               subscribes: [...subscribesNew],
            }
   
      case GET_CUSTOMER_SUBSCRIBES_EDIT_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      default:
         return state
   }
}

export default Users
