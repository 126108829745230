import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import lightlogo from "../../assets/images/logo-light.svg"
import FooterWelcome from "./_components/FooterWelcome";
import HeaderWelcome from "./_components/HeaderWelcome";

//Import config
import { facebook, google } from "../../config"

const Login = props => {
   const dispatch = useDispatch()

   const { error } = useSelector(state => ({
      error: state.Login.error,
   }))

   // handleValidSubmit
   const handleValidSubmit = (event, values) => {
      dispatch(loginUser(values, props.history))
   }

   const signIn = (res, type) => {
      if (type === "google" && res) {
         const postData = {
            name: res.profileObj.name,
            email: res.profileObj.email,
            token: res.tokenObj.access_token,
            idToken: res.tokenId,
         }
         dispatch(socialLogin(postData, props.history, type))
      } else if (type === "facebook" && res) {
         const postData = {
            name: res.name,
            email: res.email,
            token: res.accessToken,
            idToken: res.tokenId,
         }
         dispatch(socialLogin(postData, props.history, type))
      }
   }

   //handleGoogleLoginResponse
   const googleResponse = response => {
      signIn(response, "google")
   }

   //handleTwitterLoginResponse
   // const twitterResponse = e => {}

   //handleFacebookLoginResponse
   const facebookResponse = response => {
      signIn(response, "facebook")
   }

   return (
      <React.Fragment>
         <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark">
               <i className="bx bx-home h2" />
            </Link>
         </div>
         <div className="account-pages my-5 pt-sm-5">
            <Container>
               <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={5}>
                     <Card className="overflow-hidden">
                        <div className="bg-primary bg-soft">
                           <HeaderWelcome />
                        </div>
                        <CardBody className="pt-0">
                           <div className="auth-logo">
                              <Link to="/" className="auth-logo-light">
                                 <div className="avatar-md profile-user-wid mb-4">
                                    <span className="avatar-title rounded-circle bg-light">
                                       <img
                                          src={lightlogo}
                                          alt=""
                                          className="rounded-circle"
                                          height="34"
                                       />
                                    </span>
                                 </div>
                              </Link>
                              <Link to="/" className="auth-logo-dark">
                                 <div className="avatar-md profile-user-wid mb-4">
                                    <span className="avatar-title rounded-circle bg-light">
                                       <img
                                          src={logo}
                                          alt=""
                                          className="rounded-circle"
                                          height="34"
                                       />
                                    </span>
                                 </div>
                              </Link>
                           </div>
                           <div className="p-2">
                              <AvForm
                                 className="form-horizontal"
                                 onValidSubmit={handleValidSubmit}
                              >
                                 {error ? <Alert color="danger">{error}</Alert> : null}

                                 <div className="mb-3">
                                    <AvField
                                       name="email"
                                       label="Email"
                                       //  value="admin@themesbrand.com"
                                       value=""
                                       className="form-control"
                                       placeholder="Digite seu email"
                                       type="email"
                                       required
                                    />
                                 </div>

                                 <div className="mb-3">
                                    <AvField
                                       name="password"
                                       label="Password"
                                       //  value="123456"
                                       value=""
                                       type="password"
                                       required
                                       placeholder="Digite a senha"
                                    />
                                 </div>

                                 <div className="form-check">
                                    <input
                                       type="checkbox"
                                       className="form-check-input"
                                       id="customControlInline"
                                    />
                                    <label
                                       className="form-check-label"
                                       htmlFor="customControlInline"
                                    >
                                       Lembre-me
                                    </label>
                                 </div>

                                 <div className="mt-3 d-grid">
                                    <button
                                       className="btn btn-primary btn-block"
                                       type="submit"
                                    >
                                       Entrar
                                    </button>
                                 </div>

                                 {/* <div className="mt-4 text-center">
                     <h5 className="font-size-14 mb-3">Ou logar com</h5>

                     <ul className="list-inline">
                       <li className="list-inline-item">
                         <FacebookLogin
                           appId={facebook.APP_ID}
                           autoLoad={false}
                           callback={this.facebookResponse}
                           render={renderProps => (
                            <button
                               onClick={renderProps.onClick}
                               disabled={renderProps.disabled}
                               className="social-list-item bg-primary text-white border-primary"
                             >
                               <i className="mdi mdi-facebook" />
                             </button>
                           )}
                         />
                       </li>
                       <li className="list-inline-item">
                         <GoogleLogin
                           clientId={google.CLIENT_ID}
                           render={renderProps => (
                             <button
                               onClick={renderProps.onClick}
                               disabled={renderProps.disabled}
                               className="social-list-item bg-danger text-white border-danger"
                             >
                               <i className="mdi mdi-google" />
                             </button>
                           )}
                           onSuccess={(response)=>this.googleResponse(response)}
                           onFailure={() => { }}
                         />
                       </li>
                     </ul>
                   </div> */}

                                 <div className="mt-4 text-center">
                                    <Link to="/forgot-password" className="text-muted">
                                       <i className="mdi mdi-lock me-1" /> Esqueceu sua senha?
                                    </Link>
                                 </div>
                              </AvForm>
                           </div>
                        </CardBody>
                     </Card>
                     <div className="mt-5 text-center">
                        <FooterWelcome />
                     </div>
                  </Col>
               </Row>
            </Container>
         </div>
      </React.Fragment>
   )
}

export default withRouter(Login)

Login.propTypes = {
   history: PropTypes.object,
}
