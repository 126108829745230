import React, { useState, useEffect } from "react";
import {
   Col,
   Row,
   Modal,
   ModalHeader,
   ModalBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux"

import {
   getWorkoutsSearch as onGetWorkoutsSearch,
} from "store/workouts/actions"


const animatedComponents = makeAnimated();

const optionsTrainer = [
   {
       options: []
   }
];
const optionsModality = [
   {
       options: []
   }
];
const optionsLevels = [
   {
       options: []
   }
];

function ModalFiltro({modalFilter, toggleFilter, isEdit, workoutsItem }) {
   const dispatch = useDispatch();

   // console.log(workoutsItem, "workoutItem")

   const trainersSelected = workoutsItem && !workoutsItem?.trainers_workouts ? null:
   workoutsItem?.trainers_workouts.map(item=>{
           return (
               {
                   label: item.name,
                   value: item._id
               }
           )           
       }) 

const modalitySelected = workoutsItem && !workoutsItem?.modality_workouts ? null:
       workoutsItem?.modality_workouts.map(item=>{
               return (
                   {
                       label: item.name,
                       value: item._id
                   }
               )           
           })  
           
const levelsSelected = workoutsItem && !workoutsItem?.workoutlevels_workouts ? null:
workoutsItem?.workoutlevels_workouts.map(item=>{
   return (
       {
           label: item.name,
           value: item._id
       }
   )           
})

   const [erros, setErros] = useState(null);
   const [trainersSelectedValue, setSelectedTrainersValue] = useState(trainersSelected ? trainersSelected:null);
   const [selectedModalityValue, setSelectedModalityValue] = useState(modalitySelected ? modalitySelected:null);
   const [selectedLevelValue, setSelectedLevelValue] = useState(levelsSelected ? levelsSelected:null);

   const { trainers } = useSelector(state => ({
      trainers: state?.trainers?.trainers,
   }))

   const { modalities } = useSelector(state => ({
      modalities: state?.modalities?.modalities,
   }))

   const { workoutsLevels } = useSelector(state => ({
      workoutsLevels: state?.workoutsLevels?.workoutsLevels,
   }))

   useEffect(()=>{
      if(trainers) {
          optionsTrainer[0].options = [];
          trainers.map(item=>{               
              optionsTrainer[0].options.push(
                      {
                          label: item.name,
                          value: item._id,
                      }
                  );
                          
          });
      }       
  },[trainers]);

  useEffect(()=>{
      if(modalities) {
          optionsModality[0].options = [];
          modalities.map(item=>{               
              optionsModality[0].options.push(
                      {
                          label: item.name,
                          value: item._id,
                      }
                  );
                          
          });
      }       
  },[modalities]);  

   useEffect(()=>{
      if(workoutsLevels) {
          optionsLevels[0].options = [];
          workoutsLevels.map(item=>{                
                  optionsLevels[0].options.push(
                      {
                          label: item.name,
                          value: item._id,
                      }
                  );                             
          });
      }       
  },[workoutsLevels]);

// function handleMultiTrainer(selected) {
//    setSelectedTrainersValue(selected);
// }
// function handleMultiModalities(selected) {  
//    setSelectedModalityValue(selected);  
// }
// function handleMultiLevels(selected) {
//    setSelectedLevelValue(selected);
// }


   /**
    * Handling submit customer on customer form
    */
    const handleValidCustomerSubmit = (e, values) => {
      // console.log('values', values);
      setErros(null);
      const objToSend = {};
       if(trainersSelectedValue){
         objToSend.trainers = trainersSelectedValue         
      } if (selectedModalityValue) {
         objToSend.modalities = selectedModalityValue 
      } if (selectedLevelValue) {
         objToSend.level = selectedLevelValue 
      } if(values.from !== "" && values.to !== "") {

         objToSend.datas = {
            from: values.from,
            to: values.to
         }
      } else if(values.from !== "" && values.to !== "") {

         objToSend.datas = {
            from: values.from,
            to: values.to
         }
      }

      if(Object.keys(objToSend).length === 0) {
         setErros("Preencha algum tipo de filtro.");
      }
      
      dispatch(onGetWorkoutsSearch(objToSend))
      toggleFilter()
   }
  
   return (
      <Modal isOpen={modalFilter} toggleFilter={toggleFilter}>
         <ModalHeader toggle={toggleFilter} tag="h4">
            Filtrar Treinos
         </ModalHeader>
         <ModalBody>
            <AvForm
               onValidSubmit={handleValidCustomerSubmit}
            >
               <Row form>
                  <Col className="col-12">
                     <div className="mb-3">
                     <label htmlFor="trainers">Por Treinadoras</label>
                                    <Select
                                        value={trainersSelectedValue}
                                        isMulti={false}
                                        onChange={(selected) => setSelectedTrainersValue(selected)}
                                        options={optionsTrainer}
                                        classNamePrefix="select2-selection"
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                    />
                     </div>

                     <div className="mb-3">
                     <label htmlFor="modalities">Por Modalidades</label>
                                    <Select
                                        value={selectedModalityValue}
                                        isMulti={false}
                                        onChange={(selected) => setSelectedModalityValue(selected)}
                                        options={optionsModality}
                                        classNamePrefix="select2-selection"
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                    />
                     </div>

                     <div className="mb-3">
                     <label htmlFor="level">Por Nivel</label>
                                    <Select
                                        value={selectedLevelValue}
                                        isMulti={false}
                                        onChange={(selected) => setSelectedLevelValue(selected)}
                                        options={optionsLevels}
                                        classNamePrefix="select2-selection"
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                    />
                     </div>
                     
                     <div className="mt-4">
                        <h6>ou por data de criação</h6>
                        <div className="d-flex justify-content-between mt-3">
                           <div className="mb-3 w-75" style={{marginRight:20}}>
                              <AvField
                                 name="from"
                                 label="data inicial"
                                 type="date"
                                 errorMessage="data inicial inválida"
                                 validate={{
                                    required: { value: false },
                                 }}
                                 value=""
                              />
                           </div>

                           <div className="mb-3 w-75">
                              <AvField
                                 name="to"
                                 label="data final"
                                 type="date"
                                 errorMessage="data final inválida"
                                 validate={{
                                    required: { value: false },
                                 }}
                                 value={""}
                              />
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col>
                     {erros && <div className="alert alert-danger">{erros}</div>}
                     <div className="text-end">
                        <button
                           type="submit"
                           className="btn btn-success save-customer"
                        >
                           Procurar
                        </button>
                     </div>
                  </Col>
               </Row>
            </AvForm>
         </ModalBody>
      </Modal>
   );
}

ModalFiltro.propTypes = {
   modalFilter: PropTypes.bool,
   isEdit: PropTypes.bool,
   toggleFilter: PropTypes.func,
   workoutsItem: PropTypes.object,
}


export default ModalFiltro;