/* Equipments */
export const GET_EQUIPMENTS = "GET_EQUIPMENTS"
export const GET_EQUIPMENTS_SUCCESS = "GET_EQUIPMENTS_SUCCESS"
export const GET_EQUIPMENTS_FAIL = "GET_EQUIPMENTS_FAIL"

/**
 * add Equipments
 */
 export const ADD_NEW_EQUIPMENTS = "ADD_NEW_EQUIPMENTS"
 export const ADD_EQUIPMENTS_SUCCESS = "ADD_EQUIPMENTS_SUCCESS"
 export const ADD_EQUIPMENTS_FAIL = "ADD_EQUIPMENTS_FAIL"
 
 /**
  * Edit Equipments
  */
 export const UPDATE_EQUIPMENTS = "UPDATE_EQUIPMENTS"
 export const UPDATE_EQUIPMENTS_SUCCESS = "UPDATE_EQUIPMENTS_SUCCESS"
 export const UPDATE_EQUIPMENTS_FAIL = "UPDATE_EQUIPMENTS_FAIL"
 
 /**
  * Delete Equipments
  */
 export const DELETE_EQUIPMENTS = "DELETE_EQUIPMENTS"
 export const DELETE_EQUIPMENTS_SUCCESS = "DELETE_EQUIPMENTS_SUCCESS"
 export const DELETE_EQUIPMENTS_FAIL = "DELETE_EQUIPMENTS_FAIL"