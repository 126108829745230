import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Label
} from "reactstrap";

import {
    getRecipes as onGetRecipes,
 } from "store/recipes/actions";

import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { addRecipes, editRecipes } from "api/recipes";
import { useDispatch } from "react-redux";
import { getStringShortcodeLocale } from "utils";
import Loading from "components/Loading";


const FormGeneralInfo  = ({ isEdit, toggle, recipesItem })=> {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const dispatch = useDispatch();
 
    const [isEditLocal, setIsEditLocal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
      
    useEffect(() => {      
       setIsEditLocal(isEdit);
      const title = recipesItem?.title && recipesItem?.title.indexOf("[pt]") > -1 ? getStringShortcodeLocale(recipesItem?.title, 'pt'):recipesItem?.title;
      const titleEN = recipesItem?.title && recipesItem?.title.indexOf("[en]") > -1 ? getStringShortcodeLocale(recipesItem?.title, 'en'):"";
      const titleES = recipesItem?.title && recipesItem?.title.indexOf("[es]") > -1 ? getStringShortcodeLocale(recipesItem?.title, 'es'):"";

      const ingredients = recipesItem?.ingredients && recipesItem?.ingredients.indexOf("[pt]") > -1 ? getStringShortcodeLocale(recipesItem?.ingredients, 'pt'):recipesItem?.ingredients;
      const ingredientsEN = recipesItem?.ingredients && recipesItem?.ingredients.indexOf("[en]") > -1 ? getStringShortcodeLocale(recipesItem?.ingredients, 'en'):"";
      const ingredientsES = recipesItem?.ingredients && recipesItem?.ingredients.indexOf("[es]") > -1 ? getStringShortcodeLocale(recipesItem?.ingredients, 'es'):"";

      const doing = recipesItem?.doing && recipesItem?.doing.indexOf("[pt]") > -1 ? getStringShortcodeLocale(recipesItem?.doing, 'pt'):recipesItem?.doing;
      const doingEN = recipesItem?.doing && recipesItem?.doing.indexOf("[en]") > -1 ? getStringShortcodeLocale(recipesItem?.doing, 'en'):"";
      const doingES = recipesItem?.doing && recipesItem?.doing.indexOf("[es]") > -1 ? getStringShortcodeLocale(recipesItem?.doing, 'es'):"";
 
      const time = recipesItem?.time && recipesItem?.time.indexOf("[pt]") > -1 ? getStringShortcodeLocale(recipesItem?.time, 'pt'):recipesItem?.time;
      const timeEn = recipesItem?.time && recipesItem?.time.indexOf("[en]") > -1 ? getStringShortcodeLocale(recipesItem?.time, 'en'):"";
      const timeEs = recipesItem?.time && recipesItem?.time.indexOf("[es]") > -1 ? getStringShortcodeLocale(recipesItem?.time, 'es'):"";

      const portions = recipesItem?.portions && recipesItem?.portions.indexOf("[pt]") > -1 ? getStringShortcodeLocale(recipesItem?.portions, 'pt'):recipesItem?.portions;
      const portionsEn = recipesItem?.portions && recipesItem?.portions.indexOf("[en]") > -1 ? getStringShortcodeLocale(recipesItem?.portions, 'en'):"";
      const portionsEs = recipesItem?.portions && recipesItem?.portions.indexOf("[es]") > -1 ? getStringShortcodeLocale(recipesItem?.portions, 'es'):"";

      const difficulty = recipesItem?.difficulty && recipesItem?.difficulty.indexOf("[pt]") > -1 ? getStringShortcodeLocale(recipesItem?.difficulty, 'pt'):recipesItem?.difficulty;
      const difficultyEn = recipesItem?.difficulty && recipesItem?.difficulty.indexOf("[en]") > -1 ? getStringShortcodeLocale(recipesItem?.difficulty, 'en'):"";
      const difficultyEs = recipesItem?.difficulty && recipesItem?.difficulty.indexOf("[es]") > -1 ? getStringShortcodeLocale(recipesItem?.difficulty, 'es'):"";

       reset({
          title: isEdit ? title:'',
          titleEn: isEdit ? titleEN:'',
          titleEs: isEdit ? titleES:'',
          ingredients: isEdit ? ingredients:'', 
          ingredientsEn: isEdit ? ingredientsEN:'',
          ingredientsEs: isEdit ? ingredientsES:'',        
          doing: isEdit ? doing: '',
          doingEn: isEdit ? doingEN: '', 
          doingEs: isEdit ? doingES: '', 
          categories: isEdit ? recipesItem.categories:'',
          date: isEdit ? recipesItem.date:'',
          time: isEdit ? time: '',
          timeEn: isEdit ? timeEn: '', 
          timeEs: isEdit ? timeEs: '',
          portions: isEdit ? portions: '',
          portionsEn: isEdit ? portionsEn: '', 
          portionsEs: isEdit ? portionsEs: '', 
          difficulty: isEdit ? difficulty: '',
          difficultyEn: isEdit ? difficultyEn: '', 
          difficultyEs: isEdit ? difficultyEs: '', 
                   
       });
    }, [isEdit, recipesItem]);
 
    const convertBase64 = (file) => {
       return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
             resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
             reject(error);
          };
       })
    }
 
    /**
     * Handling submit customer on customer form
     */
    const handleValidCustomerSubmit = async (data) => {
       console.log('data', data);
 
       if (data.title) {
         const titleFinal = `[pt]${data.title}[:pt][en]${data.titleEn}[:en][es]${data.titleEs}[:es]`;
         const ingredientsFinal = `[pt]${data.ingredients}[:pt][en]${data.ingredientsEn}[:en][es]${data.ingredientsEs}[:es]`;
         const doingFinal = `[pt]${data.doing}[:pt][en]${data.doingEn}[:en][es]${data.doingEs}[:es]`;
         const timeFinal = `[pt]${data.time}[:pt][en]${data.timeEn}[:en][es]${data.timeEs}[:es]`;
         const difficultyFinal = `[pt]${data.difficulty}[:pt][en]${data.difficultyEn}[:en][es]${data.difficultyEs}[:es]`;
         const portionsFinal = `[pt]${data.portions}[:pt][en]${data.portionsEn}[:en][es]${data.portionsEs}[:es]`;
         const base64 = data.thumbnail[0] ? await convertBase64(data.thumbnail[0]):null;
         //  console.log(base64);
          setIsLoading(true);
          if(!isEdit)
          {
             await addRecipes({              
                title: titleFinal,               
                image: base64,               
                ingredients: ingredientsFinal,
                doing: doingFinal,
                categories: data.categories,
                time: timeFinal,
                difficulty: difficultyFinal,
                portions: portionsFinal
               //  date: data.date                  
             });
          } else {
             await editRecipes({
                _id: recipesItem._id,
                title: titleFinal,               
                image: base64,               
                ingredients: ingredientsFinal,    
                doing: doingFinal, 
                categories: data.categories,
                time: timeFinal,
                difficulty: difficultyFinal,
                portions: portionsFinal
               //  date: data.date                 
             });
          }
          
          setIsLoading(false);
          dispatch(onGetRecipes());
          toggle();
       }
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(handleValidCustomerSubmit)}
            >
                <Row form>
                <Col className="col-12">
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="title" className="">Nome</label>
                           <input {...register("title", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.title && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="titleEn" className="">Nome Inglês</label>
                           <input {...register("titleEn", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.titleEn && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="titleEs" className="">Nome Espanhol</label>
                           <input {...register("titleEs", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.titleEs && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>

                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="ingredients" className="">Ingredientes</label>
                           <input {...register("ingredients", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.ingredients && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="ingredientsEn" className="">Ingredientes Inglês</label>
                           <input {...register("ingredientsEn", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.ingredientsEn && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="ingredientsEs" className="">Ingredientes Espanhol</label>
                           <input {...register("ingredientsEs", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.ingredientsEs && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>

                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="doing" className="">Modo de Fazer</label>
                           <textarea {...register("doing", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.doing && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="doingEn" className="">Modo de Fazer Inglês</label>
                           <textarea {...register("doingEn", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.doingEn && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="doingEs" className="">Modo de Fazer Espanhol</label>
                           <textarea {...register("doingEs", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.doingEs && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>

                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="time" className="">Tempo</label>
                           <input {...register("time", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.time && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="timeEn" className="">Tempo Ingles</label>
                           <input {...register("timeEn", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.timeEn && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="timeEs" className="">Tempo Espanhol</label>
                           <input {...register("timeEs", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.timeEs && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>

                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="difficulty" className="">Dificuldade</label>
                           <input {...register("difficulty", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.difficulty && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="difficultyEn" className="">Dificuldade Ingles</label>
                           <input {...register("difficultyEn", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.difficultyEn && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="difficulty" className="">Dificuldade Espanhol</label>
                           <input {...register("difficulty", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.difficulty && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>

                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="portions" className="">Porções</label>
                           <input {...register("portions", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.portions && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="portionsEn" className="">Porções Ingles</label>
                           <input {...register("portionsEn", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.portionsEn && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="portionsEs" className="">Porções Espanhol</label>
                           <input {...register("portionsEs", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.portionsEs && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>

                     <div className="mt-3">
                        <div className="form-group">
                           <Label htmlFor="thumbnail" className="form-label">Upload Thumb</Label>
                           <input type="file" {...register("thumbnail", { required: !isEdit ? true:false })} className="form-control form-control-sm" id="formFileSm" />
                           {errors.thumbnail && <div className="alert alert-danger">Campo obrigatório</div>}
                           {isEditLocal && <div>
                              <Label htmlFor="thumbnail" className="form-label mt-2">Preview</Label><br/>
                              <img src={recipesItem?.thumbnail} style={{width: '100px', height: 'auto'}} />
                           </div>}
                        </div>
                     </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <div className="text-end">
                            {!isLoading && <button
                                type="submit"
                                className="btn btn-success save-customer"
                            >
                                Salvar
                            </button>}
                            {isLoading && <Loading />}
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    );
}

FormGeneralInfo.propTypes = {
    toggle: PropTypes.func,
    isEdit: PropTypes.bool,
    recipesItem: PropTypes.object,
 }

export default FormGeneralInfo;