import React, { useEffect, useState } from "react";
import {
   Modal,
   ModalHeader,
   ModalBody,
} from "reactstrap";
import PropTypes from "prop-types";
import FormGeneralInfoWorkouts from "./_formGeneralInfoWorkouts";
import FormCategoriasWorkouts from "./_formCategoriasWorkouts";
import FormTextWorkouts from "./_formTextWorkouts";

function ModalAdd({ modal, toggle, isEdit, workoutsItem, workoutsDuration }) {
   const [tabActive, setTabActive] = useState(0);   
 

   useEffect(()=>{
      setTabActive(0);
   }, [isEdit, workoutsItem]);

   return (
      <Modal isOpen={modal} toggle={toggle}>
         <ModalHeader toggle={toggle} tag="h4">
            {isEdit ? `Editar` : 'Treino'} Treino - <small>{isEdit ? workoutsItem._id:''}</small>
         </ModalHeader>
         {isEdit && <div className="d-flex w-100 mt-4">
            <div className="btn-group mb-3 mx-auto">
               <button type="button" onClick={()=>setTabActive(0)}
                  className={`btn btn-outline-primary w-sm ${tabActive === 0 ? 'active':''}`}>
                  Informações Gerais
               </button>               
               <button type="button" onClick={()=>setTabActive(1)}
                  className={`btn btn-outline-primary w-sm ${tabActive === 1 ? 'active':''}`}>
                  Categorias
               </button>
               <button type="button" onClick={()=>setTabActive(2)}
                  className={`btn btn-outline-primary w-sm ${tabActive === 2 ? 'active':''}`}>
                  Textos Introdutorios
               </button>               
            </div>
         </div>}
         <ModalBody>
            {tabActive === 0 && <FormGeneralInfoWorkouts isEdit={isEdit} workoutsItem={workoutsItem} toggle={toggle} workoutsDuration={workoutsDuration} />}            
            {tabActive === 1 && <FormCategoriasWorkouts isEdit={isEdit} workoutsItem={workoutsItem} toggle={toggle} />}
            {tabActive === 2 && <FormTextWorkouts isEdit={isEdit} workoutsItem={workoutsItem} toggle={toggle} />}           
         </ModalBody>
      </Modal>
   );
}

ModalAdd.propTypes = {
   modal: PropTypes.bool,
   isEdit: PropTypes.bool,
   toggle: PropTypes.func,
   workoutsItem: PropTypes.object,
   workoutsDuration: PropTypes.array,
}


export default ModalAdd;

