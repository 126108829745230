import {post, get, del} from './index';

async function getModalities() {
   return await get('/gerenciador/modalidades');
}

async function addModalities(obj) {
   return await post(`/gerenciador/modalidades/add`, obj);
}

async function editModalities(obj) {
   return await post(`/gerenciador/modalidades/edit`, obj);
}

async function deleteModalities(obj) {
   return await del(`/gerenciador/modalidades/delete`, obj);
}

export {
    getModalities,
    addModalities,
    editModalities,
    deleteModalities
}