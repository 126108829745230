import { 
   GET_WORKOUTS,
   GET_WORKOUTS_FAIL,
   GET_WORKOUTS_SUCCESS,
   ADD_NEW_WORKOUTS,
   ADD_WORKOUTS_SUCCESS,
   ADD_WORKOUTS_FAIL,
   UPDATE_WORKOUTS,
   UPDATE_WORKOUTS_SUCCESS,
   UPDATE_WORKOUTS_FAIL,
   DELETE_WORKOUTS,
   DELETE_WORKOUTS_SUCCESS,
   DELETE_WORKOUTS_FAIL,
   GET_WORKOUTS_SEARCH,
   GET_WORKOUTS_SEARCH_SUCCESS,
   GET_WORKOUTS_SEARCH_FAIL,
   GET_WORKOUTS_SEARCH_CLEAN,
   GET_TOTAL_WORKOUTS,
   GET_TOTAL_WORKOUTS_SUCCESS,
   GET_TOTAL_WORKOUTS_FAIL, 
} from "./actionTypes"

const INIT_STATE = {
  workouts: [],
  workoutsSearch: [],
  total: 0,
 error: {},
}

const workouts = (state = INIT_STATE, action) => {
 switch (action.type) {
   case GET_TOTAL_WORKOUTS:
      return {
         ...state,
         total: action.payload,
      }

   case GET_TOTAL_WORKOUTS_SUCCESS:
      return {
         ...state,
         total: action.payload,
      }

   case GET_TOTAL_WORKOUTS_FAIL:
      return {
         ...state,
         error: action.payload,
      }

   case GET_WORKOUTS:
        return {
           ...state,
           workouts: action.payload,
        }

     case GET_WORKOUTS_SUCCESS:
        return {
           ...state,
           workouts: action.payload,
        }

     case GET_WORKOUTS_FAIL:
        return {
           ...state,
           error: action.payload,
        }

     case ADD_NEW_WORKOUTS:
        return {
           ...state,
           workouts: [...state.workouts, action.workouts],
        }

     case ADD_WORKOUTS_FAIL:
        return {
           ...state,
           error: action.payload,
        }

     case UPDATE_WORKOUTS:
        return {
           ...state,
           workouts: state.workouts.map(workout =>
             workout.id.toString() === action.payload.id.toString()
                 ? { workouts, ...action.payload }
                 : workout
           ),
        }

     case UPDATE_WORKOUTS_FAIL:
        return {
           ...state,
           error: action.payload,
        }

     case DELETE_WORKOUTS_SUCCESS:
        return {
           ...state,
           workouts: state.workouts.filter(
              workouts => workouts.id.toString() !== action.payload.id.toString()
           ),
        }

        case GET_WORKOUTS_SEARCH:
         return {
            ...state,
            workoutsSearch: action.payload,
         }
      
      case GET_WORKOUTS_SEARCH_SUCCESS:
         return {
            ...state,
            workoutsSearch: [...action.payload],
         }
   
      case GET_WORKOUTS_SEARCH_FAIL:
         return {
            ...state,
            error: action.payload,
         }
         
      case GET_WORKOUTS_SEARCH_CLEAN:
         return {
            ...state,
            workoutsSearch: [],
         }

     case DELETE_WORKOUTS_FAIL:
        return {
           ...state,
           error: action.payload,
        }

     default:
        return state
 }
}

export default workouts;
