import config from './index';
import axios from 'axios';

import firebase from "firebase/app";
import "firebase/auth";

function get(path) {
   const headers = setUpHeaders();
   const url = `${process.env.REACT_APP_ENDPOINT_API}${path}`;

   return axios
      .get(url, headers)
      .then(res => res)
      .catch((error) => {
         console.log('error axios - get', error);
         return error;
      });
}

function post(path, obj, headerAdd) {
   let headers = setUpHeaders();
   if (headerAdd) {
      headers = {
         headers: {
            ...headers.headers, ...headerAdd
         }
      };
   }

   const url = `${process.env.REACT_APP_ENDPOINT_API}${path}`;

   return axios
      .post(url, obj, headers)
      .then(res => res)
      .catch((error) => {
         return {
            status: error.response?.status,
            data: error.response?.data,
            response: error.response,
         };
      });
}

function put(path, obj) {
   const headers = setUpHeaders();
   const url = `${process.env.REACT_APP_ENDPOINT_API}${path}`;

   return axios
      .put(url, obj, headers)
      .then(res => res)
      .catch((error) => {
         console.log('error axios - post', error);
         return error;
      });
}

async function del(path, obj) {
   const headers = setUpHeaders();
   const url = `${process.env.REACT_APP_ENDPOINT_API}${path}`;
   let config = {
      headers: headers.headers,
      data: { //! Take note of the `data` keyword. This is the request body.
         ...obj,
      }
   }
   const resp = await axios.delete(url, config)
      .then(res => res)
      .catch((error) => {
         console.log('error axios - del', error);
         return error;
      });
   return resp;
}

function setUpHeaders() {
   const userLogged = localStorage.getItem("authUser");
   if (userLogged) {
      const jsonLogged = JSON.parse(userLogged);
      const token = jsonLogged['stsTokenManager']['accessToken'];

      const configHeader = {
         headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json'
         },
      }
      return configHeader;
   }
   return {};
}

export {
   get,
   post,
   put,
   del
}