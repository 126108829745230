import { 
  GET_CATEGORIES_WORKOUTS, 
  GET_CATEGORIES_WORKOUTS_SUCCESS, 
  GET_CATEGORIES_WORKOUTS_FAIL, 
  ADD_NEW_CATEGORIES_WORKOUTS, 
  ADD_CATEGORIES_WORKOUTS_FAIL, 
  ADD_CATEGORIES_WORKOUTS_SUCCESS, 
  UPDATE_CATEGORIES_WORKOUTS, 
  UPDATE_CATEGORIES_WORKOUTS_FAIL, 
  UPDATE_CATEGORIES_WORKOUTS_SUCCESS, 
  DELETE_CATEGORIES_WORKOUTS, 
  DELETE_CATEGORIES_WORKOUTS_SUCCESS, 
  DELETE_CATEGORIES_WORKOUTS_FAIL 
} from "./actionTypes"

export const getCategoriesWorkouts = () => ({
  type: GET_CATEGORIES_WORKOUTS,
})

export const getCategoriesWorkoutsSuccess = tasks => ({
  type: GET_CATEGORIES_WORKOUTS_SUCCESS,
  payload: tasks,
})

export const getCategoriesWorkoutsFail = error => ({
  type: GET_CATEGORIES_WORKOUTS_FAIL,
  payload: error,
})

export const addNewCategoriesWorkouts = category => ({
  type: ADD_NEW_CATEGORIES_WORKOUTS,
  payload: category,
})

export const addCategoriesWorkoutsSuccess = category => ({
  type: ADD_CATEGORIES_WORKOUTS_SUCCESS,
  payload: category,
})

export const addCategoriesWorkoutsFail = error => ({
  type: ADD_CATEGORIES_WORKOUTS_FAIL,
  payload: error,
})

export const updateCategoriesWorkouts = category => ({
  type: UPDATE_CATEGORIES_WORKOUTS,
  payload: category,
})

export const updateCategoriesWorkoutsSuccess = category => ({
  type: UPDATE_CATEGORIES_WORKOUTS_SUCCESS,
  payload: category,
})

export const updateCategoriesWorkoutsFail = error => ({
  type: UPDATE_CATEGORIES_WORKOUTS_FAIL,
  payload: error,
})

export const deleteCategoriesWorkouts = category => ({
  type: DELETE_CATEGORIES_WORKOUTS,
  payload: category,
})

export const deleteCategoriesWorkoutsSuccess = category => ({
  type: DELETE_CATEGORIES_WORKOUTS_SUCCESS,
  payload: category,
})

export const deleteCategoriesWorkoutsFail = error => ({
  type: DELETE_CATEGORIES_WORKOUTS_FAIL,
  payload: error,
})
