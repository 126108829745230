import { 
   GET_WORKOUTSLEVELS,
   GET_WORKOUTSLEVELS_FAIL,
   GET_WORKOUTSLEVELS_SUCCESS,
   ADD_NEW_WORKOUTSLEVELS,
   ADD_WORKOUTSLEVELS_SUCCESS,
   ADD_WORKOUTSLEVELS_FAIL,
   UPDATE_WORKOUTSLEVELS,
   UPDATE_WORKOUTSLEVELS_SUCCESS,
   UPDATE_WORKOUTSLEVELS_FAIL,
   DELETE_WORKOUTSLEVELS,
   DELETE_WORKOUTSLEVELS_SUCCESS,
   DELETE_WORKOUTSLEVELS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
   workoutsLevels: [],
 error: {},
}

const workoutsLevels = (state = INIT_STATE, action) => {
 switch (action.type) {
   case GET_WORKOUTSLEVELS:
        return {
           ...state,
           workoutsLevels: action.payload,
        }

     case GET_WORKOUTSLEVELS_SUCCESS:
        return {
           ...state,
           workoutsLevels: action.payload,
        }

     case GET_WORKOUTSLEVELS_FAIL:
        return {
           ...state,
           error: action.payload,
        }

     case ADD_NEW_WORKOUTSLEVELS:
        return {
           ...state,
           workoutsLevels: [...state.workoutsLevels, action.workoutsLevels],
        }

     case ADD_WORKOUTSLEVELS_FAIL:
        return {
           ...state,
           error: action.payload,
        }

     case UPDATE_WORKOUTSLEVELS:
        return {
           ...state,
           workoutsLevels: state.workoutsLevels.map(workout =>
             workout.id.toString() === action.payload.id.toString()
                 ? { workoutsLevels, ...action.payload }
                 : workout
           ),
        }

     case UPDATE_WORKOUTSLEVELS_FAIL:
        return {
           ...state,
           error: action.payload,
        }

     case DELETE_WORKOUTSLEVELS_SUCCESS:
        return {
           ...state,
           workoutsLevels: state.workoutsLevels.filter(
            customer => customer.id.toString() !== action.payload.id.toString()
           ),
        }

     case DELETE_WORKOUTSLEVELS_FAIL:
        return {
           ...state,
           error: action.payload,
        }

     default:
        return state
 }
}

export default workoutsLevels;
