import {post, get, del} from './index';

async function getWorkouts() {   
   return await get('/gerenciador/workouts');
}

async function getTotalWorkouts() {
   return await get('/gerenciador/total/workouts');
}

async function addWorkouts(obj) {
   return await post(`/gerenciador/workouts/add`, obj);
}

async function editWorkouts(obj) {
   return await post(`/gerenciador/workouts/edit`, obj);
}

async function editWorkoutsText(obj) {
   return await post(`/gerenciador/workoutstext/edit`, obj);
}

async function deleteWorkouts(obj) {
   return await del(`/gerenciador/workouts/delete`, obj);
}

async function relationsWorkout(obj) {
   return await post(`/gerenciador/workouts/relationsworkouts`, obj);
}

async function cloneWorkoutApi(_id) {
   return await get(`/gerenciador/workouts/clone/${_id}`);
}

async function getWorkoutsSearch(search) {
   return await post(`/gerenciador/workouts/search`, search);
}

export {
    getWorkouts,
    getTotalWorkouts,
    addWorkouts,
    editWorkouts,
    editWorkoutsText,
    deleteWorkouts,
    relationsWorkout,
    cloneWorkoutApi,
    getWorkoutsSearch,
}




