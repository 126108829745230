import { 
   GET_WORKOUTSDAYS,
   GET_WORKOUTSDAYS_FAIL,
   GET_WORKOUTSDAYS_SUCCESS,
   ADD_NEW_WORKOUTSDAYS,
   ADD_WORKOUTSDAYS_SUCCESS,
   ADD_WORKOUTSDAYS_FAIL,
   UPDATE_WORKOUTSDAYS,
   UPDATE_WORKOUTSDAYS_SUCCESS,
   UPDATE_WORKOUTSDAYS_FAIL,
   DELETE_WORKOUTSDAYS,
   DELETE_WORKOUTSDAYS_SUCCESS,
   DELETE_WORKOUTSDAYS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  workoutsDays: [],
 error: {},
}

const workoutsDays = (state = INIT_STATE, action) => {
 switch (action.type) {
   case GET_WORKOUTSDAYS:
        return {
           ...state,
           workoutsDaysbyId: action.payload,
         //   workoutsDays: action.payload,
        }

     case GET_WORKOUTSDAYS_SUCCESS:
        return {
           ...state,
           workoutsDays: action.payload,
        }

     case GET_WORKOUTSDAYS_FAIL:
        return {
           ...state,
           error: action.payload,
        }

     case ADD_NEW_WORKOUTSDAYS:
        return {
           ...state,
           workoutsDays: [...state.workoutsDays, action.workoutsDays],
        }

     case ADD_WORKOUTSDAYS_FAIL:
        return {
           ...state,
           error: action.payload,
        }

     case UPDATE_WORKOUTSDAYS:
        return {
           ...state,
           workoutsDays: state.workoutsDays.map(workout =>
             workout.id.toString() === action.payload.id.toString()
                 ? { workoutsDays, ...action.payload }
                 : workout
           ),
        }

     case UPDATE_WORKOUTSDAYS_FAIL:
        return {
           ...state,
           error: action.payload,
        }

     case DELETE_WORKOUTSDAYS_SUCCESS:
        return {
           ...state,
           workoutsDays: state.workoutsDays.filter(
            workoutsDays => workoutsDays.id.toString() !== action.payload.id.toString()
           ),
        }

     case DELETE_WORKOUTSDAYS_FAIL:
        return {
           ...state,
           error: action.payload,
        }

     default:
        return state
 }
}

export default workoutsDays;
