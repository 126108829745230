/* CUSTOMERS */
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"

/**
 * add CUSTOMER
 */
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER"
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS"
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL"

/**
 * Edit CUSTOMER
 */
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS"
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL"

/**
 * Delete CUSTOMER
 */
export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL"

/* TOTAL CUSTOMERS */
export const GET_TOTAL_CUSTOMERS = "GET_TOTAL_CUSTOMERS"
export const GET_TOTAL_CUSTOMERS_SUCCESS = "GET_TOTAL_CUSTOMERS_SUCCESS"
export const GET_TOTAL_CUSTOMERS_FAIL = "GET_TOTAL_CUSTOMERS_FAIL"

/* CUSTOMER SEARCH*/
export const GET_CUSTOMERS_SEARCH = "GET_CUSTOMERS_SEARCH"
export const GET_CUSTOMERS_SEARCH_SUCCESS = "GET_CUSTOMERS_SEARCH_SUCCESS"
export const GET_CUSTOMERS_SEARCH_FAIL = "GET_CUSTOMERS_SEARCH_FAIL"
export const GET_CUSTOMERS_SEARCH_CLEAN = "GET_CUSTOMERS_SEARCH_CLEAN"

/* CUSTOMER DETAIL*/
export const GET_CUSTOMER_DETAIL = "GET_CUSTOMER_DETAIL"
export const GET_CUSTOMER_DETAIL_SUCCESS = "GET_CUSTOMER_DETAIL_SUCCESS"
export const GET_CUSTOMER_DETAIL_FAIL = "GET_CUSTOMER_DETAIL_FAIL"

/* CUSTOMER PROPERTIES*/
export const GET_CUSTOMER_PROPERTIES = "GET_CUSTOMER_PROPERTIES"
export const GET_CUSTOMER_PROPERTIES_SUCCESS = "GET_CUSTOMER_PROPERTIES_SUCCESS"
export const GET_CUSTOMER_PROPERTIES_FAIL = "GET_CUSTOMER_PROPERTIES_FAIL"

/* CUSTOMER SCHEDULE*/
export const GET_CUSTOMER_SCHEDULE = "GET_CUSTOMER_SCHEDULE"
export const GET_CUSTOMER_SCHEDULE_SUCCESS = "GET_CUSTOMER_SCHEDULE_SUCCESS"
export const GET_CUSTOMER_SCHEDULE_FAIL = "GET_CUSTOMER_SCHEDULE_FAIL"

/* CUSTOMER MEDIAS*/
export const GET_CUSTOMER_MEDIAS = "GET_CUSTOMER_MEDIAS"
export const GET_CUSTOMER_MEDIAS_SUCCESS = "GET_CUSTOMER_MEDIAS_SUCCESS"
export const GET_CUSTOMER_MEDIAS_FAIL = "GET_CUSTOMER_MEDIAS_FAIL"

/* CUSTOMER TOKENS*/
export const GET_CUSTOMER_TOKENS = "GET_CUSTOMER_TOKENS"
export const GET_CUSTOMER_TOKENS_SUCCESS = "GET_CUSTOMER_TOKENS_SUCCESS"
export const GET_CUSTOMER_TOKENS_FAIL = "GET_CUSTOMER_TOKENS_FAIL"

/* CUSTOMER ASSINATURAS */
export const GET_CUSTOMER_SUBSCRIBES = "GET_CUSTOMER_SUBSCRIBES"
export const GET_CUSTOMER_SUBSCRIBES_SUCCESS = "GET_CUSTOMER_SUBSCRIBES_SUCCESS"
export const GET_CUSTOMER_SUBSCRIBES_FAIL = "GET_CUSTOMER_SUBSCRIBES_FAIL"

/* CUSTOMER ASSINATURAS */
export const GET_CUSTOMER_SUBSCRIBES_ADD = "GET_CUSTOMER_SUBSCRIBES_ADD"
export const GET_CUSTOMER_SUBSCRIBES_ADD_SUCCESS = "GET_CUSTOMER_SUBSCRIBES_ADD_SUCCESS"
export const GET_CUSTOMER_SUBSCRIBES_ADD_FAIL = "GET_CUSTOMER_SUBSCRIBES_ADD_FAIL"

/* CUSTOMER ASSINATURAS EDIT */
export const GET_CUSTOMER_SUBSCRIBES_EDIT = "GET_CUSTOMER_SUBSCRIBES_EDIT"
export const GET_CUSTOMER_SUBSCRIBES_EDIT_SUCCESS = "GET_CUSTOMER_SUBSCRIBES_EDIT_SUCCESS"
export const GET_CUSTOMER_SUBSCRIBES_EDIT_FAIL = "GET_CUSTOMER_SUBSCRIBES_EDIT_FAIL"
