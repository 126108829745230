import React, { useEffect, useState } from "react";
import {
   Col,
   Row,
   Modal,
   ModalHeader,
   ModalBody,
   Input,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import InputMask from 'react-input-mask';
import moment from 'moment-timezone';
import { useDispatch } from "react-redux";
import { validatePrice } from "../../../../utils";

import {
   addCustomersSubscribe as onAddCustomersSubscribe,
   editCustomersSubscribe as onEditCustomersSubscribe,
} from "store/users/actions";


function ModalAssinatura({modal, toggle, isEdit, id, itemSubscribeEdit}) {

   const dispatch = useDispatch();

   /**
    * Handling submit customer on customer form
    */
    const handleValidCustomerSubmit = (e, values) => {
      if(values.description==="") values.description = "Transferência bancária";
      if(values.select_status==="") values.select_status = "approved";

      if (isEdit) {
         const updateCustomer = {
            idOrder: itemSubscribeEdit._id,
            status:values.select_status,
            payday:moment.utc(moment(values.payday).tz('America/Sao_Paulo').format('YYYY-MM-DDT00:00:00')).toDate(),
            expirationDate:moment.utc(moment(values.expirationday).tz('America/Sao_Paulo').format('YYYY-MM-DDT23:59:59')).toDate(),
            value:values.value.replace(',', '.'),
            lastUpdateDate:moment.utc(moment().tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm:ss')).toDate(),
            statusDescription:values.description
         }

         // update customer
         dispatch(onEditCustomersSubscribe(updateCustomer))
      } else {
         const dataToSave = {
            type:"manual",
            status:"approved",
            payday:moment.utc(moment(values.payday).tz('America/Sao_Paulo').format('YYYY-MM-DDT00:00:00')).toDate(),
            expirationDate:moment.utc(moment(values.expirationday).tz('America/Sao_Paulo').format('YYYY-MM-DDT23:59:59')).toDate(),
            users_idusers:id,
            value:values.value.replace(',', '.'),
            lastUpdateDate:moment.utc(moment().tz('America/Sao_Paulo').format('YYYY-MM-DDTHH:mm:ss')).toDate(),
            statusDescription:values.description
         };
         // save new customer
         dispatch(onAddCustomersSubscribe(dataToSave))
      }
      toggle()
   }

   return (
      <Modal isOpen={modal} toggle={toggle}>
         <ModalHeader toggle={toggle} tag="h4">
            {!!isEdit
               ? "Editar Assinatura"
               : "Adicionar Assinatura"}
         </ModalHeader>
         <ModalBody>
            <AvForm
               onValidSubmit={handleValidCustomerSubmit}
            >
               <Row form>
                  <Col className="col-12">
                     <div className="mb-3">
                        <AvField
                           name="payday"
                           label="Data do pagamento"
                           type="date"
                           errorMessage="Dia de pagamento inválido"
                           validate={{
                              required: { value: true },
                           }}
                           value={itemSubscribeEdit? itemSubscribeEdit.payday.substr(0, itemSubscribeEdit.payday.indexOf('T')):""}
                        />
                     </div>

                     <div className="mb-3">
                        <AvField
                           name="expirationday"
                           label="Data de expiração"
                           type="date"
                           errorMessage="Dia de expiração inválido"
                           validate={{
                              required: { value: true },
                           }}
                           value={itemSubscribeEdit ? itemSubscribeEdit.expirationDate.substr(0, itemSubscribeEdit.expirationDate.indexOf('T')):""}
                        />
                     </div>

                     <div className="mb-3">
                        <AvField
                           label="Valor"
                           type="text"
                           name='value'
                           tag={[Input, InputMask]}
                           validate={{
                              required: { value: true },
                           }}
                           value={itemSubscribeEdit ? itemSubscribeEdit.value.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'):""}
                        />
                     </div>

                     <div className="mb-3">
                         <AvField 
                           type="select" 
                           name="description" 
                           label="Selecione o motivo..."
                           value={itemSubscribeEdit && itemSubscribeEdit.statusDescription}
                        >
                           <option value="Transferência bancária">
                                 Transferência bancária
                           </option>
                           <option value="Cortesia">
                                 Cortesia
                           </option>
                           <option value="Influencer">
                                 Influencer
                           </option>
                           <option value="Trainner">
                              Trainner
                           </option>
                           <option value="Aprovado pela Operadora">
                              Aprovado pela Operadora
                           </option>
                        </AvField>
                     </div>

                     {isEdit && (
                        <div className="mb-3">
                           <AvField 
                              type="select" 
                              name="select_status" 
                              id="select_status"
                              label="Status"
                           >
                              <option value="approved">
                                 approved
                              </option>
                              <option value="captured">
                                 captured
                              </option>
                              <option value="deined">
                                 deined
                              </option>
                           </AvField>
                        </div>
                     )}
                  </Col>
               </Row>
               <Row>
                  <Col>
                     <div className="text-end">
                        <button
                           type="submit"
                           className="btn btn-success save-customer"
                        >
                           Salvar
                        </button>
                     </div>
                  </Col>
               </Row>
            </AvForm>
         </ModalBody>
      </Modal>
   );
}

ModalAssinatura.propTypes = {
   modal: PropTypes.bool,
   isEdit: PropTypes.bool,
   toggle: PropTypes.func,
   id: PropTypes.string,
   itemSubscribeEdit: PropTypes.object
}


export default ModalAssinatura;