import React, { useState } from "react";
import {
   Col,
   Row,
   Modal,
   ModalHeader,
   ModalBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import { validateEmail } from "utils";

import { useDispatch } from "react-redux"

import {
   getCustomersSearch as onGetCustomersSearch,
} from "store/users/actions";

function ModalFiltro({modal, toggle, isEdit}) {
   const dispatch = useDispatch();

   const [erros, setErros] = useState(null);

   /**
    * Handling submit customer on customer form
    */
    const handleValidCustomerSubmit = (e, values) => {
      // console.log('values', values);
      setErros(null);
      const objToSend = {};
      if(values.email !== "")
      {
         if(!validateEmail(values.email)) {
            setErros("Email inválido");
            return;
         }
         objToSend.email = values.email;
         
      } else if(values.from !== "" && values.to !== "") {

         objToSend.datas = {
            from: values.from,
            to: values.to
         }
      }

      if(Object.keys(objToSend).length === 0) {
         setErros("Preencha algum tipo de filtro.");
      }
      
      dispatch(onGetCustomersSearch(objToSend))
      toggle()
   }

   return (
      <Modal isOpen={modal} toggle={toggle}>
         <ModalHeader toggle={toggle} tag="h4">
            Filtrar Clientes
         </ModalHeader>
         <ModalBody>
            <AvForm
               onValidSubmit={handleValidCustomerSubmit}
            >
               <Row form>
                  <Col className="col-12">
                     <div className="mb-3">
                        <AvField
                           name="email"
                           label="por email"
                           type="email"
                           errorMessage="Email inválido"
                           validate={{
                              required: { value: false },
                           }}
                           value={""}
                        />
                     </div>
                     
                     <div className="mt-4">
                        <h6>ou por data de criação</h6>
                        <div className="d-flex justify-content-between mt-3">
                           <div className="mb-3 w-75" style={{marginRight:20}}>
                              <AvField
                                 name="from"
                                 label="data inicial"
                                 type="date"
                                 errorMessage="data inicial inválida"
                                 validate={{
                                    required: { value: false },
                                 }}
                                 value=""
                              />
                           </div>

                           <div className="mb-3 w-75">
                              <AvField
                                 name="to"
                                 label="data final"
                                 type="date"
                                 errorMessage="data final inválida"
                                 validate={{
                                    required: { value: false },
                                 }}
                                 value={""}
                              />
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col>
                     {erros && <div className="alert alert-danger">{erros}</div>}
                     <div className="text-end">
                        <button
                           type="submit"
                           className="btn btn-success save-customer"
                        >
                           Procurar
                        </button>
                     </div>
                  </Col>
               </Row>
            </AvForm>
         </ModalBody>
      </Modal>
   );
}

ModalFiltro.propTypes = {
   modal: PropTypes.bool,
   isEdit: PropTypes.bool,
   toggle: PropTypes.func,
}


export default ModalFiltro;