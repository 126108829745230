import {post, get, del} from './index';

async function getWorkoutsDurationApi() {   
   return await get('/gerenciador/workouts/duration/list');
}

async function addWorkoutsDuration(obj) {
   return await post(`/gerenciador/workout/duration/add`, obj);
}

async function editWorkoutsDuration(obj) {
   return await post(`/gerenciador//workout/duration/edit`, obj);
}

async function deleteWorkoutsDuration(obj) {
   return await del(`/gerenciador/workout/duration/delete`, obj);
}

export {
   getWorkoutsDurationApi,
    addWorkoutsDuration,
    editWorkoutsDuration,
    deleteWorkoutsDuration,    
}
