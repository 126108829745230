import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux"
import { addWorkoutsDays, editWorkoutsDays, findOne } from "../../../../api/workoutsDays"
import Loading from "components/Loading";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useSelector } from "react-redux";
import SuccessMessage from "components/SuccessMessage";

import {
    getWorkoutsDays as onGetWorkoutsDays,
} from "store/workoutsDays/actions"



const animatedComponents = makeAnimated();

const options = [
    {
        options: []
    }
];
const optionsEquip = [
    {
        options: []
    }
];

function ModalAdd({ modal, toggle, isEdit, workoutsDaysItem, idworkouts, onFetchDaysData }) {
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const dispatch = useDispatch();

    const [isEditLocal, setIsEditLocal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [selectedEquipmentValue, setSelectedEquipmentValue] = useState(null);
    const [selectedCategorieValue, setSelectedCategorieValue] = useState(null);

    useEffect(() => {
        checkEquipamentAndCategories();
        resetForm();
        setIsEditLocal(isEdit);
    }, [isEdit, workoutsDaysItem]);

    useEffect(() => {
        resetForm();
        setSuccessMessage(false);
    }, [modal]);

    function resetForm() {
        reset({
            name: isEdit ? workoutsDaysItem.name : '',
            duration: isEdit ? workoutsDaysItem.duration : '',
            order_int: isEdit ? workoutsDaysItem.order_int : '',
            weekDay: isEdit ? workoutsDaysItem.weekDay : '',
        });
    }

    function checkEquipamentAndCategories() {
        const equipamentsSelected = workoutsDaysItem && !workoutsDaysItem?.equipments ? null :
            workoutsDaysItem?.equipments.map(item => {
                return item && (
                    {
                        label: item.name,
                        value: item._id
                    }
                )
            });
        
            console.log('workoutsDaysItem?.categories', workoutsDaysItem?.categories);
        const categoriesSelected = workoutsDaysItem && !workoutsDaysItem?.categories ? null :
            workoutsDaysItem?.categories.map(item => {
                return item && (
                    {
                        label: item.name,
                        value: item._id
                    }
                )
            });
        setSelectedEquipmentValue(equipamentsSelected);
        setSelectedCategorieValue(categoriesSelected);
    }

    const { categories } = useSelector(state => ({
        categories: state.categories.categories,
    }));

    const { equipaments } = useSelector(state => ({
        equipaments: state.equipaments.equipaments,
    }));

    console.log(categories, "categories do dia")

    console.log(equipaments, "equipments do dia")

    useEffect(() => {
        if (categories) {
            options[0].options = [];
            categories.map(item => {
                if (item.type === 'workouts') {
                    options[0].options.push(
                        {
                            label: item.name,
                            value: item._id,
                        }
                    );
                }
            });
        }
    }, [categories]);

    useEffect(() => {
        if (equipaments) {
            optionsEquip[0].options = [];
            equipaments.map(item => {
                optionsEquip[0].options.push(
                    {
                        label: item.name,
                        value: item._id,
                    }
                );
            });
        }
    }, [equipaments]);

    useEffect(() => {

        findOne(idworkouts).then((res) => {
            if (res.data) {
                dispatch(onGetWorkoutsDays(res.data))
            }
        });
    }, [dispatch]);
    /**
     * Handling submit customer on customer form
     */
    const handleValidCustomerSubmit = async (data) => {

        if (data.name) {
            console.log('selectedCategorieValue', selectedCategorieValue);
            console.log('selectedEquipmentValue', selectedEquipmentValue);
            const arrCategoriesToSend = selectedCategorieValue && selectedCategorieValue.length > 0 ? selectedCategorieValue.map(item => item && item.value) : null;
            const arrEquipamentsToSend = selectedEquipmentValue && selectedEquipmentValue.length > 0 ? selectedEquipmentValue.map(item => item && item.value) : null;
            const objToSend = {
                name: data.name,
                order_int: data.order_int,
                duration: data.duration,
                weekDay: data.weekDay,
                settings: data.settings,
                categories: JSON.stringify(arrCategoriesToSend),
                equipments: JSON.stringify(arrEquipamentsToSend),
                workouts_workoutDay: idworkouts,
            };
            if (!isEdit) {
                await addWorkoutsDays(objToSend);
            } else {
                objToSend._id = workoutsDaysItem._id;
                console.log('objToSend',objToSend);
                await editWorkoutsDays(objToSend);
            }
            setIsLoading(false);
            setSuccessMessage(true);
            onFetchDaysData();
        }
    }

    function handleMulti(selected) {
        setSelectedCategorieValue(selected);
    }
    function handleMultiEquip(selected) {
        setSelectedEquipmentValue(selected);
    }

    const customStyles = {
        input: provided => ({
          ...provided,
          color: '#fff'
        }),
        singleValue: provided => ({
          ...provided,
          color: '#fff'
        }),
    }

    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
                {isEdit ? 'Editar' : 'Adicionar'} Dia
            </ModalHeader>
            <ModalBody>
                <form
                    onSubmit={handleSubmit(handleValidCustomerSubmit)}
                >
                    <Row form>
                        <Col className="col-12">
                            <div className="mb-3">
                                <div className="form-group">
                                    <label htmlFor="name" className="">Nome</label>
                                    <input {...register("name", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                                    {errors.name && <div className="alert alert-danger">Campo obrigatório</div>}
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="form-group">
                                    <label htmlFor="weekDay" className="">Dia da Semana</label>
                                    <input {...register("weekDay", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                                    {errors.weekDay && <div className="alert alert-danger">Campo obrigatório</div>}
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="form-group">
                                    <label htmlFor="duration" className="">Duração em Segundos</label>
                                    <input type="number"{...register("duration", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                                    {errors.duration && <div className="alert alert-danger">Campo obrigatório</div>}
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="form-group">
                                    <label htmlFor="order_int" className="">Ordem</label>
                                    <input type="number"{...register("order_int", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                                    {errors.order_int && <div className="alert alert-danger">Campo obrigatório</div>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row form>
                        <Col className="col-12">
                            <div className="mt-3">
                                <div className="form-group">
                                    <div className="mb-3 templating-select select2-container">
                                        <label htmlFor="control-label">Selecione a Categoria</label>
                                        <Select
                                            value={selectedCategorieValue}
                                            isMulti={true}
                                            onChange={(event) => handleMulti(event)}
                                            options={options}
                                            classNamePrefix="select2-selection"
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="form-group">
                                    <div className="mb-3 templating-select select2-container">
                                        <label htmlFor="control-label">Selecione o Equipamento</label>
                                        <Select
                                            value={selectedEquipmentValue}
                                            isMulti={true}
                                            onChange={(event) => handleMultiEquip(event)}
                                            options={optionsEquip}
                                            classNamePrefix="select2-selection"
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            {successMessage && <SuccessMessage />}
                            <div className="text-end">
                                {!isLoading && <button
                                    type="submit"
                                    className="btn btn-success save-customer"
                                >
                                    Salvar
                                </button>}
                                {isLoading && <Loading />}
                            </div>
                        </Col>
                    </Row>
                </form>
            </ModalBody>
        </Modal>
    );
}

ModalAdd.propTypes = {
    modal: PropTypes.bool,
    isEdit: PropTypes.bool,
    toggle: PropTypes.func,
    workoutsDaysItem: PropTypes.object,
    idworkouts: PropTypes.string,
    onFetchDaysData: PropTypes.func,
}


export default ModalAdd;