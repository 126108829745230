import {post, get, del} from './index';

async function getRecipes() {   
   return await get('/gerenciador/receitas');
}

async function addRecipes(obj) {
   return await post(`/gerenciador/receitas/add`, obj);
}

async function editRecipes(obj) {
   return await post(`/gerenciador/receitas/edit`, obj);
}

async function deleteRecipes(obj) {
   return await del(`/gerenciador/receitas/delete`, obj);
}

async function setupCategories(obj) {
   return await post(`/gerenciador/receitas/setup/categories`, obj);
}

export {
   getRecipes,
   addRecipes,
   editRecipes,
   deleteRecipes,
   setupCategories,
}




