import React, { useEffect, useState } from "react";
import {
   Col,
   Row,
   Modal,
   ModalHeader,
   ModalBody,   
} from "reactstrap";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux"
import { editUsersDetail, userResetPassword } from "../../../../api/users";

import {
    getCustomerDetail as onGetCustomerDetail,  
 } from "store/users/actions";

function ModalAdd({ modal, toggleEdit, isEdit, userItem }) {
   const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
   const dispatch = useDispatch();
   const [, setIsEditLocal] = useState(false);
// console.log(userItem, "userItem")
   useEffect(() => {
      setIsEditLocal(isEdit);
      reset({
         name: isEdit ? userItem?.name:'',
         email: isEdit ? userItem?.email:'',        
      });
   }, [isEdit, userItem]);

   /**
    * Handling submit customer on customer form
    */
   const handleValidCustomerSubmit = async (data) => {
      console.log('data', data);

      if (data.name) {         
         if(isEdit)
         {
            await editUsersDetail({
               _id: userItem._id,
               name: data.name, 
               email: data.email,                           
            });
         }

         if(data.password) {
            await userResetPassword({
               _id: userItem._id,              
               newPassword: data.password   
            })
         }

         dispatch(onGetCustomerDetail());
         toggleEdit();
      }
   }

   return (
      <Modal isOpen={modal} toggle={toggleEdit}>
         <ModalHeader toggle={toggleEdit} tag="h4">
            {isEdit ? 'Editar' : 'Adicionar'} Usuário
         </ModalHeader>
         <ModalBody>
            <form
               onSubmit={handleSubmit(handleValidCustomerSubmit)}
            >
               <Row form>
                  <Col className="col-12">
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="name" className="">Nome</label>
                           <input {...register("name", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.name && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>

                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="email" className="">E-mail</label>
                           <input {...register("email", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.email && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div> 

                      <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="password" className="">Password</label>
                           <input {...register("password")} className="is-touched is-pristine av-valid form-control"  />                           
                        </div>
                     </div>                     
                  </Col>
               </Row>
               <Row className="mt-4">
                  <Col>
                     <div className="text-end">
                        <button
                           type="submit"
                           className="btn btn-success save-customer"
                        >
                           Salvar
                        </button>
                     </div>
                  </Col>
               </Row>
            </form>
         </ModalBody>
      </Modal>
   );
}

ModalAdd.propTypes = {
   modal: PropTypes.bool,
   isEdit: PropTypes.bool,
   toggleEdit: PropTypes.func,
   userItem: PropTypes.object,
}


export default ModalAdd;