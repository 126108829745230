import moment from "moment-timezone";

const handleValidDate = _date => {
    return moment.utc(moment(`${_date}`).tz('America/Sao_Paulo').toDate()).format('DD/MM/YYYY HH:mm:ss');
}

const handleValidOnlyDate = _date => {
    return moment.utc(moment(`${_date}`).tz('America/Sao_Paulo').toDate()).format('DD/MM/YYYY');
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validatePrice(price) {
    const re = /^\d+(,\d{3})*(\.\d{1,2})?$/;
    return re.test(String(price));
}

function getStringShortcodeLocale(str, locale) {
    if(str.indexOf(`[${locale}]`) > -1) {
        const ptStr = str.substring((str.indexOf(`[${locale}]`)+4), str.indexOf(`[:${locale}]`));
        return ptStr;
    }

    return str;
}

function filterArrForTypeVideoAudio(arr, type) {
    const arrTemp = arr && arr.map(item => item.type === type && item);
    return arrTemp.filter(item=>item !== null && item);
}

function addZeroes(num, lenTemp) {
    const strNum = num.toString();
    if (strNum.indexOf('.') > -1) {
      return Number(strNum).toFixed(lenTemp);
    }
  
    return Number(num);
  }

const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
       const fileReader = new FileReader();
       fileReader.readAsDataURL(file);
       fileReader.onload = () => {
          resolve(fileReader.result);
       };
       fileReader.onerror = (error) => {
          reject(error);
       };
    })
 }

export {
    handleValidDate,
    handleValidOnlyDate,
    validateEmail,
    validatePrice,
    getStringShortcodeLocale,
    filterArrForTypeVideoAudio,
    addZeroes,
    convertBase64,
}