import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CATEGORIES_WORKOUTS } from "./actionTypes"
import { getCategoriesWorkoutsFail, getCategoriesWorkoutsSuccess } from "./actions"

import {
  getCategoriesWorkouts,
} from '../../api/categoriesWorkouts';

function* fetchWorkouts() {
  try {
    const response = yield call(getCategoriesWorkouts)    
    yield put(getCategoriesWorkoutsSuccess(response.data.categories))
  } catch (error) {
    yield put(getCategoriesWorkoutsFail(error))
  }
}

function* tasksSaga() {
  yield takeEvery(GET_CATEGORIES_WORKOUTS, fetchWorkouts)
}

export default tasksSaga
