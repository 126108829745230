import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Label,
} from "reactstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { setupCategories } from "api/recipes";
import Loading from "components/Loading";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useSelector } from "react-redux";
import SuccessMessage from "components/SuccessMessage";

import {
    getRecipes as onGetRecipes,
 } from "store/recipes/actions"
 
import { useDispatch } from "react-redux";

const animatedComponents = makeAnimated();

const options = [
    {
        options: []
    }
];

const optionsDate = [
    {
        options: []
    }
];

const FormCategorias = ({ isEdit, recipesItem, toggle }) => {
    const dispatch = useDispatch();

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

    const categoriesSelected = recipesItem && !recipesItem.categories ? null:
    recipesItem.categories.map(item=>{
            return (
                {
                    label: item.name,
                    value: item._id
                }
            )
        }) 
        
        

    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedDateValue, setSelectedDateValue] = useState(null);
    

    const { categories } = useSelector(state => ({
        categories: state.categories.categories,
    }));
    

    useEffect(()=>{
        if(categories) {
            categories.map(item=>{
                if(item.type === 'posts') {
                    options[0].options.push(
                        {
                            label: item.name,
                            value: item._id,
                        }
                    );
                }
                if(item.type === 'date') {
                    optionsDate[0].options.push(
                        {
                            label: item.name,
                            value: item._id,
                        }
                    );
                }
            });
        }
    },[categories]);

    useEffect(()=>{
        console.log('recipesItem', recipesItem);
        if(recipesItem) {
            const arrCategorias = recipesItem.categories.filter(item=>item.type == "posts" && item).map(item=>{
                return (
                    {
                        label: item.name,
                        value: item._id
                    }
                )
            });
            setSelectedValue(arrCategorias);

            const arrDates = recipesItem.categories.filter(item=>item.type == "date" && item).map(item=>{
                return (
                    {
                        label: item.name,
                        value: item._id
                    }
                )
            });
            setSelectedDateValue(arrDates);
        }
    },[recipesItem]);   

    

    const handleValidCustomerSubmit = async (data) => {
        setSuccessMessage(false);
        setIsLoading(true);

        const payload = { _id: recipesItem._id};
        let hasData = false;

        if (selectedValue || selectedDateValue) {
            payload.categories = [...selectedValue, ...selectedDateValue];
            hasData = true;
        }

        if(hasData){
            await setupCategories(payload);
            setSuccessMessage(true);
            dispatch(onGetRecipes());
        }

        setIsLoading(false);
    }

    function handleMulti(selected) {
        setSelectedValue(selected);        
    }

    function handleMultiDate(selected) {
        setSelectedDateValue(selected);        
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(handleValidCustomerSubmit)}
            >
                <Row form>
                    <Col className="col-12">
                        <div className="mt-3">
                            <div className="form-group">
                                <div className="mb-3 templating-select select2-container">
                                    <label htmlFor="control-label">Adicione as categorias</label>
                                    <Select
                                        value={selectedValue}
                                        isMulti={true}
                                        onChange={(event)=>handleMulti(event)}
                                        options={options}
                                        classNamePrefix="select2-selection"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                    />
                                </div>
                                <div className="mb-3 templating-select select2-container">
                                    <label htmlFor="control-label">Adicione os Horario</label>
                                    <Select
                                        value={selectedDateValue}
                                        isMulti={true}
                                        onChange={(event)=>handleMultiDate(event)}
                                        options={optionsDate}
                                        classNamePrefix="select2-selection"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {successMessage && <SuccessMessage />}
                        <div className="text-end">
                            {!isLoading && <button
                                type="submit"
                                className="btn btn-success save-customer"
                            >
                                Salvar
                            </button>}
                            {isLoading && <Loading />}
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    );
}

FormCategorias.propTypes = {
    toggle: PropTypes.func,
    isEdit: PropTypes.bool,
    recipesItem: PropTypes.object,
}

export default FormCategorias;