import {post, get, del} from './index';

async function getWorkoutsLevels() {   
   return await get('/gerenciador/workouts/level');
}

async function addWorkoutsLevels(obj) {
   return await post(`/gerenciador/workout/level/add`, obj);
}

async function editWorkoutsLevels(obj) {
   return await post(`/gerenciador//workout/level/edit`, obj);
}

async function deleteWorkoutsLevels(obj) {
   return await del(`/gerenciador/workout/level/delete`, obj);
}

export {
    getWorkoutsLevels,
    addWorkoutsLevels,
    editWorkoutsLevels,
    deleteWorkoutsLevels,    
}
