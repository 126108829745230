import React from 'react';

function SuccessMessage() {
    return (
        <div className="alert alert-success">
            Dados salvos com sucesso!
        </div>
    );
}

export default SuccessMessage;