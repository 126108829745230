import { 
   GET_EQUIPMENTS, 
   GET_EQUIPMENTS_SUCCESS, 
   GET_EQUIPMENTS_FAIL, 
   ADD_NEW_EQUIPMENTS, 
   ADD_EQUIPMENTS_FAIL, 
   ADD_EQUIPMENTS_SUCCESS, 
   UPDATE_EQUIPMENTS, 
   UPDATE_EQUIPMENTS_FAIL, 
   UPDATE_EQUIPMENTS_SUCCESS, 
   DELETE_EQUIPMENTS, 
   DELETE_EQUIPMENTS_SUCCESS, 
   DELETE_EQUIPMENTS_FAIL 
} from "./actionTypes"

const INIT_STATE = {
  equipaments: [],
  error: {},
}

const equipaments = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EQUIPMENTS:
         return {
            ...state,
            equipaments: action.payload,
         }

      case GET_EQUIPMENTS_SUCCESS:
         return {
            ...state,
            equipaments: action.payload,
         }

      case GET_EQUIPMENTS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case ADD_NEW_EQUIPMENTS:
         return {
            ...state,
            equipaments: [...state.equipaments, action.payload],
         }

      case ADD_EQUIPMENTS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case UPDATE_EQUIPMENTS:
         return {
            ...state,
            equipaments: state.equipaments.map(equip =>
              equip.id.toString() === action.payload.id.toString()
                  ? { equip, ...action.payload }
                  : equip
            ),
         }

      case UPDATE_EQUIPMENTS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case DELETE_EQUIPMENTS_SUCCESS:
         return {
            ...state,
            equipaments: state.equipaments.filter(
               equip => equip.id.toString() !== action.payload.id.toString()
            ),
         }

      case DELETE_EQUIPMENTS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      default:
         return state
  }
}

export default equipaments;
