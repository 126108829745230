import {post, get} from './index';

async function getTrainers() {
   return await get('/gerenciador/trainers');
}

async function addTrainer(obj) {
   return await post(`/gerenciador/trainers/add`, obj);
}

async function editTrainer(obj) {
   return await post(`/gerenciador/trainers/edit`, obj);
}

async function deleteTrainerApi(obj) {
   return await post(`/gerenciador/trainers/delete`, obj);
}

export {
   getTrainers,
   addTrainer,
   editTrainer,
   deleteTrainerApi
}