import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CATEGORIES } from "./actionTypes"
import { getCategoriesFail, getCategoriesSuccess } from "./actions"

import {
  getCategories,
} from '../../api/categories';

function* fetchModalities() {
  try {
    const response = yield call(getCategories)
    yield put(getCategoriesSuccess(response.data.categories))
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}

function* tasksSaga() {
  yield takeEvery(GET_CATEGORIES, fetchModalities)
}

export default tasksSaga
