import { 
    GET_RECIPES, 
    GET_RECIPES_FAIL, 
    GET_RECIPES_SUCCESS,
    ADD_NEW_RECIPES,
    ADD_RECIPES_SUCCESS,
    ADD_RECIPES_FAIL,
    UPDATE_RECIPES,
    UPDATE_RECIPES_SUCCESS,
    UPDATE_RECIPES_FAIL,
    DELETE_RECIPES,
    DELETE_RECIPES_FAIL,
    DELETE_RECIPES_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
   recipes: [],
  error: {},
}

const recipes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RECIPES:
         return {
            ...state,
            recipes: action.payload,
         }

      case GET_RECIPES_SUCCESS:
         return {
            ...state,
            recipes: action.payload,
         }

      case GET_RECIPES_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case ADD_NEW_RECIPES:
         return {
            ...state,
            recipes: [...state.recipes, action.payload],
         }

      case ADD_RECIPES_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case UPDATE_RECIPES:
         return {
            ...state,
            recipes: state.recipes.map(recipe =>
              recipe.id.toString() === action.payload.id.toString()
                  ? { recipes, ...action.payload }
                  : recipe
            ),
         }

      case UPDATE_RECIPES_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case DELETE_RECIPES_SUCCESS:
         return {
            ...state,
            recipes: state.recipes.filter(
               recipes => recipes.id.toString() !== action.payload.id.toString()
            ),
         }

      case DELETE_RECIPES_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      default:
         return state
  }
}

export default recipes;
