import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
   GET_TRAININGS,
} from "./actionTypes"


import {
   getTrainings,
} from '../../api/trainings';

import { getTrainingsFail, getTrainingsSuccess } from "./actions";

function* fetchTrainings() {
   try {
      const response = yield call(getTrainings);
      yield put(getTrainingsSuccess(response.data.trainings));
   } catch (error) {
      yield put(getTrainingsFail(error));
   }
}

// function* fetchCustomerDetail({ idCliente }) {
//    console.log('idCliente', idCliente);
//    try {
//       const response = yield call(getUsersDetail, idCliente);
//       yield put(geCustomerDetailSuccess(response.data.user));
//       yield put(geCustomerSubscribeSuccess(response.data.orders));
//    } catch (error) {
//       yield put(geCustomerDetailFail(error));
//    }
// }

// function* addCustomerSubscribe({ objToSave }) {
//    try {
//       const response = yield call(addUserSubscribe, objToSave);
//       yield put(addCustomersSubscribeSuccess(response.data.orderSaved));
//    } catch (error) {
//       yield put(addCustomersSubscribeFail(error));
//    }
// }

// function* editCustomerSubscribe({ objToSave }) {
//    try {
//       const response = yield call(editUserSubscribe, objToSave);
//       yield put(editCustomersSubscribeSuccess(response.data.orderUpdated));
//    } catch (error) {
//       yield put(editCustomersSubscribeFail(error));
//    }
// }

// function* deleteCustomer(customer) {
//    try {
//       const response = yield call(deleteUserApi, {_id: customer.payload._id});
//       yield put(deleteCustomerSuccess(response.data));
//    } catch (error) {
//       yield put(deleteCustomerFail(error));
//    }
// }

function* trainersSaga() {
 
   yield takeEvery(GET_TRAININGS, fetchTrainings);
}

export default trainersSaga
