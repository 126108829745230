import { 
    GET_CATEGORIES, 
    GET_CATEGORIES_FAIL, 
    GET_CATEGORIES_SUCCESS,
    ADD_NEW_CATEGORIES,
    ADD_CATEGORIES_SUCCESS,
    ADD_CATEGORIES_FAIL,
    UPDATE_CATEGORIES,
    UPDATE_CATEGORIES_SUCCESS,
    UPDATE_CATEGORIES_FAIL,
    DELETE_CATEGORIES,
    DELETE_CATEGORIES_FAIL,
    DELETE_CATEGORIES_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
   categories: [],
   error: {},
}

const categories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
         return {
            ...state,
            categories: action.payload,
         }

      case GET_CATEGORIES_SUCCESS:
         return {
            ...state,
            categories: action.payload,
         }

      case GET_CATEGORIES_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case ADD_NEW_CATEGORIES:
         return {
            ...state,
            categories: [...state.categories, action.payload],
         }

      case ADD_CATEGORIES_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case UPDATE_CATEGORIES:
         return {
            ...state,
            categories: state.categories.map(category =>
              category.id.toString() === action.payload.id.toString()
                  ? { category, ...action.payload }
                  : category
            ),
         }

      case UPDATE_CATEGORIES_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case DELETE_CATEGORIES_SUCCESS:
         return {
            ...state,
            categories: state.categories.filter(
               customer => customer.id.toString() !== action.payload.id.toString()
            ),
         }

      case DELETE_CATEGORIES_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      default:
         return state
  }
}

export default categories;
