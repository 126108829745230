import {post, get} from './index';

async function getTrainings() {
   return await get('/gerenciador/trainings');
}

async function addTraining(obj) {
   return await post(`/gerenciador/trainings/add`, obj);
}

async function editTraining(obj) {
   return await post(`/gerenciador/trainings/edit`, obj);
}

async function uploadVideoApi(obj, header) {
   return await post(`/gerenciador/trainings/upload/video`, obj, header);
}

async function uploadAudioApi(obj, header) {
   return await post(`/gerenciador/trainings/upload/audio`, obj, header);
}

async function deleteTrainingApi(obj) {
   return await post(`/gerenciador/trainings/delete`, obj);
}

async function setupCategories(obj) {
   return await post(`/gerenciador/trainings/setup/categories`, obj);
}

async function getTrainingsApi(obj) {
   return await post(`/gerenciador/trainings/get/all`, obj);
}

export {
   getTrainings,
   addTraining,
   editTraining,
   deleteTrainingApi,
   uploadVideoApi,
   uploadAudioApi,
   setupCategories,
   getTrainingsApi,
}