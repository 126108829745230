/* Categories */
export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"

/**
 * add Categories
 */
 export const ADD_NEW_CATEGORIES = "ADD_NEW_CATEGORIES"
 export const ADD_CATEGORIES_SUCCESS = "ADD_CATEGORIES_SUCCESS"
 export const ADD_CATEGORIES_FAIL = "ADD_CATEGORIES_FAIL"
 
 /**
  * Edit Categories
  */
 export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES"
 export const UPDATE_CATEGORIES_SUCCESS = "UPDATE_CATEGORIES_SUCCESS"
 export const UPDATE_CATEGORIES_FAIL = "UPDATE_CATEGORIES_FAIL"
 
 /**
  * Delete Categories
  */
 export const DELETE_CATEGORIES = "DELETE_CATEGORIES"
 export const DELETE_CATEGORIES_SUCCESS = "DELETE_CATEGORIES_SUCCESS"
 export const DELETE_CATEGORIES_FAIL = "DELETE_CATEGORIES_FAIL"