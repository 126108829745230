import React, { Component } from "react";
import { Col, Row } from "reactstrap";

import profile from "../../../assets/images/profile-img.png"

function HeaderWelcome() {
   return (
      <Row>
         <Col className="col-7">
            <div className="text-primary p-4">
               <h5 className="text-primary">Bem vindo!</h5>
               <p>Faça o login para continuar.</p>
            </div>
         </Col>
         <Col className="col-5 align-self-end">
            <img src={profile} alt="" className="img-fluid" />
         </Col>
      </Row>
   );
}

export default HeaderWelcome;