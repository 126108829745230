import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
   Button,
   Col,
   Container,
   Row,
   Card,
   CardBody,
   Media,   
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ModalAssinatura from "./_components/ModalAssinatura";
import ModalAdd from "./_components/ModalAdd"
import ModalImg from "./_components/ModalFoto"

import {
   getCustomerDetail as onGetCustomerDetail,   
} from "store/users/actions";

import { useSelector, useDispatch } from "react-redux";
import { handleValidDate, handleValidOnlyDate, addZeroes, getStringShortcodeLocale } from "utils";
import { deleteUserApi } from "api/users";
import TokensSquare from "./_components/TokensSquare";
import ImagesSquare from "./_components/ImagesSquare";

function EditUser(props) {
   const dispatch = useDispatch();
   const idCliente = props.match.params.id;   
   

   const { customerDetail } = useSelector(state => ({
      customerDetail: state.users.customerDetail,
   }))

   const { subscribes } = useSelector(state => ({
      subscribes: state.users.subscribes,
   }))

   const { properties } = useSelector(state => ({
      properties: state.users.properties,
   }))

   const { schedules } = useSelector(state => ({
      schedules: state.users.schedules,
   })) 

   const { medias } = useSelector(state => ({
      medias: state.users.medias,
   }))
   
   const { tokens } = useSelector(state => ({
      tokens: state.users.tokens,
   })) 

   const [modalEditSubscribe, setModalEditSubscribe] = useState(false);
   const [isEditSubscribe, setIsEditSubscribe] = useState(false);
   const [itemSubscribeEdit, setItemSubscribeEdit] = useState(null);
   const [, setUserItem] = useState();
   const [isEdit, setIsEdit] = useState(false); 
   const [modalAdd, setModalAdd] = useState(false); 
   const [modalFoto, setModalFoto] = useState(false); 
   const [fotoSelected, setFotoSelected] = useState(null); 
   const [results, setResults] = useState();
   const [resultsSchedul, setResultsSchedul] = useState();   

   useEffect(() => {
      if (customerDetail && !Object.keys(customerDetail).length) {
         dispatch(onGetCustomerDetail(idCliente)); 
      }      
   }, [customerDetail]);   

   useEffect(() => {
      parseJSON()      
   }, [properties]);

   useEffect(() => {
      let resultSchedules = []; 
      if(schedules) {
         resultSchedules = Object.keys(schedules).length
      }
      setResultsSchedul(resultSchedules)
   }, [subscribes, schedules, tokens]);  

   const toggle = () => {
      setModalEditSubscribe(!modalEditSubscribe);
   }

   const toggleEdit = () => {
      setModalAdd(!modalAdd)
   }

   
   const toggleFoto = () => {
      setModalFoto(!modalFoto)
   }

   const handleCustomerClicks = () => {
      setItemSubscribeEdit(null);
      setIsEditSubscribe(false)
      toggle()
   }

   const handleEditCustomerClicks = (item) => {
      setItemSubscribeEdit(item);
      setIsEditSubscribe(true)
      toggle()
   }

   function goModalAdd(isEdit, item) {
      if(isEdit) {
         setUserItem(item);
      }
      setIsEdit(isEdit);
      toggleEdit();
   }  

   function goModalFoto(item) {    
      setFotoSelected(item); 
      setModalFoto(modalFoto);
      toggleFoto();
   }  

   const handleDeleteCustomer = async (customer) => {
      if (confirm("Tem certeza que deseja excluir?") == true) {
         await deleteUserApi({_id: customerDetail._id});
         window.location.href = `/clientes`;
      }
   } 

   const propertiesDetails = properties?.valueproperty; 
   const parseJSON = async () => {   
    if(propertiesDetails) {
     const res = await JSON.parse(propertiesDetails)
     setResults(res)  
     }  
   } 

   const arrParseObjetivo = [
      'Ficar Mais Em Forma',
      'Ganhar Massa Muscular',
      'Perder Peso'
   ]  

   const diffPesoInicialMeta = Number(results?.pesoInicial) - Number(results?.pesoMeta); 
   const perdaDePeso = Number(results?.pesoInicial) - Number(results?.pesoAtual) 
   const concluidoTemp = results?.pesoAtual ? parseFloat((perdaDePeso * 100) / diffPesoInicialMeta):0;  
   const concluido = addZeroes((concluidoTemp),1);  

   // console.log((subscribes,"subscribes"))

   

   return (
      <>
      <React.Fragment>
         <div className="page-content">
            <MetaTags>
               <title>Editar Clientes | Suore</title>
            </MetaTags>
            <Container fluid>
               <Breadcrumb title="Clientes" linkTitle="/clientes" breadcrumbItem="Editar" />
               <Row>
                  <Col xs="12" md="7">
                     <Card>
                        <CardBody>
                           <div className="mb-2 text-muted d-flex justify-content-between align-items-center">
                              <h5>Assinaturas</h5>
                              <Button
                                 type="button"
                                 color="success"
                                 className="btn-rounded  mb-2 me-2"
                                 onClick={handleCustomerClicks}
                              >
                                 <i className="mdi mdi-plus me-1" />
                                 Inserir Assinatura
                              </Button>
                           </div>
                           <div className="table-responsive">
                              {!subscribes || subscribes.length === 0 && <p>Nenhuma assinatura até o momento</p>}                             
                              {subscribes && subscribes.length > 0 && (
                                 <table className="table table-nowrap align-middle mb-0">
                                    <thead>
                                       <tr>
                                          <th>Tipo</th>
                                          <th>Status</th>
                                          <th>Pago em</th>
                                          <th>Expira em:</th>
                                          <th>Valor:</th>
                                          <th>Descrição:</th>
                                          <th>Ações</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {subscribes.map(item => {
                                          return (
                                             <tr key={item._id}>
                                                <td>{item?.type}</td>
                                                <td>{item.status}</td>
                                                <td>{handleValidDate(item.payday)}</td>
                                                <td>{handleValidDate(item.expirationDate)}</td>
                                                <td>{(item.value).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</td>
                                                <td>{item.statusDescription}</td>
                                                <td>
                                                   <Button
                                                      onClick={() => handleEditCustomerClicks(item)}
                                                      type="button"
                                                      color="info"
                                                      className="btn-rounded  mb-2 me-2">
                                                      <i className="fas fa-pencil-alt text-white me-1" />
                                                      Editar
                                                   </Button>
                                                </td>
                                             </tr>
                                          );
                                       })}
                                    </tbody>
                                 </table>
                              )}
                           </div>
                        </CardBody>
                     </Card>
                     <Card>
                        <CardBody>
                           <div className="mb-2 text-muted d-flex justify-content-between align-items-center">
                              <h5>Dispositivos</h5>                          
                           </div>
                           <div className="table-responsive">
                              <TokensSquare customerDetail={customerDetail} />
                           </div>
                        </CardBody>
                     </Card>
                     
                     <Card>
                        <CardBody>
                           <div className="mb-2 text-muted d-flex justify-content-between align-items-center">
                              <h5>Ultimos Treinos</h5>                             
                           </div>
                           <div>
                              {!resultsSchedul && resultsSchedul === 0  && <p>Nenhuma treino até o momento</p>}                            
                              {resultsSchedul && resultsSchedul > 0 && (
                                 <table className="table table-nowrap align-middle mb-0">
                                    <thead>
                                       <tr>
                                          <th>Descrição</th>
                                          <th>Dia</th>
                                          <th>Data</th>                                         
                                       </tr>
                                    </thead>
                                    <tbody>                                      
                                       {Object.values(schedules).map((item, idx) => {                                          
                                          if(idx > Object.values(schedules).length -5) {
                                             // console.log('item', item);
                                             let dayInfo = item?.workoutdays_idworkoutsdays?.name;
                                             if(!item?.workoutdays_idworkoutsdays?.name) {
                                                const adicionalInfo = JSON.parse(item.adicional_info);
                                                dayInfo = `Dia ${parseInt(adicionalInfo.day) + 1}`;
                                             }
                                             return (
                                                <tr key={item?._id}>
                                                   <td>{getStringShortcodeLocale(item?.workouts_idworkouts?.name, 'pt')}</td>                                               
                                                   <td>{dayInfo}</td>
                                                   <td>{handleValidOnlyDate(item.date_scheduled)}</td>           
                                                </tr>
                                             );
                                          }
                                       })}
                                    </tbody>
                                 </table>
                              )}
                           </div>
                        </CardBody>
                     </Card> 
                       <Card>
                        <CardBody>
                           <div className="mb-2 text-muted d-flex justify-content-between align-items-center">
                              <h5>Imagens</h5>                             
                           </div>
                           <ImagesSquare medias={medias} onGoModalFoto={goModalFoto} />
                        </CardBody>
                     </Card>                       
                  </Col>
                  <Col xs="12" md="5">
                     <Card>
                        <CardBody>
                           <Media>
                              <Media body className="align-self-center">
                                 <div className="text-muted">
                                    <h5>{customerDetail && customerDetail.name}</h5>
                                    <p><strong>Email:</strong> {customerDetail && customerDetail.email}</p>
                                    <p><strong>Id no:</strong> {customerDetail && customerDetail._id}</p>
                                    <p><strong>Versão app:</strong> {customerDetail && customerDetail.appversion ? customerDetail.appversion:null}</p>
                                    <p><strong>Está Ativo?</strong> {customerDetail && customerDetail.is_active ? 'Sim' : 'Não'}</p>
                                    <p><strong>Está bloquedo?</strong> {customerDetail && customerDetail.is_blocked ? 'Sim' : 'Não'}</p>
                                    <p><strong>Incompany?</strong> {customerDetail && customerDetail.incompany ? 'Sim' : 'Não'}</p>
                                    <p><strong>Gympass?</strong> {customerDetail && customerDetail.gympassid ? 'Sim' : 'Não'}</p>
                                    <p><strong>Device:</strong> {customerDetail && customerDetail.platform}</p>
                                    <p><strong>Criado em:</strong> {customerDetail && handleValidDate(customerDetail.created_at)}</p>
                                    <p><strong>Genero:</strong> {results && results?.genero === 0  ? 'Mulher' : 'Homem'}</p>
                                    <p><strong>Altura:</strong> {results && results?.altura}m</p>
                                    <p><strong>Peso Inicial:</strong> {results && results?.pesoInicial}Kg</p>
                                    <p><strong>Peso Atual:</strong> {results && results?.pesoAtual}Kg</p>                                  
                                    <p><strong>Peso Meta:</strong> {results && results?.pesoMeta}Kg</p>
                                    <p><strong>Peso Perdido:</strong> {perdaDePeso ? perdaDePeso : 0}Kg</p>
                                    <p><strong>Conclusão Meta:</strong> {concluido}%</p>
                                    <p><strong>Gordura Corporal:</strong> {results && results?.gorduraCorporal}</p>
                                    <p><strong>Gordura Corporal Meta:</strong> {results && results?.gorduraCorporalMeta}</p>
                                    <p><strong>Objetivo: </strong>{
                                       results?.objetivo && results?.objetivo.map((obj, i) => `${arrParseObjetivo[obj]} ${results?.objetivo.length === (i + 1) ? '' : ' - '}`)}                                   
                                    </p>                                                             
                                 </div>              
                              </Media>
                           </Media>
                           <Button 
                              style={{marginTop: '26px'}}                            
                              type="button"
                              color="success"
                              className="btn-rounded mb-2 me-2"
                              onClick={()=>goModalAdd(true)}
                           >
                              Editar Usuário
                           </Button>
                          
                           <Button
                              style={{marginLeft: '26px',marginTop: '26px'}}
                              type="button"
                              color="danger"
                              className="btn-rounded mb-2 me-2"
                              onClick={handleDeleteCustomer}
                           >
                              Deletar Usuário
                           </Button>                          
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </Container>
            {customerDetail && <ModalAssinatura modal={modalEditSubscribe} isEdit={isEditSubscribe} toggle={toggle} id={customerDetail._id} itemSubscribeEdit={itemSubscribeEdit} />}
         </div>
      </React.Fragment>
      <ModalAdd modal={modalAdd} toggleEdit={toggleEdit} isEdit={isEdit} userItem={customerDetail} />
      <ModalImg modalFoto={modalFoto} toggleFoto={toggleFoto} userItem={medias} fotoSelected={fotoSelected} />
      </> 
   );
}

EditUser.propTypes = {
   match: PropTypes.object,
   onAddNewCustomer: PropTypes.func,
   onDeleteCustomer: PropTypes.func,
   onUpdateCustomer: PropTypes.func,
}

export default EditUser;