import {
   GET_TRAINERS,
   GET_TRAINERS_FAIL,
   GET_TRAINERS_SUCCESS,
   ADD_NEW_CUSTOMER,
   ADD_CUSTOMER_SUCCESS,
   ADD_CUSTOMER_FAIL,
   UPDATE_CUSTOMER,
   UPDATE_CUSTOMER_SUCCESS,
   UPDATE_CUSTOMER_FAIL,
   DELETE_CUSTOMER,
   DELETE_CUSTOMER_SUCCESS,
   DELETE_CUSTOMER_FAIL,
} from "./actionTypes"

export const getTrainers = () => ({
   type: GET_TRAINERS,
})

export const getTrainersSuccess = Trainers => ({
   type: GET_TRAINERS_SUCCESS,
   payload: Trainers,
})

export const getTrainersFail = error => ({
   type: GET_TRAINERS_FAIL,
   payload: error,
})

export const addNewCustomer = customer => ({
   type: ADD_NEW_CUSTOMER,
   payload: customer,
})

export const addTrainerSuccess = customer => ({
   type: ADD_CUSTOMER_SUCCESS,
   payload: customer,
})

export const addCustomerFail = error => ({
   type: ADD_CUSTOMER_FAIL,
   payload: error,
})

export const updateCustomer = customer => ({
   type: UPDATE_CUSTOMER,
   payload: customer,
})

export const updateTrainerSuccess = customer => ({
   type: UPDATE_CUSTOMER_SUCCESS,
   payload: customer,
})

export const updateCustomerFail = error => ({
   type: UPDATE_CUSTOMER_FAIL,
   payload: error,
})

export const deleteCustomer = customer => ({
   type: DELETE_CUSTOMER,
   payload: customer,
})

export const deleteTrainerSuccess = customer => ({
   type: DELETE_CUSTOMER_SUCCESS,
   payload: customer,
})

export const deleteCustomerFail = error => ({
   type: DELETE_CUSTOMER_FAIL,
   payload: error,
})