import React, { useEffect, useState } from "react";
import {
   Modal,
   ModalHeader,
   ModalBody,
} from "reactstrap";
import PropTypes from "prop-types";

import FormGeneralInfo from "./_formGeneralInfo";
import FormVideoTraining from "./_formVideoTraining";
import FormAudioTraining from "./_formAudioTraining";
import FormCategorias from "./_formCategorias";

function ModalAdd({ modal, toggle, isEdit, trainingItem }) {
   const [tabActive, setTabActive] = useState(0);

   useEffect(()=>{
      setTabActive(0);
   }, [isEdit, trainingItem]);

   return (
      <Modal isOpen={modal} toggle={toggle}>
         <ModalHeader toggle={toggle} tag="h4">
            {isEdit ? 'Editar' : 'Adicionar'} Exercício - <small>{isEdit ? trainingItem._id:''}</small>
         </ModalHeader>
         {isEdit && <div className="d-flex w-100 mt-4">
            <div className="btn-group mb-3 mx-auto">
               <button type="button" onClick={()=>setTabActive(0)}
                  className={`btn btn-outline-primary w-sm ${tabActive === 0 ? 'active':''}`}>
                  Informações Gerais
               </button>
               <button type="button" onClick={()=>setTabActive(1)}
                  className={`btn btn-outline-primary w-sm ${tabActive === 1 ? 'active':''}`}>
                  Vídeo
               </button>
               <button type="button" onClick={()=>setTabActive(2)}
                  className={`btn btn-outline-primary w-sm ${tabActive === 2 ? 'active':''}`}>
                  Áudio
               </button>
               <button type="button" onClick={()=>setTabActive(3)}
                  className={`btn btn-outline-primary w-sm ${tabActive === 3 ? 'active':''}`}>
                  Categorias
               </button>
            </div>
         </div>}
         <ModalBody>
            {tabActive === 0 && <FormGeneralInfo isEdit={isEdit} trainingItem={trainingItem} toggle={toggle} />}
            {tabActive === 1 && <FormVideoTraining isEdit={isEdit} trainingItem={trainingItem} toggle={toggle} />}
            {tabActive === 2 && <FormAudioTraining isEdit={isEdit} trainingItem={trainingItem} toggle={toggle} />}
            {tabActive === 3 && <FormCategorias isEdit={isEdit} trainingItem={trainingItem} toggle={toggle} />}
         </ModalBody>
      </Modal>
   );
}

ModalAdd.propTypes = {
   modal: PropTypes.bool,
   isEdit: PropTypes.bool,
   toggle: PropTypes.func,
   trainingItem: PropTypes.object,
}


export default ModalAdd;