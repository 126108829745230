import React from "react"

function FooterWelcome() {
   return (
      <p>
         © {new Date().getFullYear()} Suore.
      </p>
   );
}

export default FooterWelcome;