import React, { useEffect, useState } from "react";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
 } from "react-bootstrap-table2-paginator";
 import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
 import {
    Button,
    Col,
    Row,
 } from "reactstrap";
 const { SearchBar } = Search;
 import BootstrapTable from "react-bootstrap-table-next";
 import PropTypes from "prop-types";

 import { useDispatch } from "react-redux";

 import {
    getCustomersSearchClean as onGetCustomersSearchClean,
 } from "store/users/actions";

function TableComponent({EcommerceCustomerColumns, pageOptions, data, totalCustomer, toggle, defaultSorted, handleTableChange, filtered, showTotal, showFilter}) {

    const dispatch = useDispatch();

    function cleanFilter() {
        dispatch(onGetCustomersSearchClean());
    }

    return (
        <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="_id"
            columns={EcommerceCustomerColumns}
            data={data}
        >
            {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                keyField="_id"
                data={data || []}
                columns={EcommerceCustomerColumns}
                bootstrap4
                search
                >
                {toolkitProps => (
                    <React.Fragment>
                        <Row className="mb-2">
                            <Col sm="4">
                            <div className="d-flex align-items-center ms-2 mb-2">
                                <div className="search-box d-inline-block">
                                    <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                    </div>
                                </div>
                                {showFilter && <Button
                                    className="bg-info d-flex align-items-center"
                                    style={{marginLeft:15}}
                                    onClick={toggle}
                                >
                                    <i className="bx bx-filter text-white font-size-20" /> 
                                    <span className="text-white font-size-15">Filtros</span>
                                </Button>}
                                {filtered && 
                                    <Button
                                        onClick={()=>cleanFilter()}
                                        className="bg-warning bg-rounded" 
                                        style={{marginLeft:15}}>
                                            <span className="text-white">Limpar Filtro</span>
                                    </Button>}
                            </div>
                            </Col>
                            <Col sm="8">
                                <div>
                                    <div className="text-sm-end">
                                        <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-15 py-2 px-4">
                                            {!filtered && showTotal && <span>{totalCustomer} Cadastros</span>}
                                            {filtered && showTotal && <span>{data.length} Cadastro(s)</span>}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="12">
                            <div className="table-responsive">
                                <BootstrapTable
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    keyField="id"
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                />
                            </div>
                            </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                            <PaginationListStandalone
                                {...paginationProps}
                            />
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
                </ToolkitProvider>
            )}
        </PaginationProvider>
    );
}

TableComponent.propTypes = {
    EcommerceCustomerColumns: PropTypes.array,
    pageOptions: PropTypes.object,
    data: PropTypes.array,
    totalCustomer: PropTypes.number,
    toggle: PropTypes.func,
    defaultSorted: PropTypes.array,
    handleTableChange:  PropTypes.func,
    filtered: PropTypes.number,
    showTotal: PropTypes.number,
    showFilter: PropTypes.number,
} 

export default TableComponent;