import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Label,
} from "reactstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { uploadAudioApi } from "api/trainings";
import Loading from "components/Loading";
import { filterArrForTypeVideoAudio } from "utils";

import {
    getTrainings as onGetTrainings,
 } from "store/trainings/actions";
 import { useDispatch } from "react-redux";

const FormAudioTraining = ({ isEdit, trainingItem, toggle }) => {
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);

    console.log('trainingItem.medias', trainingItem.medias);

    const hasAudio = trainingItem.medias && filterArrForTypeVideoAudio(trainingItem.medias, 'audio');

    useEffect(() => {
        const checkHasVideo = () => {
            const hasAudio = trainingItem.medias && filterArrForTypeVideoAudio(trainingItem.medias, 'audio');

            hasAudio[0] && setVideoUrl(hasAudio[0].url);
        }
        checkHasVideo();
    }, [trainingItem])

    const handleValidCustomerSubmit = async (data) => {
        console.log('data',data);
        if (data.video) {
            setIsLoading(true);
            const _idVideo = hasAudio[0] ? hasAudio[0]._id : null;

            const formData = new FormData();
            formData.append('file', data.video[0]);
            formData.append('_id', trainingItem._id);
        
            if(_idVideo) {
                formData.append('_idVideo', _idVideo);
            }

            const header = {
                path: `uploads/trainings/${trainingItem.idtrainings}`,
            }

            const resp = await uploadAudioApi(formData, header);

            setVideoUrl(resp.data.url)
            setIsLoading(false);
            dispatch(onGetTrainings());
        }
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(handleValidCustomerSubmit)}
            >
                <Row form>
                    <Col className="col-12">
                        <div className="mt-3">
                            <div className="form-group">
                                <Label htmlFor="video" className="form-label">Upload Vídeo</Label>
                                <input type="file" {...register("video", { required: !isEdit ? true : false })} className="form-control form-control-sm" id="formFileSm" />
                                {errors.video && <div className="alert alert-danger">Campo obrigatório</div>}
                                <div>
                                    <Label htmlFor="video" className="form-label mt-2">Preview</Label><br />
                                    {videoUrl && (
                                        <video width="320" height="240" controls>
                                            <source src={videoUrl} type="audio/mp3" />
                                        </video>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <div className="text-end">
                            {!isLoading && <button
                                type="submit"
                                className="btn btn-success save-customer"
                            >
                                Salvar
                            </button>}
                            {isLoading && <Loading />}
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    );
}

FormAudioTraining.propTypes = {
    toggle: PropTypes.func,
    isEdit: PropTypes.bool,
    trainingItem: PropTypes.object,
}

export default FormAudioTraining;