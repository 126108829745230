import {
   GET_TRAINERS,
   GET_TRAINERS_FAIL,
   GET_TRAINERS_SUCCESS,
   ADD_CUSTOMER_SUCCESS,
   ADD_CUSTOMER_FAIL,
   UPDATE_CUSTOMER_SUCCESS,
   UPDATE_CUSTOMER_FAIL,
   DELETE_CUSTOMER_SUCCESS,
   DELETE_CUSTOMER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
   trainers: [],
}

const Trainers = (state = INIT_STATE, action) => {
   switch (action.type) {

      case GET_TRAINERS:
         return {
            ...state,
            trainers: action.payload,
         }

      case GET_TRAINERS_SUCCESS:
         return {
            ...state,
            trainers: action.payload,
         }

      case GET_TRAINERS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case ADD_CUSTOMER_SUCCESS:
         return {
            ...state,
            trainers: [...state.trainers, action.payload],
         }

      case ADD_CUSTOMER_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case UPDATE_CUSTOMER_SUCCESS:
         return {
            ...state,
            trainers: state.trainers.map(trainer =>
               trainer.id.toString() === action.payload.id.toString()
                  ? { trainer, ...action.payload }
                  : trainer
            ),
         }

      case UPDATE_CUSTOMER_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case DELETE_CUSTOMER_SUCCESS:
         return {
            ...state,
            trainers: state.trainers.filter(
               customer => customer.id.toString() !== action.payload.id.toString()
            ),
         }

      case DELETE_CUSTOMER_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      default:
         return state
   }
}

export default Trainers
