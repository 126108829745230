import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_WORKOUTSDAYS } from "./actionTypes"
import { getWorkoutsDaysFail, getWorkoutsDaysSuccess } from "./actions"

import {
  getWorkoutsDays,
} from '../../api/workoutsDays';

function* fetchWorkoutsDays() {  
  try {
    const response = yield call(getWorkoutsDays)
    yield put(getWorkoutsDaysSuccess(response.data.days))
  } catch (error) {
    yield put(getWorkoutsDaysFail(error))
  }
}


function* tasksSaga() {  
  yield takeEvery(GET_WORKOUTSDAYS, fetchWorkoutsDays)  
}

export default tasksSaga
