import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Label
} from "reactstrap";
import {
    getTrainings as onGetTrainings,
 } from "store/trainings/actions";
 import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { addTraining, editTraining } from "api/trainings";
import { useDispatch } from "react-redux";
import { getStringShortcodeLocale } from "utils";

const FormGeneralInfo = ({isEdit, trainingItem, toggle}) => {
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const dispatch = useDispatch();

    const [isEditLocal, setIsEditLocal] = useState(false);   

    useEffect(() => {
        setIsEditLocal(isEdit);
        const name = trainingItem.name && trainingItem.name.indexOf("[pt]") > -1 ? getStringShortcodeLocale(trainingItem.name, 'pt'):trainingItem.name;
        const nameEN = trainingItem.name && trainingItem.name.indexOf("[en]") > -1 ? getStringShortcodeLocale(trainingItem.name, 'en'):"";
        const nameES = trainingItem.name && trainingItem.name.indexOf("[es]") > -1 ? getStringShortcodeLocale(trainingItem.name, 'es'):"";
  
        reset({
           name: isEdit ? name:'',
           nameEn: isEdit ? nameEN:'',
           nameEs: isEdit ? nameES:'',
           duration: isEdit ? trainingItem.duration:'',
           tags: isEdit ? trainingItem.categories:'',
        });
     }, [isEdit, trainingItem]);

     const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
           const fileReader = new FileReader();
           fileReader.readAsDataURL(file);
           fileReader.onload = () => {
              resolve(fileReader.result);
           };
           fileReader.onerror = (error) => {
              reject(error);
           };
        })
     }

    const handleValidCustomerSubmit = async (data) => {
        console.log('data', data);

        if (data.name) {
            const nameFinal = `[pt]${data.name}[:pt][en]${data.nameEn}[:en][es]${data.nameEs}[:es]`;
            const base64 = data.thumbnail[0] ? await convertBase64(data.thumbnail[0]):null;
            console.log(base64);
            const objToUpdate = {
                name: nameFinal,
                duration: data.duration,
                tags: data.tags,
                image: base64,
            };

            if (isEdit) {
                objToUpdate._id = trainingItem._id;
                await editTraining(objToUpdate);
            }
            else {
                //add
                await addTraining(objToUpdate);
            }            

            dispatch(onGetTrainings());
            toggle();
        }
    }
    return (
        <>
            <form
                onSubmit={handleSubmit(handleValidCustomerSubmit)}
            >
                <Row form>
                    <Col className="col-12">
                        <div className="mb-3">
                            <div className="form-group">
                                <label htmlFor="name" className="">Nome</label>
                                <input {...register("name", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                                {errors.name && <div className="alert alert-danger">Campo obrigatório</div>}
                            </div>
                        </div>

                        <div className="mb-3">
                            <div className="form-group">
                                <label htmlFor="nameEn" className="">Nome Inglês</label>
                                <input {...register("nameEn", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                                {errors.nameEn && <div className="alert alert-danger">Campo obrigatório</div>}
                            </div>
                        </div>

                        <div className="mb-3">
                            <div className="form-group">
                                <label htmlFor="nameEs" className="">Nome Espanhol</label>
                                <input {...register("nameEs", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                                {errors.nameEs && <div className="alert alert-danger">Campo obrigatório</div>}
                            </div>
                        </div>

                        <div className="mb-3">
                            <div className="form-group">
                                <label htmlFor="duration" className="">Duração</label>
                                <input {...register("duration", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                                {errors.duration && <div className="alert alert-danger">Campo obrigatório</div>}
                            </div>
                        </div>

                        <div className="mt-3">
                        <div className="form-group">
                           <Label htmlFor="thumbnail" className="form-label">Upload Thumb</Label>
                           <input type="file" {...register("thumbnail", { required: !isEdit ? true:false })} className="form-control form-control-sm" id="formFileSm" />
                           {errors.thumbnail && <div className="alert alert-danger">Campo obrigatório</div>}
                           {isEditLocal && <div>
                              <Label htmlFor="thumbnail" className="form-label mt-2">Preview</Label><br/>
                              <img src={trainingItem?.thumbnail_url} style={{width: '100px', height: 'auto'}} />
                           </div>}
                        </div>
                     </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <div className="text-end">
                            <button
                                type="submit"
                                className="btn btn-success save-customer"
                            >
                                Salvar
                            </button>
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    );
}

FormGeneralInfo.propTypes = {
    toggle: PropTypes.func,
    isEdit: PropTypes.bool,
    trainingItem: PropTypes.object,
 }

export default FormGeneralInfo;