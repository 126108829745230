import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
   Col,
   Container,
   Row,
} from "reactstrap";

import $ from 'jquery';
import { useDispatch } from "react-redux";
import ProcurarExercicios from "./_components/ProcurarExercicios";
import { getStringShortcodeLocale } from "utils";
import { findOneDay, addWorkoutsDaysSetup } from "api/workoutsDays"
import { useSelector } from "react-redux";
import Loading from "components/Loading";
import Breadcrumb from "components/Common/Breadcrumb2";

const WorkoutsConfig = props => {
   const idworkoutdays = props.match.params.id;
   const dispatch = useDispatch();

   const [dayData, setDayData] = useState(null);
   const [carregouScript, setCarregouScript] = useState(false);
   const [isSaving, setIsSaving] = useState(false);
   const [showSuccessFeedBack, setShowSuccessFeedBack] = useState(false);

   useEffect(() => {
      const fetchDayData = async () => {
         const dayData = await findOneDay(idworkoutdays);
         console.log('dayData', dayData);
         if (!dayData.data.error) {
            setDayData(dayData.data.day);
         }
      };
      fetchDayData();
   }, []);

   useEffect(()=>{
      if(showSuccessFeedBack) {
         setTimeout(()=>{
            setShowSuccessFeedBack(false);
         }, 1000);
      }
   },[showSuccessFeedBack])

   const { workoutsDays } = useSelector(state => {
      return {
         workoutsDays: state.workoutsDays,
      }
   })

   const [identificador, setIdentificador] = useState("");
   const [exercicioId, setExercicioId] = useState("");
   const [textEn, setTextEn] = useState("");
   const [textEs, setTextEs] = useState("");
   const [tempo, setTempo] = useState("");
   const [strContent, setStrContent] = useState("");
   const [breadcrumbItems, setBreadcrumbItems] = useState([
      {
         title: 'Treinos',
         link: '/treinos',
      },
      {
         title: 'Dias',
         link: '/workouts/:id/dia',
      },
   ]);

   let editor = null;

   const iconPickerOptions = {
      searchText: "Buscar...",
      labelHeader: "{0}/{1}"
   };
   const sortableListOptions = {
      placeholderCss: {
         'background-color': "#cccccc"
      }
   };


   useEffect(() => {

      const scriptIconPicker = document.createElement('script');
      scriptIconPicker.src = "https://appfequeiroz.s3.us-east-2.amazonaws.com/data/js/bootstrap-iconpicker.min.js";
      scriptIconPicker.async = true;
      document.body.appendChild(scriptIconPicker);

      const script = document.createElement('script');

      scriptIconPicker.onload = function () {
         script.src = "https://appfequeiroz.s3.us-east-2.amazonaws.com/data/js/jquery-menu-editor.min.js";
         script.async = true;
         document.body.appendChild(script);
      };

      script.onload = function () {
         setCarregouScript(true);
      };

      return () => {
         document.body.removeChild(script);
         document.body.removeChild(scriptIconPicker);
      }
   }, []);

   useEffect(() => {
      if (carregouScript && dayData) {
         const newBreadcrumb = [...breadcrumbItems];
         newBreadcrumb[1].link = `/workouts/${dayData?.workouts_workoutDay?._id}/dia`;
         newBreadcrumb[2] = {title:dayData?.name,link:'#'};
         setBreadcrumbItems(newBreadcrumb);
         setUpAllSchema();
      }
   }, [dayData, carregouScript])

   function setUpAllSchema() {
      editor = new MenuEditor('myEditor', {
         listOptions: sortableListOptions,
         iconPicker: iconPickerOptions
      });

      console.log('dayData', dayData);
      editor.setForm($('#frmEdit'));
      editor.setUpdateButton($('#btnUpdate'));

      editor.setData(dayData.settings);

      document.querySelector('#btnUpdate').addEventListener('click', function () {
         editor.update();
      });

      document.querySelector('#btnAdd').addEventListener('click', function () {
         editor.add();
      });

      document.querySelector('.btn-salvar').addEventListener('click', function () {
         const str = editor.getString();
         console.log('str', str);
         setStrContent(str)
      });

      document.querySelector('.btn-clone').addEventListener('click', function () {
         cloneMenu();
      });
   }

   function cloneMenu() {
      const json = JSON.parse(editor.getString());
      const newObj = {
         ...json
      };

      for (let i = json.length; i < json.length * 2; i++) {
         let pos = i - 2;
         if (pos < 0)
            pos = 0
         newObj[i] = {
            ...json[pos]
         }
      }
      editor.setData(JSON.stringify(newObj));
   }

   function handleTrainingAutoComplete(selection) {
      console.log('selecton', selection, selection._id);
      setExercicioId(selection._id.toString());
      setTempo(selection.duration);
      setIdentificador(getStringShortcodeLocale(selection.name, 'pt'));
      setTextEn(getStringShortcodeLocale(selection.name, 'en'));
      setTextEs(getStringShortcodeLocale(selection.name, 'es'));
      $('select[name="categorie"]').val('exercicio');
   }


   const saveDayConfig = async (data) => {
      setIsSaving(true);
      const objToSend = {
         settings: strContent,
         _id: idworkoutdays,
      };
      // console.log(objToSend, "json")

      await addWorkoutsDaysSetup(objToSend);
      setIsSaving(false);
      setShowSuccessFeedBack(true);

      window.location.reload();
   }

   return (
      <>
         <React.Fragment>
            <div className="page-content mb-5">
               <MetaTags>
                  <title>Treinadoras | Suore</title>
               </MetaTags>
               <Container fluid>
                  {dayData && <Breadcrumb title={`${getStringShortcodeLocale(dayData?.workouts_workoutDay?.name, 'pt')} - ${dayData?.name} - Setup`} breadcrumbItems={breadcrumbItems} />}

                  <ProcurarExercicios onHandleTrainingAutoComplete={handleTrainingAutoComplete} />
                  <Row className="mb-2 mt-5">
                     <Col sm="6">
                        <div className="panel panel-flat">
                           <div className="panel-heading">
                              <h5 className="panel-title">Adicionar Itens</h5>
                           </div>

                           <div className="panel-body">
                              <div className="card border-primary mb-3">
                                 <div className="card-body">
                                    <form id="frmEdit" className="form-horizontal">
                                       <div className="form-group mb-4">
                                          <label htmlFor="text">Identificador</label>
                                          <div className="input-group">
                                             <input type="text"
                                                className="form-control item-menu"
                                                name="text"
                                                id="text"
                                                placeholder="Identificador"
                                                defaultValue={identificador}
                                                onChange={(e) => setIdentificador(e.currentTarget.value)}
                                             />
                                          </div>
                                       </div>
                                       <input type="hidden" className="item-menu" name="textEn" id="textEn" defaultValue={textEn} />
                                       <input type="hidden" className="item-menu" name="textEs" id="textEs" defaultValue={textEs} />
                                       <div className="form-group mb-4">
                                          <label htmlFor="target">Categoria</label>
                                          <select name="categorie" id="categorie" className="form-control item-menu">
                                             <option value="cicuito">Circuito</option>
                                             <option value="serie">Série</option>
                                             <option value="exercicio">Exercício</option>
                                             <option value="descanso">Descanso</option>
                                          </select>
                                       </div>
                                       <div className="form-group mb-4">
                                          <label htmlFor="href">Tempo/Repeticões</label>
                                          <input type="text" className="form-control item-menu" id="tempo" name="tempo" placeholder="Tempo" defaultValue={tempo} />
                                          <span className="help-block">Se for tempo, coloque o tempo sempre em segundos</span>
                                       </div>
                                       <div className="form-group mb-4">
                                          <label htmlFor="target">Tipo</label>
                                          <select name="type_time" id="type_time" className="form-control item-menu">
                                             <option value="rep">Repetições</option>
                                             <option value="time">Tempo</option>
                                          </select>
                                       </div>
                                       <div className="form-group mb-4">
                                          <label htmlFor="target">Exercício Id</label>
                                          <input type="text" className="form-control item-menu" name="exercicio_id" id="exercicio_id" defaultValue={exercicioId} />
                                       </div>
                                       
                                    </form>
                                 </div>
                                 <div className="card-footer d-flex justify-content-end">
                                    <button type="button" id="btnUpdate" className="btn btn-primary" style={{ marginRight: '30px' }} disabled><i className="fas fa-sync-alt"></i> Atualizar</button>
                                    <button type="button" id="btnAdd" className="btn btn-success ml-4"><i className="fas fa-plus"></i> Adicionar</button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </Col>
                     <Col sm="6">
                        <div className="panel panel-flat">
                           <div className="panel-heading">
                              <h5 className="panel-title">Treino Salvo</h5>
                           </div>

                           <div className="panel-body">
                              <ul id="myEditor" className="sortableLists list-group"></ul>
                           </div>

                           {showSuccessFeedBack && <div className="alert alert-success mt-2">
                              Salvo com sucesso.
                           </div>}

                           <div className="d-flex justify-content-end mt-4">
                              <a href="#" target="_blank" className="btn btn-blue" style={{ margin: "0 10px 30px 0", backgroundColor: `#1E88E5`, color: `#fff` }}>
                                 Visualizar Treino <i className="fa fa-eye position-right"></i>
                              </a>
                              <button className="btn btn-warning btn-clone" style={{ margin: '0 10px 30px 0' }}>
                                 Clonar <i className="fa fa-clone position-right"></i>
                              </button>
                              {isSaving && <Loading />}
                              {!isSaving && <button onClick={() => saveDayConfig()} className="btn btn-success btn-salvar" style={{ margin: '0 0px 30px 0', padding: '5px 30px' }}>
                                 Salvar <i className="icon-arrow-right14 position-right"></i>
                              </button>}
                           </div>
                        </div>
                     </Col>
                  </Row>
               </Container>
            </div>
         </React.Fragment>
      </>
   )
}

WorkoutsConfig.propTypes = {
   match: PropTypes.object,
   trainers: PropTypes.array,
   total: PropTypes.number,
   onGetWorkoutsDays: PropTypes.func,
   onAddNewCustomer: PropTypes.func,
   onDeleteCustomer: PropTypes.func,
   onUpdateCustomer: PropTypes.func,
}

export default WorkoutsConfig;
