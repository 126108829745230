import { 
    GET_MODALITIES, 
    GET_MODALITIES_FAIL, 
    GET_MODALITIES_SUCCESS,
    ADD_NEW_MODALITIES,
    ADD_MODALITIES_SUCCESS,
    ADD_MODALITIES_FAIL,
    UPDATE_MODALITIES,
    UPDATE_MODALITIES_SUCCESS,
    UPDATE_MODALITIES_FAIL,
    DELETE_MODALITIES,
    DELETE_MODALITIES_FAIL,
    DELETE_MODALITIES_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  modalities: [],
  error: {},
}

const modalities = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MODALITIES:
         return {
            ...state,
            modalities: action.payload,
         }

      case GET_MODALITIES_SUCCESS:
         return {
            ...state,
            modalities: action.payload,
         }

      case GET_MODALITIES_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case ADD_NEW_MODALITIES:
         return {
            ...state,
            modalities: [...state.modalities, action.payload],
         }

      case ADD_MODALITIES_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case UPDATE_MODALITIES:
         return {
            ...state,
            modalities: state.modalities.map(modality =>
              modality.id.toString() === action.payload.id.toString()
                  ? { modality, ...action.payload }
                  : modality
            ),
         }

      case UPDATE_MODALITIES_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case DELETE_MODALITIES_SUCCESS:
         return {
            ...state,
            modalities: state.modalities.filter(
               modality => modality.id.toString() !== action.payload.id.toString()
            ),
         }

      case DELETE_MODALITIES_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      default:
         return state
  }
}

export default modalities;
