import { 
  GET_CATEGORIES, 
  GET_CATEGORIES_SUCCESS, 
  GET_CATEGORIES_FAIL, 
  ADD_NEW_CATEGORIES, 
  ADD_CATEGORIES_FAIL, 
  ADD_CATEGORIES_SUCCESS, 
  UPDATE_CATEGORIES, 
  UPDATE_CATEGORIES_FAIL, 
  UPDATE_CATEGORIES_SUCCESS, 
  DELETE_CATEGORIES, 
  DELETE_CATEGORIES_SUCCESS, 
  DELETE_CATEGORIES_FAIL 
} from "./actionTypes"

export const getCategories = () => ({
  type: GET_CATEGORIES,
})

export const getCategoriesSuccess = tasks => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: tasks,
})

export const getCategoriesFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
})

export const addNewCategories = category => ({
  type: ADD_NEW_CATEGORIES,
  payload: category,
})

export const addCategoriesSuccess = category => ({
  type: ADD_CATEGORIES_SUCCESS,
  payload: category,
})

export const addCategoriesFail = error => ({
  type: ADD_CATEGORIES_FAIL,
  payload: error,
})

export const updateCategories = category => ({
  type: UPDATE_CATEGORIES,
  payload: category,
})

export const updateCategoriesSuccess = category => ({
  type: UPDATE_CATEGORIES_SUCCESS,
  payload: category,
})

export const updateCategoriesFail = error => ({
  type: UPDATE_CATEGORIES_FAIL,
  payload: error,
})

export const deleteCategories = category => ({
  type: DELETE_CATEGORIES,
  payload: category,
})

export const deleteCategoriesSuccess = category => ({
  type: DELETE_CATEGORIES_SUCCESS,
  payload: category,
})

export const deleteCategoriesFail = error => ({
  type: DELETE_CATEGORIES_FAIL,
  payload: error,
})
