/* Categories Workouts */
export const GET_CATEGORIES_WORKOUTS = "GET_CATEGORIES_WORKOUTS"
export const GET_CATEGORIES_WORKOUTS_SUCCESS = "GET_CATEGORIES_WORKOUTS_SUCCESS"
export const GET_CATEGORIES_WORKOUTS_FAIL = "GET_CATEGORIES_WORKOUTS_FAIL"

/**
 * add Categories Workouts
 */
 export const ADD_NEW_CATEGORIES_WORKOUTS = "ADD_NEW_CATEGORIES_WORKOUTS"
 export const ADD_CATEGORIES_WORKOUTS_SUCCESS = "ADD_CATEGORIES_WORKOUTS_SUCCESS"
 export const ADD_CATEGORIES_WORKOUTS_FAIL = "ADD_CATEGORIES_WORKOUTS_FAIL"
 
 /**
  * Edit Categories Workouts
  */
 export const UPDATE_CATEGORIES_WORKOUTS = "UPDATE_CATEGORIES_WORKOUTS"
 export const UPDATE_CATEGORIES_WORKOUTS_SUCCESS = "UPDATE_CATEGORIES_WORKOUTS_SUCCESS"
 export const UPDATE_CATEGORIES_WORKOUTS_FAIL = "UPDATE_CATEGORIES_WORKOUTS_FAIL"
 
 /**
  * Delete Categories Workouts
  */
 export const DELETE_CATEGORIES_WORKOUTS = "DELETE_CATEGORIES_WORKOUTS"
 export const DELETE_CATEGORIES_WORKOUTS_SUCCESS = "DELETE_CATEGORIES_WORKOUTS_SUCCESS"
 export const DELETE_CATEGORIES_WORKOUTS_FAIL = "DELETE_CATEGORIES_WORKOUTS_FAIL"