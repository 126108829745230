import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as moment from "moment"
import {
   Card,
   CardBody,
   Col,
   Container,
   Row,
   Button,
   DropdownItem,
   DropdownMenu,
   DropdownToggle,
   UncontrolledDropdown,
} from "reactstrap"

//Import Breadcrumb
import {
   getCategories as onGetCategories,
} from "store/categories/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableComponent from "./_components/TableComponent"
import ModalAdd from "./_components/ModalAdd"
import { deleteCategories } from "api/categories"

const Categories = props => {
   const dispatch = useDispatch()

   const { categories } = useSelector(state => ({
      categories: state.categories.categories,
   }))
   

   const [customerList, setCustomerList] = useState([]);
   const [isEdit, setIsEdit] = useState(false);
   const [categoryItem, setCategoryItem] = useState(false);
   const [modalAdd, setModalAdd] = useState(false);

   //pagination customization
   const pageOptions = {
      sizePerPage: 10,
      totalSize: categories && categories.length, // replace later with size(orders),
      custom: true,
   }

   function goModalAdd(isEdit, item) {
      if(isEdit) {
         setCategoryItem(item);
      }
      setIsEdit(isEdit);
      toggle();
   }

   const handleDeleteCategory = async (category) => {
      if (confirm("Tem certeza que deseja excluir?") == true) {
         await deleteCategories({_id: category._id});
         dispatch(onGetCategories());
      }
   }

   const EcommerceCustomerColumns = [
      {
         text: "id",
         dataField: "id",
         sort: true,
         hidden: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => <>{row.id}</>,
      },
      {
         text: "Nome",
         dataField: "name",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => (
            <a style={{color:'inherit'}} onClick={()=>goModalAdd(true, row)}>
               <p className="mb-0">{row.name}</p>
            </a>
         ),
      },
      {
         dataField: "type",
         text: "Tipo",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => <a style={{color:'inherit'}} onClick={()=>goModalAdd(true, row)}>{row.type}</a>,
      },
      {
         dataField: "menu",
         isDummyField: true,
         text: "Ações",
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, item) => (
            <UncontrolledDropdown direction="left">
               <DropdownToggle href="#" className="card-drop" tag="i">
                  <i className="mdi mdi-dots-horizontal font-size-18" />
               </DropdownToggle>
               <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                     onClick={()=>goModalAdd(true, item)}
                  >
                     <i className="fas fa-pencil-alt text-success me-1" />
                     Editar
                  </DropdownItem>
                  <DropdownItem
                     href="#"
                     onClick={() => handleDeleteCategory(item)}
                  >
                     <i className="fas fa-trash-alt text-danger me-1" />
                     Excluir
                  </DropdownItem>
               </DropdownMenu>
            </UncontrolledDropdown>
         ),
      },
   ]

   const toggle = () => {
      setModalAdd(!modalAdd)
   }

   useEffect(() => {
      if (categories && !categories.length) {
         dispatch(onGetCategories())
      }
   }, [dispatch, categories])

   useEffect(() => {
      setCustomerList(categories)
   }, [categories])

   useEffect(() => {
      if (!isEmpty(categories)) {
         setCustomerList(categories)
      }
   }, [categories])

   // eslint-disable-next-line no-unused-vars
   const handleTableChange = (type, { page, searchText }) => {
      setCustomerList(
         categories.filter(customer =>
            Object.keys(customer).some(key =>
               customer[key].toLowerCase().includes(searchText.toLowerCase())
            )
         )
      )
   }

   const defaultSorted = [
      {
         dataField: "created_at_dt",
         order: "desc",
      },
   ]

   /** set Date formate */
   const handleValidDate = date => {
      const date1 = moment(new Date(date)).format("DD/MM/YYYY")
      return date1
   }

   return (
      <>
      <React.Fragment>
         <div className="page-content">
            <MetaTags>
               <title>Treino - Categorias</title>
            </MetaTags>
            <Container fluid>
               {/* <Breadcrumbs title="Suore" breadcrumbItem="Clientes" /> */}
               <h3>Treino - Categorias</h3>
               <Row className="mb-2">
                  <Col sm="4"></Col>
                  <Col sm="8">
                     <div className="text-sm-end">
                        <Button
                           type="button"
                           color="success"
                           className="btn-rounded mb-2 me-2"
                           onClick={()=>goModalAdd(false, null)}
                        >
                           <i className="mdi mdi-plus me-1"></i>
                           Adicionar Categoria
                        </Button>
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col xs="12">
                     <Card>
                        <CardBody>
                           {categories && categories.length > 0 && <TableComponent EcommerceCustomerColumns={EcommerceCustomerColumns} pageOptions={pageOptions} data={categories} toggle={toggle} defaultSorted={defaultSorted} handleTableChange={handleTableChange} />}
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </div>
      </React.Fragment>
      <ModalAdd modal={modalAdd} toggle={toggle} isEdit={isEdit} categoryItem={categoryItem} />
      </>
   )
}

Categories.propTypes = {
   categories: PropTypes.array,
   total: PropTypes.number,
   onGetCategories: PropTypes.func,
 
}

export default Categories
