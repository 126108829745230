import { 
    GET_STRETCHING, 
    GET_STRETCHING_FAIL, 
    GET_STRETCHING_SUCCESS,
    ADD_NEW_STRETCHING,
    ADD_STRETCHING_SUCCESS,
    ADD_STRETCHING_FAIL,
    UPDATE_STRETCHING,
    UPDATE_STRETCHING_SUCCESS,
    UPDATE_STRETCHING_FAIL,
    DELETE_STRETCHING,
    DELETE_STRETCHING_FAIL,
    DELETE_STRETCHING_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
   stretching: [],
  error: {},
}

const stretching = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STRETCHING:
         return {
            ...state,
            stretching: action.payload,
         }

      case GET_STRETCHING_SUCCESS:
         return {
            ...state,
            stretching: action.payload,
         }

      case GET_STRETCHING_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case ADD_NEW_STRETCHING:
         return {
            ...state,
            stretching: [...state.stretching, action.payload],
         }

      case ADD_STRETCHING_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case UPDATE_STRETCHING:
         return {
            ...state,
            stretching: state.stretching.map(streching =>
               streching.id.toString() === action.payload.id.toString()
                  ? { stretching, ...action.payload }
                  : streching
            ),
         }

      case UPDATE_STRETCHING_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case DELETE_STRETCHING_SUCCESS:
         return {
            ...state,
            stretching: state.stretching.filter(
               stretching => stretching.id.toString() !== action.payload.id.toString()
            ),
         }

      case DELETE_STRETCHING_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      default:
         return state
  }
}

export default stretching;
