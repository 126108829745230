import { 
    GET_CATEGORIES_WORKOUTS, 
    GET_CATEGORIES_WORKOUTS_FAIL, 
    GET_CATEGORIES_WORKOUTS_SUCCESS,
    ADD_NEW_CATEGORIES_WORKOUTS,
    ADD_CATEGORIES_WORKOUTS_SUCCESS,
    ADD_CATEGORIES_WORKOUTS_FAIL,
    UPDATE_CATEGORIES_WORKOUTS,
    UPDATE_CATEGORIES_WORKOUTS_SUCCESS,
    UPDATE_CATEGORIES_WORKOUTS_FAIL,
    DELETE_CATEGORIES_WORKOUTS,
    DELETE_CATEGORIES_WORKOUTS_FAIL,
    DELETE_CATEGORIES_WORKOUTS_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
   categoriesWorkouts: [],
  error: {},
}

const categoriesWorkouts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_WORKOUTS:
         return {
            ...state,
            categoriesWorkouts: action.payload,
         }

      case GET_CATEGORIES_WORKOUTS_SUCCESS:
         return {
            ...state,
            categoriesWorkouts: action.payload,
         }

      case GET_CATEGORIES_WORKOUTS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case ADD_NEW_CATEGORIES_WORKOUTS:
         return {
            ...state,
            categoriesWorkouts: [...state.categoriesWorkouts, action.payload],
         }

      case ADD_CATEGORIES_WORKOUTS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case UPDATE_CATEGORIES_WORKOUTS:
         return {
            ...state,
            categoriesWorkouts: state.categoriesWorkouts.map(category =>
              category.id.toString() === action.payload.id.toString()
                  ? { categoriesWorkouts, ...action.payload }
                  : category
            ),
         }

      case UPDATE_CATEGORIES_WORKOUTS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case DELETE_CATEGORIES_WORKOUTS_SUCCESS:
         return {
            ...state,
            categoriesWorkouts: state.categoriesWorkouts.filter(
               customer => customer.id.toString() !== action.payload.id.toString()
            ),
         }

      case DELETE_CATEGORIES_WORKOUTS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      default:
         return state
  }
}

export default categoriesWorkouts;
