import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_RECIPES } from "./actionTypes"
import { getRecipesFail, getRecipesSuccess } from "./actions"

import {
  getRecipes,
} from '../../api/recipes';

function* fetchRecipes() {  
  try {
    const response = yield call(getRecipes)
    yield put(getRecipesSuccess(response.data.recipes))
  } catch (error) {
    yield put(getRecipesFail(error))
  }
}

function* tasksSaga() {
  yield takeEvery(GET_RECIPES, fetchRecipes)
}

export default tasksSaga