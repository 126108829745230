import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_EQUIPMENTS } from "./actionTypes"
import { getEquipmentsSuccess, getEquipmentsFail } from "./actions"

import {
  getEquipments,
} from '../../api/equipments';

function* fetchEquipments() {
  try {
    const response = yield call(getEquipments)    
    yield put(getEquipmentsSuccess(response.data.equipaments))
  } catch (error) {
    yield put(getEquipmentsFail(error))
  }
}

function* tasksSaga() {
  yield takeEvery(GET_EQUIPMENTS, fetchEquipments)
}

export default tasksSaga
