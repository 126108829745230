import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as moment from "moment"
import {
   Card,
   CardBody,
   Col,
   Container,
   Row,
   Button,
   DropdownItem,
   DropdownMenu,
   DropdownToggle,
   UncontrolledDropdown,
} from "reactstrap"

//Import Breadcrumb
import {
   getWorkouts as onGetWorkouts,
   getWorkoutsSearch as onGetWorkoutsSearch,
   getTotalWorkouts as onGetTotalWorkouts,
} from "store/workouts/actions"

import {
   getTrainers as onGetTrainers,
} from "store/trainers/actions"

import {
   getModalities as onGetModalities,
} from "store/modalities/actions"

import {
   getCategoriesWorkouts as onGetCategoriesWorkouts,
} from "store/categoriesWorkouts/actions"

import {
   getCategories as onGetCategories,
} from "store/categories/actions"

import {
   getWorkoutsLevels as onGetWorkoutsLevels,
} from "store/workoutsLevels/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableComponent from "./_components/TableComponent"
import ModalAdd from "./_components/ModalAdd"
import ModalFiltro from "./_components/ModalFiltro"
import { cloneWorkoutApi, deleteWorkouts } from "api/workouts"
import { getStringShortcodeLocale } from "utils"
import { getWorkoutsDurationApi } from "api/workoutsDuration"

const Workouts = props => {
   const dispatch = useDispatch()

   const { workouts } = useSelector(state => ({
      workouts: state.workouts.workouts,
   })); 
   
   const { workoutsSearch } = useSelector(state => ({
      workoutsSearch: state.workouts.workoutsSearch,
   })); 

   const { total } = useSelector(state => ({
      total: state.workouts.total,
   }))   

   const [, setCustomerList] = useState([]);
   const [isEdit, setIsEdit] = useState(false);
   const [workoutsItem, setWorkoutsItem] = useState();  
   const [modalAdd, setModalAdd] = useState(false);   
   const [modalFilter, setModalFilter] = useState(false);
   const [workoutsDuration, setWorkoutsDuration] = useState(false);  
   const [totalWorkouts, setTotalWorkouts] = useState(0); 

   useEffect(()=>{
      const fetchWorkoutsDuration = async() => {
         const durations  = await getWorkoutsDurationApi();
         const arrDurations = durations.data.durations.map(item=> {
            return {
               label: item.name,
               value: item._id
            }
         })
         setWorkoutsDuration(arrDurations);
      }

      fetchWorkoutsDuration();
   },[]);

   //pagination customization
   const pageOptions = {
      sizePerPage: 10,
      totalSize: workouts && workouts.length, // replace later with size(orders),
      custom: true,
   }

  function goModalAdd(isEdit, item) {
      if(isEdit) {
         setWorkoutsItem(item);
      }
      setIsEdit(isEdit);
      toggle();
   }   

   const handleDeleteWorkouts = async (workouts) => {
      if (confirm("Tem certeza que deseja excluir?") == true) {
         await deleteWorkouts({_id: workouts._id});
         dispatch(onGetWorkouts());
      }
   }
   const formatName = (str) => {
      if(str.indexOf('[pt]') > -1) {
         return getStringShortcodeLocale(str, "pt");
      }

      return str;
   }

   const EcommerceCustomerColumns = [
      {
         text: "id",
         dataField: "id",
         sort: true,
         hidden: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => <>{row.id}</>,
      },
      {
         text: "Nome",
         dataField: "name",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => (
            <a style={{color:'inherit'}} onClick={()=>goModalAdd(true, row)}>
               <p className="mb-0">{formatName(row.name)}</p>
            </a>
         ),
      },
      {
         text: "Nível",
         dataField: "workoutlevels_workouts[0].name",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => {
            return (
            <a style={{color:'inherit'}} onClick={()=>goModalAdd(true, row)}>
               <p className="mb-0">{(row.workoutlevels_workouts[0]?.name || '')}</p>
            </a>)
         },
      },
      {
         text: "Duração ",
         dataField: "duration",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => (
            <a style={{color:'inherit'}} onClick={()=>goModalAdd(true, row)}>
               <p className="mb-0">{(row.workoutduration_workouts?.name)}</p>
            </a>
         ),
      },
      {
         dataField: "created_at",
         text: "Cadastro",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => <a style={{color:'inherit'}} onClick={()=>goModalAdd(true, row)}>{handleValidDate(row.created_at)}</a>,
      },
      
      {
         text: "created_at_dt",
         dataField: "created_at",
         sort: true,
         hidden: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => <>{row.created_at}</>,
      }, 
      {
         text: "Publicado ",
         dataField: "is_published",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => (
            <a style={{color:'inherit'}} onClick={()=>goModalAdd(true, row)}>
               <p className="mb-0">{(row?.is_published ? 'Sim':'Não')}</p>
            </a>
         ),
      },  

      {
         dataField: "menu",
         isDummyField: true,
         text: "Ações",
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, item) => (
            <UncontrolledDropdown direction="left">
               <DropdownToggle href="#" className="card-drop" tag="i">
                  <i className="mdi mdi-dots-horizontal font-size-18" />
               </DropdownToggle>
               <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                     onClick={()=>goModalAdd(true, item)}
                  >
                     <i className="fas fa-pencil-alt text-success me-1" />
                     Editar
                  </DropdownItem>
                  <DropdownItem
                     href="#"
                     onClick={() => handleDeleteWorkouts(item)}
                  >
                     <i className="fas fa-trash-alt text-danger me-1" />
                     Excluir
                  </DropdownItem>
                  <DropdownItem                     
                     href={`/workouts/${item._id}/dia`}>
                     <i className="fas fa-plus text-info me-1" />
                      Dia
                  </DropdownItem>  
                  <DropdownItem                     
                     href="#"
                     onClick={()=>cloneWorkout(item._id)}>
                     <i className="fas fa-clone text-info me-1" />
                     Clone Treino
                  </DropdownItem>    
                  <DropdownItem                     
                     href={`/workouts/${item._id}/ver/treino`}>
                     <i className="fas fa-eye" />
                     Ver Treino
                  </DropdownItem>               
               </DropdownMenu>
            </UncontrolledDropdown>
         ),
      },
   ];

   async function cloneWorkout(_id) {
      await cloneWorkoutApi(_id);
      dispatch(onGetWorkouts());     
   }
   const toggleFilter = () => {
      setModalFilter(!modalFilter)
   }
     
   const toggle = () => {
      setModalAdd(!modalAdd)               
   }    

   useEffect(() => {
      if (workouts && !workouts.length) {         
         dispatch(onGetWorkouts())
         dispatch(onGetTrainers())
         dispatch(onGetModalities())
         dispatch(onGetCategoriesWorkouts()) 
         dispatch(onGetCategories())         
         dispatch(onGetWorkoutsLevels())  
         dispatch(onGetTotalWorkouts())        
      }
   }, [dispatch, workouts])

   useEffect(() => {      
      // console.log('workoutsSearch', workoutsSearch);
   }, [workoutsSearch])

   useEffect(() => {
      setCustomerList(workouts)
   }, [workouts])

   useEffect(() => {
      if (!isEmpty(workouts)) {
         setCustomerList(workouts)
      }
   }, [workouts])   

   useEffect(() => {
      setTotalWorkouts(total)
   }, [total])

   // eslint-disable-next-line no-unused-vars
   const handleTableChange = (type, { page, searchText }) => {
      setCustomerList(
         workouts.filter(customer =>
            Object.keys(customer).some(key =>
               customer[key].toLowerCase().includes(searchText.toLowerCase())
            )
         )
      )
   }

   const handleTableChangeSearch = (type, { page, searchText }) => {
      setCustomerList(
         workoutsSearch.filter(customer =>
            Object.keys(customer).some(key =>
               customer[key].toLowerCase().includes(searchText.toLowerCase())
            )
         )
      )
   }

   

   const defaultSorted = [
      {
         dataField: "is_published",
         order: "desc",
      },
      {
         dataField: "name",
         order: "asc",
      },
   ]

   /** set Date formate */
   const handleValidDate = date => {
      const date1 = moment(new Date(date)).format("DD/MM/YYYY")
      return date1
     }   
   //   console.log(workoutsSearch, "workoutsSearch")
   return (
      <>
      <React.Fragment>
         <div className="page-content">
            <MetaTags>
               <title>Treinos | Suore</title>
            </MetaTags>
            <Container fluid>
               {/* <Breadcrumbs title="Suore" breadcrumbItem="Clientes" /> */}
               <h3>Treinos</h3>
               <Row className="mb-2">
                  <Col sm="4">                  
                  </Col>
                  <Col sm="8">
                     <div className="text-sm-end">
                        <Button
                           type="button"
                           color="success"
                           className="btn-rounded mb-2 me-2"
                           onClick={()=>goModalAdd(false, null)}
                        >
                           <i className="mdi mdi-plus me-1"></i>
                           Adicionar Treino
                        </Button>
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col xs="12">
                     <Card>
                        <CardBody>
                           {workouts && workouts.length > 0 && (!workoutsSearch || workoutsSearch.length === 0) &&
                           <TableComponent 
                           EcommerceCustomerColumns={EcommerceCustomerColumns} 
                           pageOptions={pageOptions} 
                           data={workouts} 
                           toggle={toggle}
                           toggleFilter={toggleFilter} 
                           defaultSorted={defaultSorted} 
                           handleTableChange={handleTableChange} 
                           totalCustomer={totalWorkouts}                            
                           showFilter={1}
                           showTotal={1}
                           />}
                           {workoutsSearch && workoutsSearch.length > 0 && 
                           <TableComponent 
                           EcommerceCustomerColumns={EcommerceCustomerColumns} 
                           pageOptions={pageOptions} 
                           data={workoutsSearch} 
                           // totalCustomer={totalCustomer} 
                           toggleFilter={toggleFilter} 
                           defaultSorted={defaultSorted} 
                           handleTableChange={handleTableChange} 
                           filtered={1} showTotal={1} showFilter={1} />}                           
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </div>
      </React.Fragment>
      <ModalAdd modal={modalAdd} toggle={toggle} isEdit={isEdit} workoutsItem={workoutsItem} workoutsDuration={workoutsDuration} /> 
      <ModalFiltro modalFilter={modalFilter} toggleFilter={toggleFilter} workoutsItem={workoutsItem} />
       </>
   )
}

Workouts.propTypes = {
   workouts: PropTypes.array,
   workoutsSearch: PropTypes.array,
   total: PropTypes.number,
   onGetWorkouts: PropTypes.func,  
   onGetWorkoutsSearch: PropTypes.func,
}

export default Workouts;