import { getTrainings } from "api/trainings";
import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
} from "reactstrap";
import AutoComplete from "../AutoComplete";
import PropTypes from "prop-types";

function ProcurarExercicios({ onHandleTrainingAutoComplete }) {

    const [open, setOpen] = useState(true);
    const [trainingsData, setTrainingsData] = useState([]);

    useEffect(() => {
        fetchTrainings();
    }, []);

    async function fetchTrainings() {
        const trainings = await getTrainings();
        // console.log('trainings.data.trainings', trainings.data.trainings);
        setTrainingsData(trainings.data.trainings);
    }

    function handleCloseBoxComplete() {
        setOpen(!open);
    }

    return (
        <Row className="mb-2 mt-5">
            <div className="panel panel-flat">
                <div className="panel-body">
                    <div className="d-flex justify-content-between align-items-center px-4 py-4" style={{ backgroundColor: '#2a3042' }} onClick={() => handleCloseBoxComplete()}>
                        <h5 className="panel-title" style={{ marginBottom: 0 }}>Procurar Exercício</h5>
                        <i className={`fas ${open ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                    </div>
                    <div className={`card border-primary ${!open ? 'collapse' : ''}`}>
                        <div className="card-body">
                            <div className="form-group mb-2">
                                <label>Digite as 3 primeireas letras do nome ou tags para achar o exercício</label>
                                {trainingsData && <AutoComplete trainings={trainingsData} onHandleTrainingAutoComplete={onHandleTrainingAutoComplete} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Row>
    );
}

ProcurarExercicios.propTypes = {
    onHandleTrainingAutoComplete: PropTypes.func,
}

export default ProcurarExercicios;