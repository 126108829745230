import { 
   GET_WORKOUTSDAYS,
   GET_WORKOUTSDAYS_FAIL,
   GET_WORKOUTSDAYS_SUCCESS,
   ADD_NEW_WORKOUTSDAYS,
   ADD_WORKOUTSDAYS_SUCCESS,
   ADD_WORKOUTSDAYS_FAIL,
   UPDATE_WORKOUTSDAYS,
   UPDATE_WORKOUTSDAYS_SUCCESS,
   UPDATE_WORKOUTSDAYS_FAIL,
   DELETE_WORKOUTSDAYS,
   DELETE_WORKOUTSDAYS_SUCCESS,
   DELETE_WORKOUTSDAYS_FAIL,
 } from "./actionTypes"
 
 export const getWorkoutsDays = workoutDays => {  
     const body = {
      type: GET_WORKOUTSDAYS,    
     } 
     if(workoutDays) body.payload = workoutDays;
     return body    
  }  
 
 
 export const getWorkoutsDaysSuccess = tasks => ({
   type: GET_WORKOUTSDAYS_SUCCESS,
   payload: tasks,
 })
 
 export const getWorkoutsDaysFail = error => ({
   type: GET_WORKOUTSDAYS_FAIL,
   payload: error,
 })
 
 export const addNewWorkoutsDays = workoutDays => ({
   type: ADD_NEW_WORKOUTSDAYS,
   payload: workoutDays,
 })
 
 export const addWorkoutsDaysSuccess = workoutDays => ({
   type: ADD_WORKOUTSDAYS_SUCCESS,
   payload: workoutDays,
 })
 
 export const addWorkoutsDaysFail = error => ({
   type: ADD_WORKOUTSDAYS_FAIL,
   payload: error,
 })
 
 export const updateWorkoutsDays = workoutDays => ({
   type: UPDATE_WORKOUTSDAYS,
   payload: workoutDays,
 })
 
 export const updateWorkoutsDaysSuccess = workoutDays => ({
   type: UPDATE_WORKOUTSDAYS_SUCCESS,
   payload: workoutDays,
 })
 
 export const updateWorkoutsDaysFail = error => ({
   type: UPDATE_WORKOUTSDAYS_FAIL,
   payload: error,
 })
 
 export const deleteWorkoutsDays = workoutDays => ({
   type: DELETE_WORKOUTSDAYS,
   payload: workoutDays,
 })
 
 export const deleteWorkoutsDaysSuccess = workoutDays => ({
   type: DELETE_WORKOUTSDAYS_SUCCESS,
   payload: workoutDays,
 })
 
 export const deleteWorkoutsDaysFail = error => ({
   type: DELETE_WORKOUTSDAYS_FAIL,
   payload: error,
 })