import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import categoriesWorkoutsSaga from "./categoriesWorkouts/saga"
import categoriesSaga from "./categories/saga"
import trainersSaga from "./trainers/saga";
import trainingsSaga from "./trainings/saga";
import modalitiesSaga from "./modalities/saga";
import dashboardSaga from "./dashboard/saga";
import usersSaga from "./users/saga";
import recipesSaga from "./recipes/saga";
import workoutsSaga from "./workouts/saga";
import workoutsDaysSaga from "./workoutsDays/saga";
import workoutsLevelsSaga from "./workoutsLevels/saga"
import equipmentsSaga from "./equipments/saga"
import stretchingSaga from "./stretching/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(trainersSaga),
    fork(dashboardSaga),
    fork(modalitiesSaga),
    fork(categoriesWorkoutsSaga),
    fork(usersSaga),
    fork(LayoutSaga),
    fork(trainingsSaga),
    fork(categoriesSaga),
    fork(recipesSaga),    
    fork(workoutsSaga),
    fork(workoutsDaysSaga),
    fork(workoutsLevelsSaga),
    fork(equipmentsSaga),
    fork(stretchingSaga),
  ])
}
