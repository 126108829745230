import { 
  GET_MODALITIES, 
  GET_MODALITIES_SUCCESS, 
  GET_MODALITIES_FAIL, 
  ADD_NEW_MODALITIES, 
  ADD_MODALITIES_FAIL, 
  ADD_MODALITIES_SUCCESS, 
  UPDATE_MODALITIES, 
  UPDATE_MODALITIES_FAIL, 
  UPDATE_MODALITIES_SUCCESS, 
  DELETE_MODALITIES, 
  DELETE_MODALITIES_SUCCESS, 
  DELETE_MODALITIES_FAIL 
} from "./actionTypes"

export const getModalities = () => ({
  type: GET_MODALITIES,
})

export const getModalitiesSuccess = tasks => ({
  type: GET_MODALITIES_SUCCESS,
  payload: tasks,
})

export const getModalitiesFail = error => ({
  type: GET_MODALITIES_FAIL,
  payload: error,
})

export const addNewModalities = category => ({
  type: ADD_NEW_MODALITIES,
  payload: category,
})

export const addModalitiesSuccess = category => ({
  type: ADD_MODALITIES_SUCCESS,
  payload: category,
})

export const addModalitiesFail = error => ({
  type: ADD_MODALITIES_FAIL,
  payload: error,
})

export const updateModalities = category => ({
  type: UPDATE_MODALITIES,
  payload: category,
})

export const updateModalitiesSuccess = category => ({
  type: UPDATE_MODALITIES_SUCCESS,
  payload: category,
})

export const updateModalitiesFail = error => ({
  type: UPDATE_MODALITIES_FAIL,
  payload: error,
})

export const deleteModalities = category => ({
  type: DELETE_MODALITIES,
  payload: category,
})

export const deleteModalitiesSuccess = category => ({
  type: DELETE_MODALITIES_SUCCESS,
  payload: category,
})

export const deleteModalitiesFail = error => ({
  type: DELETE_MODALITIES_FAIL,
  payload: error,
})
