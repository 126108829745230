import PropTypes from 'prop-types'
import React, { useState } from "react"

import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import { Link } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import RightSidebar from "../CommonForBoth/RightSidebar"
import megamenuImg from "../../assets/images/megamenu-img.png"

// import images
import github from "../../assets/images/brands/github.png"
import bitbucket from "../../assets/images/brands/bitbucket.png"
import dribbble from "../../assets/images/brands/dribbble.png"
import dropbox from "../../assets/images/brands/dropbox.png"
import mail_chimp from "../../assets/images/brands/mail_chimp.png"
import slack from "../../assets/images/brands/slack.png"

import logo from "../../assets/images/logo.svg"
import logoLightSvg from "../../assets/images/logo-light.svg"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
   showRightSidebarAction,
   toggleLeftmenu,
   changeSidebarType,
} from "../../store/actions"

const Header = props => {
   const [search, setsearch] = useState(false)
   const [megaMenu, setmegaMenu] = useState(false)
   const [socialDrp, setsocialDrp] = useState(false)

   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

   const [position, setPosition] = useState();
   const [open, setOpen] = useState(false);

   const toggleTopDrawer = () => {
      setPosition('right');
      setOpen(!open)
   }

   const onDrawerClose = () => {
      setOpen(false);
   }

   function toggleFullscreen() {
      if (
         !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
         !document.webkitFullscreenElement
      ) {
         // current working methods
         if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen()
         } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen()
         } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(
               Element.ALLOW_KEYBOARD_INPUT
            )
         }
      } else {
         if (document.cancelFullScreen) {
            document.cancelFullScreen()
         } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
         } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen()
         }
      }
   }

   function tToggle() {
      var body = document.body;
      if (window.screen.width <= 998) {
         body.classList.toggle("sidebar-enable");
      } else {
         body.classList.toggle("vertical-collpsed");
         body.classList.toggle("sidebar-enable");
      }
   }

   return (
      <React.Fragment>
         <header id="page-topbar">
            <div className="navbar-header">

               <div className="d-flex">
                  <div className="navbar-brand-box d-lg-none d-md-block">
                     <Link to="/" className="logo logo-dark">
                        <span className="logo-sm">
                           {/* <img src={logo} alt="" height="22" /> */}
                           SUORE
                        </span>
                     </Link>

                     <Link to="/" className="logo logo-light">
                        <span className="logo-sm">
                           {/* <img src={logoLightSvg} alt="" height="22" /> */}
                           SUORE
                        </span>
                     </Link>
                  </div>

                  <button
                     type="button"
                     onClick={() => {
                        tToggle()
                      }}
                     className="btn btn-sm px-3 font-size-16 header-item"
                     id="vertical-menu-btn"
                  >
                     <i className="fa fa-fw fa-bars"></i>
                  </button>
               </div>
               <div className="d-flex">
                  <div className="dropdown d-inline-block d-lg-none ms-2">
                     <button
                        onClick={() => {
                           setsearch(!search)
                         }}
                        type="button"
                        className="btn header-item noti-icon"
                        id="page-header-search-dropdown"
                     >
                        <i className="mdi mdi-magnify"></i>
                     </button>
                     <div
                        className={
                           search
                              ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                              : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                        }
                        aria-labelledby="page-header-search-dropdown"
                     >
                        <form className="p-3">
                           <div className="form-group m-0">
                              <div className="input-group">
                                 <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search ..."
                                    aria-label="Recipient's username"
                                 />
                                 <div className="input-group-append">
                                    <button className="btn btn-primary" type="submit">
                                       <i className="mdi mdi-magnify"></i>
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>

                  <div className="dropdown d-none d-lg-inline-block ms-1">
                     <button
                        type="button"
                        onClick={toggleFullscreen}
                        className="btn header-item noti-icon"
                        data-toggle="fullscreen"
                     >
                        <i className="bx bx-fullscreen"></i>
                     </button>
                  </div>

                  {/* <NotificationDropdown /> */}
                  <ProfileMenu />
               </div>
            </div>
         </header>
      </React.Fragment>
   )
}

Header.propTypes = {
   changeSidebarType: PropTypes.func,
   leftMenu: PropTypes.any,
   leftSideBarType: PropTypes.any,
   showRightSidebar: PropTypes.any,
   showRightSidebarAction: PropTypes.func,
   t: PropTypes.any,
   toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
   const {
      layoutType,
      showRightSidebar,
      leftMenu,
      leftSideBarType,
   } = state.Layout
   return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
   showRightSidebarAction,
   toggleLeftmenu,
   changeSidebarType,
})(withTranslation()(Header))
