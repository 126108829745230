/* TRAININGS */
export const GET_TRAININGS = "GET_TRAININGS"
export const GET_TRAININGS_SUCCESS = "GET_TRAININGS_SUCCESS"
export const GET_TRAININGS_FAIL = "GET_TRAININGS_FAIL"

/**
 * add TRAINING
 */
export const ADD_NEW_TRAINING = "ADD_NEW_TRAINING"
export const ADD_TRAINING_SUCCESS = "ADD_TRAINING_SUCCESS"
export const ADD_TRAINING_FAIL = "ADD_TRAINING_FAIL"

/**
 * Edit TRAINING
 */
export const UPDATE_TRAINING = "UPDATE_TRAINING"
export const UPDATE_TRAINING_SUCCESS = "UPDATE_TRAINING_SUCCESS"
export const UPDATE_TRAINING_FAIL = "UPDATE_TRAINING_FAIL"

/**
 * Delete TRAINING
 */
export const DELETE_TRAINING = "DELETE_TRAINING"
export const DELETE_TRAINING_SUCCESS = "DELETE_TRAINING_SUCCESS"
export const DELETE_TRAINING_FAIL = "DELETE_TRAINING_FAIL"