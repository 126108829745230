import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
   GET_CUSTOMERS,
   GET_TOTAL_CUSTOMERS,
   GET_CUSTOMER_DETAIL,  
   GET_CUSTOMERS_SEARCH,
   GET_CUSTOMER_SUBSCRIBES_ADD,
   GET_CUSTOMER_SUBSCRIBES_EDIT,
   DELETE_CUSTOMER,
} from "./actionTypes"
import {
   getCustomersFail,
   getCustomersSuccess,
   getTotalCustomersSuccess,
   getTotalCustomersFail,
   geCustomerDetailSuccess,
   geCustomerDetailFail,
   getCustomerPropertiesSuccess,
   getCustomerSheduleSuccess,
   getCustomerMediasSuccess,
   getCustomerTokensSuccess,
   geCustomerSubscribeSuccess,
   geCustomersSearchSuccess,
   geCustomersSearchFail,
   addCustomersSubscribeSuccess,
   addCustomersSubscribeFail,
   editCustomersSubscribeSuccess,
   editCustomersSubscribeFail,
   deleteCustomerSuccess,
   deleteCustomerFail
} from "./actions"

import {
   addUserSubscribe,
   deleteUserApi,
   editUserSubscribe,
   getAllUsers,
   getTotalUsers,
   getUsersDetail,
   getUsersSearch
} from '../../api/users';

function* fetchCustomers() {
   try {
      const response = yield call(getAllUsers);
      yield put(getCustomersSuccess(response.data.users));
   } catch (error) {
      yield put(getCustomersFail(error));
   }
}

function* fetchTotalCustomers() {
   try {
      const response = yield call(getTotalUsers);
      yield put(getTotalCustomersSuccess(response.data.total));
   } catch (error) {
      yield put(getTotalCustomersFail(error));
   }
}

function* fetchCustomerDetail({ idCliente }) {
   console.log('idCliente', idCliente);
   try {
      const response = yield call(getUsersDetail, idCliente);
      console.log('response', response);
      yield put(geCustomerDetailSuccess(response.data.user));
      yield put(getCustomerPropertiesSuccess(response.data.properties));
      yield put(geCustomerSubscribeSuccess(response.data.orders));
      yield put(getCustomerSheduleSuccess(response.data.schedules));
      yield put(getCustomerMediasSuccess(response.data.user.medias));
      yield put(getCustomerTokensSuccess(response.data.user.tokens));
   } catch (error) {
      yield put(geCustomerDetailFail(error));
   }
}

function* fetchCustomerSearch({ search }) {
   try {
      const response = yield call(getUsersSearch, search);
      yield put(geCustomersSearchSuccess(response.data.users));
   } catch (error) {
      yield put(geCustomersSearchFail(error));
   }
}

function* addCustomerSubscribe({ objToSave }) {
   try {
      const response = yield call(addUserSubscribe, objToSave);
      yield put(addCustomersSubscribeSuccess(response.data.orderSaved));
   } catch (error) {
      yield put(addCustomersSubscribeFail(error));
   }
}

function* editCustomerSubscribe({ objToSave }) {
   try {
      const response = yield call(editUserSubscribe, objToSave);
      yield put(editCustomersSubscribeSuccess(response.data.orderUpdated));
   } catch (error) {
      yield put(editCustomersSubscribeFail(error));
   }
}

function* deleteCustomer(customer) {
   try {
      const response = yield call(deleteUserApi, {_id: customer.payload._id});
      yield put(deleteCustomerSuccess(response.data));
   } catch (error) {
      yield put(deleteCustomerFail(error));
   }
}

function* usersSaga() {
   yield takeEvery(GET_TOTAL_CUSTOMERS, fetchTotalCustomers);
   yield takeEvery(GET_CUSTOMERS, fetchCustomers);
   yield takeEvery(GET_CUSTOMER_DETAIL, fetchCustomerDetail);
   yield takeEvery(GET_CUSTOMERS_SEARCH, fetchCustomerSearch);
   yield takeEvery(GET_CUSTOMER_SUBSCRIBES_ADD, addCustomerSubscribe);
   yield takeEvery(GET_CUSTOMER_SUBSCRIBES_EDIT, editCustomerSubscribe);
   yield takeEvery(DELETE_CUSTOMER, deleteCustomer);
}

export default usersSaga
