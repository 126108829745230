/* Workouts Days */
export const GET_WORKOUTSDAYS = "GET_WORKOUTSDAYS"
export const GET_WORKOUTSDAYS_SUCCESS = "GET_WORKOUTSDAYS_SUCCESS"
export const GET_WORKOUTSDAYS_FAIL = "GET_WORKOUTSDAYS_FAIL"

/**
 * add Workouts Days
 */
export const ADD_NEW_WORKOUTSDAYS = "ADD_NEW_WORKOUTSDAYS"
export const ADD_WORKOUTSDAYS_SUCCESS = "ADD_WORKOUTSDAYS_SUCCESS"
export const ADD_WORKOUTSDAYS_FAIL = "ADD_WORKOUTSDAYS_FAIL"

/**
 * Edit Workouts Days
 */
export const UPDATE_WORKOUTSDAYS = "UPDATE_WORKOUTSDAYS"
export const UPDATE_WORKOUTSDAYS_SUCCESS = "UPDATE_WORKOUTSDAYS_SUCCESS"
export const UPDATE_WORKOUTSDAYS_FAIL = "UPDATE_WORKOUTSDAYS_FAIL"

/**
 * Delete Workouts Days
 */
export const DELETE_WORKOUTSDAYS = "DELETE_WORKOUTSDAYS"
export const DELETE_WORKOUTSDAYS_SUCCESS = "DELETE_WORKOUTSDAYS_SUCCESS"
export const DELETE_WORKOUTSDAYS_FAIL = "DELETE_WORKOUTSDAYS_FAIL"