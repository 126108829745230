import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import {
    Container,
} from "reactstrap";
import PropTypes from "prop-types";
import { findOne } from "api/workoutsDays";
import { getStringShortcodeLocale } from "utils";
import { getTrainingsApi } from "api/trainings";
import RenderSettings from "./_components/_renderSettings";
import Breadcrumb from "components/Common/Breadcrumb2";

const VerTreino = (props) => {
    const idworkouts = props.match.params.id;

    const [dayActive, setDayActive] = useState(0);
    const [workoutData, setWorkoutData] = useState(null);
    const [workoutDaysData, setWorkoutDaysData] = useState(null);
    const [closedContainers, setClosedContainers] = useState([]);
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {
            title: 'Treinos',
            link: '/treinos',
        },
        {
            title: 'Ver Treino',
            link: '#',
        },
    ]);

    useEffect(() => {
        const fetchSettings = async () => {
            const workoutData = await findOne(idworkouts);
            console.log('workoutData', workoutData);
            if (workoutData.data.days) {
                setWorkoutData(workoutData.data.days);
                // const arrSemanas = [];
                // for (let index = 0; index < workoutData.data.days.length; index++) {
                //     const day = workoutData.data.days[index];
                //     const weekDay = parseInt(day.weekDay) - 1;
                //     if (!arrSemanas[weekDay]) {
                //         arrSemanas[weekDay] = [];
                //     }
                //     arrSemanas[weekDay].push(day);
                // }

                // console.log('arrSemanas', arrSemanas);
                // setWorkoutDaysData(arrSemanas);
            }

        }

        fetchSettings();
    }, []);

    useEffect(() => {
        dayActive && dayActive > 0 && renderDay();
    }, [dayActive]);

    function handleCollapse(index) {
        const newArrClosed = [...closedContainers];
        if (newArrClosed.includes(index)) {
            newArrClosed.splice(index, 1);
        } else {
            newArrClosed.push(index);
        }
        setClosedContainers(newArrClosed);
    }

    function renderDay() {
        const arrReturn = [];
        const day = workoutData[dayActive];
        arrReturn.push(
            <div key={`dia-${day._id}`} className="container-dia">
                <div className="header-dia mt-4 cursor-pointer d-flex justify-content-between align-content-center" onClick={() => handleCollapse(index)}>
                    <div>
                        <h3>Semana {day.weekDay} - {day.name} - <a href={`/workouts/${day._id}/config`} target="_blank" rel="noreferrer">Editar Setup</a></h3>
                        <h6>
                            {day.categories && day.categories.map((item, index) => {
                                if (item) {
                                    const name = `${index > 0 ? ' | ' : ''} ${item.name}`;
                                    return name;
                                }
                            })}
                        </h6>
                    </div>
                </div>
                <div id="containerExercicios">
                    <RenderSettings item={day} />
                </div>
            </div>
        );

        return arrReturn;
    }

    function tabsDay() {
        const arrDaysIncluded = [];
        const arrDaysReturn = [];
        for (let index = 0; index < workoutData.length; index++) {
            const item = workoutData[index];
            if (!arrDaysIncluded.includes(item.idworkoutdays)) {
                arrDaysReturn.push(
                    <li key={`day-${item._id}`}><a href='#' onClick={()=>setDayActive(index)} className={`d-block btn-default ${dayActive === index ? 'btn-light' : 'btn-dark'} px-4 py-2`}>Sem {item.weekDay} - {item.name}</a></li>
                );
                arrDaysIncluded.push(item.idworkoutdays);
            }
        }
        return arrDaysReturn;
    }

    return <React.Fragment>
        <div className="page-content">
            <MetaTags>
                <title>Ver Treino | Suore</title>
            </MetaTags>
            <Container fluid>
                {workoutData && <Breadcrumb title={`${getStringShortcodeLocale(workoutData[0].workouts_workoutDay.name, 'pt')} - Ver Treino`} breadcrumbItems={breadcrumbItems} />}

                <ul className="d-flex flex-wrap" style={{ listStyle: 'none' }}>
                    {workoutData && tabsDay()}
                </ul>

                <div className="mt-4">
                    {workoutData && renderDay()}
                </div>

            </Container>
        </div>
    </React.Fragment>;
}

VerTreino.propTypes = {
    match: PropTypes.object,
}


export default VerTreino;