import React, { useEffect, useState } from "react";
import {
   Modal,
   ModalHeader,
   ModalBody,
} from "reactstrap";
import PropTypes from "prop-types";
import FormGeneralInfo from "./_formGeneralInfo";
import FormCategorias from "./_formCategorias";


function ModalAdd({ modal, toggle, isEdit, recipesItem }) {
   const [tabActive, setTabActive] = useState(0);   

   useEffect(()=>{
      setTabActive(0);
   }, [isEdit, recipesItem]);

   return (
      <Modal isOpen={modal} toggle={toggle}>
         <ModalHeader toggle={toggle} tag="h4">
            {isEdit ? 'Editar' : 'Adicionar'} Receita
         </ModalHeader>
         {isEdit && <div className="d-flex w-100 mt-4">
            <div className="btn-group mb-3 mx-auto">
               <button type="button" onClick={()=>setTabActive(0)}
                  className={`btn btn-outline-primary w-sm ${tabActive === 0 ? 'active':''}`}>
                  Informações Gerais
               </button>               
               <button type="button" onClick={()=>setTabActive(1)}
                  className={`btn btn-outline-primary w-sm ${tabActive === 1 ? 'active':''}`}>
                  Demais Informações
               </button>
            </div>
         </div>}
         <ModalBody>
            {tabActive === 0 && <FormGeneralInfo isEdit={isEdit} recipesItem={recipesItem} toggle={toggle} />}            
            {tabActive === 1 && <FormCategorias isEdit={isEdit} recipesItem={recipesItem} toggle={toggle} />}
         </ModalBody>
      </Modal>
   );
}

ModalAdd.propTypes = {
   modal: PropTypes.bool,
   isEdit: PropTypes.bool,
   toggle: PropTypes.func,
   recipesItem: PropTypes.object,
}


export default ModalAdd;

