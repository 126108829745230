import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_STRETCHING } from "./actionTypes"
import { getStretchingFail, getStretchingSuccess } from "./actions"

import {
  getStretching,
} from '../../api/stretching';

function* fetchStretching() {  
  try {
    const response = yield call(getStretching)
    yield put(getStretchingSuccess(response.data.stretching))
  } catch (error) {
    yield put(getStretchingFail(error))
  }
}

function* tasksSaga() {  
  yield takeEvery(GET_STRETCHING, fetchStretching)
}

export default tasksSaga