import {post, get, del} from './index';

async function getAllUsers() {
   return await get('/gerenciador/users');
}

async function getTotalUsers() {
   return await get('/gerenciador/total/users');
}

async function getUsersDetail(id) {
   return await get(`/gerenciador/user/details/${id}`);
}

async function editUsersDetail(obj) {
   return await post(`/gerenciador/user/details/edit`, obj);
}

async function userResetPassword(obj) {
   return await post(`/gerenciador/user/reset/password`, obj);
}

async function getUsersSearch(search) {
   return await post(`/gerenciador/search/user/`, search);
}

async function addUserSubscribe(obj) {
   return await post(`/gerenciador/orders/add`, obj);
}

async function editUserSubscribe(obj) {
   return await post(`/gerenciador/orders/edit`, obj);
}

async function deleteUserTokens(obj) {
   return await del(`/gerenciador/delete/user/tokens`, obj);
}

async function deleteUserApi(obj) {
   return await del(`/gerenciador/delete/user`, obj);
}

export {
   getAllUsers,
   getTotalUsers,
   getUsersDetail,
   getUsersSearch,   
   addUserSubscribe,
   editUserSubscribe,
   deleteUserTokens,
   deleteUserApi,
   editUsersDetail,
   userResetPassword
}