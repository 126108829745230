import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Label,
} from "reactstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { setupCategories } from "api/trainings";
import Loading from "components/Loading";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useSelector } from "react-redux";
import SuccessMessage from "components/SuccessMessage";

import {
    getTrainings as onGetTrainings,
 } from "store/trainings/actions"
 
import { useDispatch } from "react-redux";

const animatedComponents = makeAnimated();

const options = [
    {
        options: []
    }
];
const optionsTrainer = [
    {
        options: []
    }
];
const optionsModality = [
    {
        options: []
    }
];

const FormCategorias = ({ isEdit, trainingItem, toggle }) => {
    const dispatch = useDispatch();

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

    const categoriesSelected = trainingItem && !trainingItem.categories ? null:
        trainingItem.categories.map(item=>{
            return (
                {
                    label: item.name,
                    value: item._id
                }
            )
        })

    const trainersSelected = trainingItem && !trainingItem.trainers_workouts ? null:
    trainingItem.trainers_workouts.map(item=>{
                    return (
                        {
                            label: item.name,
                            value: item._id
                        }
                    )           
                }) 

    const modalitySelected = trainingItem && !trainingItem.modality_workouts ? null:
    trainingItem.modality_workouts.map(item=>{
                        return (
                            {
                                label: item.name,
                                value: item._id
                            }
                        )           
                    })  

    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [selectedValue, setSelectedValue] = useState(categoriesSelected ? categoriesSelected:null);
    const [trainersSelectedValue, setSelectedTrainersValue] = useState(trainersSelected ? trainersSelected:null);
    const [selectedModalityValue, setSelectedModalityValue] = useState(modalitySelected ? modalitySelected:null);

    const { categories } = useSelector(state => ({
        categories: state.categories.categories,
    }));

    const { trainers } = useSelector(state => ({
        trainers: state.trainers.trainers,
     }))

     const { modalities } = useSelector(state => ({
        modalities: state.modalities.modalities,
     }))

    useEffect(()=>{
        if(categories) {
            categories.map(item=>{
                if(item.type === 'workouts') {
                    options[0].options.push(
                        {
                            label: item.name,
                            value: item._id,
                        }
                    );
                }
            });
        }
    },[categories]);

    useEffect(()=>{
        if(trainers) {
            optionsTrainer[0].options = [];
            trainers.map(item=>{               
                optionsTrainer[0].options.push(
                        {
                            label: item.name,
                            value: item._id,
                        }
                    );
                            
            });
        }       
    },[trainers]);

    useEffect(()=>{
        if(modalities) {
            optionsModality[0].options = [];
            modalities.map(item=>{               
                optionsModality[0].options.push(
                        {
                            label: item.name,
                            value: item._id,
                        }
                    );
                            
            });
        }       
    },[modalities]); 

    const handleValidCustomerSubmit = async (data) => {
        setSuccessMessage(false);

        if (selectedValue) {
            console.log('selectedValue', selectedValue);
            setIsLoading(true);

            await setupCategories({
                _id: trainingItem._id,
                categories: selectedValue,
                trainers: trainersSelectedValue,
                modalities: selectedModalityValue,
            });

            setIsLoading(false);
            setSuccessMessage(true);
            dispatch(onGetTrainings());
        }
    }

    function handleMulti(selected) {
        setSelectedValue(selected);
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(handleValidCustomerSubmit)}
            >
                <Row form>
                    <Col className="col-12">
                        <div className="mt-3">
                            <div className="form-group">
                                <div className="mb-3 templating-select select2-container">
                                    <label htmlFor="control-label">Selecione as categorias</label>
                                    <Select
                                        value={selectedValue}
                                        isMulti={true}
                                        onChange={(event)=>handleMulti(event)}
                                        options={options}
                                        classNamePrefix="select2-selection"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                    />
                                </div>
                                <div className="mb-3 templating-select select2-container">
                                    <label htmlFor="control-label">Adicione o Treinador(a)</label>
                                    <Select
                                        value={trainersSelectedValue}
                                        isMulti={false}
                                        onChange={(selected) => setSelectedTrainersValue([selected])}
                                        options={optionsTrainer}
                                        classNamePrefix="select2-selection"
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                    />
                                </div>
                                <div className="mb-3 templating-select select2-container">
                                    <label htmlFor="control-label">Adicione a Modalidade</label>
                                    <Select
                                        value={selectedModalityValue}
                                        isMulti={false}
                                        onChange={(selected) => setSelectedModalityValue([selected])}
                                        options={optionsModality}
                                        classNamePrefix="select2-selection"
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                    />
                                </div> 
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {successMessage && <SuccessMessage />}
                        <div className="text-end">
                            {!isLoading && <button
                                type="submit"
                                className="btn btn-success save-customer"
                            >
                                Salvar
                            </button>}
                            {isLoading && <Loading />}
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    );
}

FormCategorias.propTypes = {
    toggle: PropTypes.func,
    isEdit: PropTypes.bool,
    trainingItem: PropTypes.object,
}

export default FormCategorias;