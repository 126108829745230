import { 
   GET_WORKOUTS,
   GET_WORKOUTS_FAIL,
   GET_WORKOUTS_SUCCESS,
   ADD_NEW_WORKOUTS,
   ADD_WORKOUTS_SUCCESS,
   ADD_WORKOUTS_FAIL,
   UPDATE_WORKOUTS,
   UPDATE_WORKOUTS_SUCCESS,
   UPDATE_WORKOUTS_FAIL,
   DELETE_WORKOUTS,
   DELETE_WORKOUTS_SUCCESS,
   DELETE_WORKOUTS_FAIL,
   GET_WORKOUTS_SEARCH,
   GET_WORKOUTS_SEARCH_SUCCESS,
   GET_WORKOUTS_SEARCH_FAIL,
   GET_WORKOUTS_SEARCH_CLEAN,
   GET_TOTAL_WORKOUTS,
   GET_TOTAL_WORKOUTS_SUCCESS,
   GET_TOTAL_WORKOUTS_FAIL,   
 } from "./actionTypes"

 export const getTotalWorkouts = () => ({
  type: GET_TOTAL_WORKOUTS
})

export const getTotalWorkoutsSuccess = workouts => ({
  type: GET_TOTAL_WORKOUTS_SUCCESS,
  payload: workouts,
})

export const getTotalWorkoutsFail = error => ({
  type: GET_TOTAL_WORKOUTS_FAIL,
  payload: error,
})
 
 export const getWorkouts = () => ({
   type: GET_WORKOUTS,
 })
 
 export const getWorkoutsSuccess = tasks => ({
   type: GET_WORKOUTS_SUCCESS,
   payload: tasks,
 })
 
 export const getWorkoutsFail = error => ({
   type: GET_WORKOUTS_FAIL,
   payload: error,
 })
 
 export const addNewWorkouts = workouts => ({
   type: ADD_NEW_WORKOUTS,
   payload: workouts,
 })
 
 export const addWorkoutsSuccess = workouts => ({
   type: ADD_WORKOUTS_SUCCESS,
   payload: workouts,
 })
 
 export const addWorkoutsFail = error => ({
   type: ADD_WORKOUTS_FAIL,
   payload: error,
 })
 
 export const updateWorkouts = workouts => ({
   type: UPDATE_WORKOUTS,
   payload: workouts,
 })
 
 export const updateWorkoutsSuccess = workouts => ({
   type: UPDATE_WORKOUTS_SUCCESS,
   payload: workouts,
 })
 
 export const updateWorkoutsFail = error => ({
   type: UPDATE_WORKOUTS_FAIL,
   payload: error,
 })
 
 export const deleteWorkouts = workouts => ({
   type: DELETE_WORKOUTS,
   payload: workouts,
 })
 
 export const deleteWorkoutsSuccess = workouts => ({
   type: DELETE_WORKOUTS_SUCCESS,
   payload: workouts,
 })
 
 export const deleteWorkoutsFail = error => ({
   type: DELETE_WORKOUTS_FAIL,
   payload: error,
 })

 export const getWorkoutsSearch = search => ({
  type: GET_WORKOUTS_SEARCH,
  search
})

export const getWorkoutsSearchSuccess = workouts => ({
  type: GET_WORKOUTS_SEARCH_SUCCESS,
  payload: workouts,
})

export const getWorkoutsSearchFail = error => ({
  type: GET_WORKOUTS_SEARCH_FAIL,
  payload: error,
})

export const getWorkoutsSearchClean = () => ({
  type: GET_WORKOUTS_SEARCH_CLEAN
})