import {post, get, del} from './index';

async function getWorkoutsDays() {   
   return await get('/gerenciador/workouts/days');
}

async function addWorkoutsDays(obj) {
   return await post(`/gerenciador/workouts/days/add`, obj);
}

async function addWorkoutsDaysSetup(obj) {
   return await post(`/gerenciador/workouts/days/addsetup`, obj);
}

async function editWorkoutsDays(obj) {
   return await post(`/gerenciador/workouts/days/edit`, obj);
}

async function deleteWorkoutsDays(obj) {
   return await del(`/gerenciador/workouts/days/delete`, obj);
}

async function findOne(id) {
   return await get(`/gerenciador/workouts/${id}/dia`);
}

async function findOneDay(id) {
   return await get(`/gerenciador/workouts/${id}/config`);
}

async function cloneWorkoutDayApi(_id) {
   return await get(`/gerenciador/workouts/days/clone/${_id}`);
}

export {
    getWorkoutsDays,
    addWorkoutsDays,
    addWorkoutsDaysSetup,    
    editWorkoutsDays,
    deleteWorkoutsDays,  
    findOne,  
    findOneDay,
    cloneWorkoutDayApi, 
}
