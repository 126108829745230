import {post, get, del} from './index';

async function getStretching() {   
   return await get("/gerenciador/stretching");
}

async function addStretching(obj) {
   return await post("/gerenciador/stretching/add", obj);
}

async function addStretchingSetup(obj) {
   return await post("/gerenciador/stretching/addsetup", obj);
}

async function editStretching(obj) {
   return await post("/gerenciador/stretching/edit", obj);
}

async function deleteStretching(obj) {
    return await del("/gerenciador/stretching/delete", obj);
}

async function editStretchingText(obj) {
return await post(`/gerenciador/stretchingtext/edit`, obj);
}

async function relationsStretching(obj) {
   return await post(`/gerenciador/stretching/relationsstretching`, obj);
}

async function findOne(id) {
   return await get(`/gerenciador/stretching/${id}/config`);
}

async function cloneStretchingApi(_id) {
   return await get(`/gerenciador/stretching/clone/${_id}`);
}

export {
    getStretching,
    addStretching,
    addStretchingSetup,
    editStretching,
    editStretchingText,
    deleteStretching,    
    relationsStretching,  
    findOne,    
    cloneStretchingApi
}




