import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as moment from "moment"
import {
   Card,
   CardBody,
   Col,
   Container,
   Row,
   Button,
   DropdownItem,
   DropdownMenu,
   DropdownToggle,
   UncontrolledDropdown,
} from "reactstrap"

import {
   getEquipments as onGetEquipments,
} from "store/equipments/actions"

import {
   getCategories as onGetCategories,
} from "store/categories/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableComponent from "./_components/TableComponent"
import ModalAdd from "./_components/ModalAdd"
import { cloneWorkoutDayApi, deleteWorkoutsDays } from "api/workoutsDays"
import { findOne } from "api/workoutsDays"
import { getStringShortcodeLocale } from "utils"

const WorkoutsDays = props => {
   const dispatch = useDispatch()
   const idworkouts = props.match.params.id;

   const [isEdit, setIsEdit] = useState(false);  
   const [workoutsDaysItem, setWorkoutsDaysItem] = useState();
   const [modalAdd, setModalAdd] = useState(false);
   const [daysData, setDaysData] = useState(null);
   const [workoutDataData, setWorkoutDataData] = useState(null);

   useEffect(() => {
      fetchDaysData();
      dispatch(onGetCategories());
      dispatch(onGetEquipments());   
   }, []);

   const fetchDaysData = async () => {
      const fetchDays = await findOne(idworkouts);
      if(!fetchDays.data.error) {
         console.log('fetchDays.data', fetchDays.data);
         setDaysData(fetchDays.data.days);
         if(fetchDays.data.days[0]) {
            setWorkoutDataData(fetchDays.data.days[0].workouts_workoutDay);
         }
      }
   }

   //pagination customization
   const pageOptions = {
      sizePerPage: 10,
      totalSize: daysData && daysData.length, // replace later with size(orders),
      custom: true,
   }

  function goModalAdd(isEdit, item) {     
      if(isEdit) {
         setWorkoutsDaysItem(item);
      }
      setIsEdit(isEdit);
      toggle();
   } 

   const handleDeleteWorkoutsDays = async (workoutsDays) => {
      if (confirm("Tem certeza que deseja excluir?") == true) {
         await deleteWorkoutsDays({_id: workoutsDays._id});
         fetchDaysData();
      }
   }

   const formatName = (str) => {
      if(str.indexOf('[pt]') > -1) {
         return getStringShortcodeLocale(str, "pt");
      }
      return str;
   }

   const EcommerceCustomerColumns = [
      {
         text: "id",
         dataField: "id",
         sort: true,
         hidden: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => <>{row.id}</>,
      },
      {
         text: "Nome",
         dataField: "name",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => (
            <a style={{color:'inherit'}} onClick={()=>goModalAdd(true, row)}>
               <p className="mb-0">{formatName(row.name)}</p>
            </a>
         ),
      },
      {
         text: "Semana",
         dataField: "weekDay",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => (
            <a style={{color:'inherit'}} onClick={()=>goModalAdd(true, row)}>
               <p className="mb-0">{row.weekDay}</p>
            </a>
         ),
      },
      {
         text: "Duração ",
         dataField: "duration",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => (
            <a style={{color:'inherit'}} onClick={()=>goModalAdd(true, row)}>
               <p className="mb-0">{(row.duration)}</p>
            </a>
         ),
      }, 
      
      {
         dataField: "created_at",
         text: "Cadastro",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => <a style={{color:'inherit'}} onClick={()=>goModalAdd(true, row)}>{handleValidDate(row.created_at)}</a>,
      },
      
      {
         text: "created_at_dt",
         dataField: "created_at",
         sort: true,
         hidden: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => <>{row.created_at}</>,
      },  

      {
         dataField: "menu",
         isDummyField: true,
         text: "Ações",
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, item) => (
            <UncontrolledDropdown direction="left">
               <DropdownToggle href="#" className="card-drop" tag="i">
                  <i className="mdi mdi-dots-horizontal font-size-18" />
               </DropdownToggle>
               <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                     onClick={()=>goModalAdd(true, item)}
                  >
                     <i className="fas fa-pencil-alt text-success me-1" />
                     Editar
                  </DropdownItem>
                  <DropdownItem
                     href="#"
                     onClick={() => handleDeleteWorkoutsDays(item)}
                  >
                     <i className="fas fa-trash-alt text-danger me-1" />
                     Excluir
                  </DropdownItem>
                  <DropdownItem
                     href={`/workouts/${item._id}/config`}            
                  >
                     <i className="fas fa-cog text-dark me-1" />
                     Setup
                  </DropdownItem>  
                  <DropdownItem                     
                     href="#"
                     onClick={()=>cloneWorkout(item._id)}>
                     <i className="fas fa-clone text-info me-1" />
                     Clone Treino
                  </DropdownItem>   
               </DropdownMenu>
            </UncontrolledDropdown>
         ),
      },
   ]

   const toggle = () => {
      setModalAdd(!modalAdd);
   }

   async function cloneWorkout(_id) {
      await cloneWorkoutDayApi(_id);
      fetchDaysData();
   }

   const defaultSorted = [
      {
         dataField: "weekDay",
         order: "asc",
      },
      {
         dataField: "name",
         order: "asc",
      },
   ]

   /** set Date formate */
   const handleValidDate = date => {
      const date1 = moment(new Date(date)).format("DD/MM/YYYY")
      return date1
     }   

   return (
      <>
      <React.Fragment>
         <div className="page-content">
            <MetaTags>
               <title>Treinos-Dia| Suore</title>
            </MetaTags>
            <Container fluid>
               {/* <Breadcrumbs title="Suore" breadcrumbItem="Clientes" /> */}
               <h3>{workoutDataData && `${getStringShortcodeLocale(workoutDataData.name, 'pt')} - `} Dias</h3>
               <Row className="mb-2">
                  <Col sm="4"></Col>
                  <Col sm="8">
                     <div className="text-sm-end">
                        <Button
                           type="button"
                           color="success"
                           className="btn-rounded mb-2 me-2"
                           onClick={()=>goModalAdd(false, null)}
                        >
                           <i className="mdi mdi-plus me-1"></i>
                           Adicionar Dia
                        </Button>
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col xs="12">
                     <Card>
                        <CardBody>
                           {console.log('daysData',daysData, daysData && daysData.length)}
                           {daysData && daysData.length > 0 && 
                           <TableComponent 
                           EcommerceCustomerColumns={EcommerceCustomerColumns} 
                           pageOptions={pageOptions} 
                           data={daysData} 
                           toggle={toggle} 
                           defaultSorted={defaultSorted} />}
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </div>
      </React.Fragment>
      <ModalAdd modal={modalAdd} toggle={toggle} isEdit={isEdit} workoutsDaysItem={workoutsDaysItem} idworkouts={idworkouts} onFetchDaysData={fetchDaysData} />     
      </>
   )
}

WorkoutsDays.propTypes = {  
   match: PropTypes.object,
   total: PropTypes.number,
   onGetWorkoutsDays: PropTypes.func,
   onAddNewCustomer: PropTypes.func,
   onDeleteCustomer: PropTypes.func,
   onUpdateCustomer: PropTypes.func,
}

export default WorkoutsDays;