import React, { useEffect, useState } from "react";
import {
    Button,
} from "reactstrap";
import PropTypes from "prop-types";
import { handleValidDate, handleValidOnlyDate, addZeroes } from "utils";
import { useDispatch } from 'react-redux';
import {
    getCustomerDetail as onGetCustomerDetail,
} from "store/users/actions";
import Loading from 'components/Loading';
import { useSelector } from "react-redux";
import { deleteUserTokens } from "api/users";

const TokensSquare = ({ customerDetail }) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const { tokens } = useSelector(state => ({
        tokens: state.users.tokens,
    }));

    useEffect(() => {
        setIsLoading(false);
    }, [tokens]);

    const handleDeleteTokens = async (token) => {
        if (confirm("Tem certeza que deseja excluir?") == true) {
            setIsLoading(true);
            await deleteUserTokens({ _id: customerDetail._id, _idToken: token._id });
            dispatch(onGetCustomerDetail(customerDetail._id));
        }
    }

    function renderTokens(objTokens) {
        const arrReturn = [];
        for (const key in objTokens) {
            if (Object.hasOwnProperty.call(objTokens, key)) {
                const item = objTokens[key];
                arrReturn.push(
                    <tr key={item._id}>
                        <td>{item.devicetype}</td>
                        <td>{item.token}</td>
                        <td>{handleValidOnlyDate(item.created_at)}</td>
                        <td>
                            <Button
                                onClick={() => handleDeleteTokens(item)}
                                type="button"
                                color="danger"
                                className="btn-rounded  mb-2 me-2">
                                Deletar
                            </Button>
                        </td>
                    </tr>
                );
            }
        }

        return arrReturn;
    }
    return <>
        {isLoading && <Loading />}
        {!tokens || Object.values(tokens).length === 0 && <p>Nenhum token até o momento</p>}
        {tokens && Object.values(tokens).length > 0 && (
            <table className="table table-nowrap align-middle mb-0">
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Tokens</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTokens(tokens)}
                </tbody>
            </table>
        )}
    </>;
}

TokensSquare.propTypes = {
    tokens: PropTypes.object,
    customerDetail: PropTypes.object,
}

export default TokensSquare;