import React from 'react';
import {
    Button,
} from "reactstrap";
import PropTypes from "prop-types";
import { handleValidDate, handleValidOnlyDate, addZeroes } from "utils";

const ImagesSquare = ({ medias, onGoModalFoto }) => {

    // console.log('medias',medias);

    function renderMedias(objMedias) {
        const arrReturn = [];
        for (const key in objMedias) {
            if (Object.hasOwnProperty.call(objMedias, key)) {
                const item = objMedias[key];
                arrReturn.push(
                    <div key={item._id} className="thumb-imgs-user">
                        <img src={item?.url} style={{ width: '80px', height: 'auto', cursor: 'pointer' }} onClick={() => onGoModalFoto(item)} />
                    </div>
                );
            }
        }

        return arrReturn;
    }
    return <>
        {!medias || Object.keys(medias).length === 0 && <p>Nenhuma Imagem até o momento</p>}
        {medias && Object.keys(medias).length > 0 && (
            <div>
                <h6>Preview</h6>
                <div className="d-flex flex-wrap">
                    {renderMedias(medias)}
                </div>
            </div>
        )}
    </>;
}

ImagesSquare.propTypes = {
    medias: PropTypes.object,
    onGoModalFoto: PropTypes.func,
}

export default ImagesSquare;