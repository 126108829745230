import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_MODALITIES } from "./actionTypes"
import { getModalitiesSuccess, getModalitiesFail } from "./actions"

import {
  getModalities,
} from '../../api/modalities';

function* fetchModalities() {
  try {
    const response = yield call(getModalities)    
    yield put(getModalitiesSuccess(response.data.modalities))
  } catch (error) {
    yield put(getModalitiesFail(error))
  }
}

function* tasksSaga() {
  yield takeEvery(GET_MODALITIES, fetchModalities)
}

export default tasksSaga
