import {
   GET_CUSTOMERS,
   GET_CUSTOMERS_FAIL,
   GET_CUSTOMERS_SUCCESS,
   ADD_NEW_CUSTOMER,
   ADD_CUSTOMER_SUCCESS,
   ADD_CUSTOMER_FAIL,
   UPDATE_CUSTOMER,
   UPDATE_CUSTOMER_SUCCESS,
   UPDATE_CUSTOMER_FAIL,
   DELETE_CUSTOMER,
   DELETE_CUSTOMER_SUCCESS,
   DELETE_CUSTOMER_FAIL,
   GET_TOTAL_CUSTOMERS,
   GET_TOTAL_CUSTOMERS_SUCCESS,
   GET_TOTAL_CUSTOMERS_FAIL,
   GET_CUSTOMER_DETAIL,
   GET_CUSTOMER_DETAIL_SUCCESS,
   GET_CUSTOMER_DETAIL_FAIL,
   GET_CUSTOMER_PROPERTIES,
   GET_CUSTOMER_PROPERTIES_SUCCESS,
   GET_CUSTOMER_PROPERTIES_FAIL,
   GET_CUSTOMER_SCHEDULE,
   GET_CUSTOMER_SCHEDULE_SUCCESS,
   GET_CUSTOMER_SCHEDULE_FAIL,
   GET_CUSTOMER_MEDIAS,
   GET_CUSTOMER_MEDIAS_SUCCESS,
   GET_CUSTOMER_MEDIAS_FAIL,
   GET_CUSTOMER_TOKENS,
   GET_CUSTOMER_TOKENS_SUCCESS,
   GET_CUSTOMER_TOKENS_FAIL,
   GET_CUSTOMER_SUBSCRIBES,
   GET_CUSTOMER_SUBSCRIBES_SUCCESS,
   GET_CUSTOMER_SUBSCRIBES_FAIL,
   GET_CUSTOMERS_SEARCH,
   GET_CUSTOMERS_SEARCH_SUCCESS,
   GET_CUSTOMERS_SEARCH_FAIL,
   GET_CUSTOMERS_SEARCH_CLEAN,
   GET_CUSTOMER_SUBSCRIBES_ADD,
   GET_CUSTOMER_SUBSCRIBES_ADD_SUCCESS,
   GET_CUSTOMER_SUBSCRIBES_ADD_FAIL,
   GET_CUSTOMER_SUBSCRIBES_EDIT,
   GET_CUSTOMER_SUBSCRIBES_EDIT_SUCCESS,
   GET_CUSTOMER_SUBSCRIBES_EDIT_FAIL
} from "./actionTypes"

export const getTotalCustomers = () => ({
   type: GET_TOTAL_CUSTOMERS,
})

export const getTotalCustomersSuccess = customers => ({
   type: GET_TOTAL_CUSTOMERS_SUCCESS,
   payload: customers,
})

export const getTotalCustomersFail = error => ({
   type: GET_TOTAL_CUSTOMERS_FAIL,
   payload: error,
})

export const getCustomers = () => ({
   type: GET_CUSTOMERS,
})

export const getCustomersSuccess = customers => ({
   type: GET_CUSTOMERS_SUCCESS,
   payload: customers,
})

export const getCustomersFail = error => ({
   type: GET_CUSTOMERS_FAIL,
   payload: error,
})

export const addNewCustomer = customer => ({
   type: ADD_NEW_CUSTOMER,
   payload: customer,
})

export const addCustomerSuccess = customer => ({
   type: ADD_CUSTOMER_SUCCESS,
   payload: customer,
})

export const addCustomerFail = error => ({
   type: ADD_CUSTOMER_FAIL,
   payload: error,
})

export const updateCustomer = customer => ({
   type: UPDATE_CUSTOMER,
   payload: customer,
})

export const updateCustomerSuccess = customer => ({
   type: UPDATE_CUSTOMER_SUCCESS,
   payload: customer,
})

export const updateCustomerFail = error => ({
   type: UPDATE_CUSTOMER_FAIL,
   payload: error,
})

export const deleteCustomer = customer => ({
   type: DELETE_CUSTOMER,
   payload: customer,
})

export const deleteCustomerSuccess = customer => ({
   type: DELETE_CUSTOMER_SUCCESS,
   payload: customer,
})

export const deleteCustomerFail = error => ({
   type: DELETE_CUSTOMER_FAIL,
   payload: error,
})

export const getCustomerDetail = idCliente => ({
   type: GET_CUSTOMER_DETAIL,
   idCliente
})

export const geCustomerDetailSuccess = customers => ({
   type: GET_CUSTOMER_DETAIL_SUCCESS,
   payload: customers,
})

export const geCustomerDetailFail = error => ({
   type: GET_CUSTOMER_DETAIL_FAIL,
   payload: error,
})

export const getCustomerProperties = idCliente => ({
   type: GET_CUSTOMER_PROPERTIES,
   idCliente
})

export const getCustomerPropertiesSuccess = customers => ({
   type: GET_CUSTOMER_PROPERTIES_SUCCESS,
   payload: customers,
})

export const getCustomerPropertiesFail = error => ({
   type: GET_CUSTOMER_PROPERTIES_FAIL,
   payload: error,
}) 
export const getCustomerSchedule = idCliente => ({
   type: GET_CUSTOMER_SCHEDULE,
   idCliente
})

export const getCustomerSheduleSuccess = customers => ({
   type: GET_CUSTOMER_SCHEDULE_SUCCESS,
   payload: customers,
})

export const getCustomerScheduleFail = error => ({
   type: GET_CUSTOMER_SCHEDULE_FAIL,
   payload: error,
})

export const getCustomerMedias = idCliente => ({
   type: GET_CUSTOMER_MEDIAS,
   idCliente
})

export const getCustomerMediasSuccess = medias => ({
   type: GET_CUSTOMER_MEDIAS_SUCCESS,
   payload: medias,
})

export const getCustomerMediasFail = error => ({
   type: GET_CUSTOMER_MEDIAS_FAIL,
   payload: error,
})

export const getCustomerTokens = idCliente => ({
   type: GET_CUSTOMER_TOKENS,
   idCliente
})

export const getCustomerTokensSuccess = tokens => ({
   type: GET_CUSTOMER_TOKENS_SUCCESS,
   payload: tokens,
})

export const getCustomerTokensFail = error => ({
   type: GET_CUSTOMER_TOKENS_FAIL,
   payload: error,
})


export const getCustomerSubscribe = idCliente => ({
   type: GET_CUSTOMER_SUBSCRIBES,
   idCliente
})

export const geCustomerSubscribeSuccess = customers => ({
   type: GET_CUSTOMER_SUBSCRIBES_SUCCESS,
   payload: customers,
})

export const geCustomerSubscribeFail = error => ({
   type: GET_CUSTOMER_SUBSCRIBES_FAIL,
   payload: error,
})

export const getCustomersSearch = search => ({
   type: GET_CUSTOMERS_SEARCH,
   search
})

export const geCustomersSearchSuccess = customers => ({
   type: GET_CUSTOMERS_SEARCH_SUCCESS,
   payload: customers,
})

export const geCustomersSearchFail = error => ({
   type: GET_CUSTOMERS_SEARCH_FAIL,
   payload: error,
})

export const getCustomersSearchClean = () => ({
   type: GET_CUSTOMERS_SEARCH_CLEAN
})

export const addCustomersSubscribe = (objToSave) => ({
   type: GET_CUSTOMER_SUBSCRIBES_ADD,
   objToSave
})

export const addCustomersSubscribeSuccess = customers => ({
   type: GET_CUSTOMER_SUBSCRIBES_ADD_SUCCESS,
   payload: customers,
})

export const addCustomersSubscribeFail = error => ({
   type: GET_CUSTOMER_SUBSCRIBES_ADD_FAIL,
   payload: error,
})

export const editCustomersSubscribe = (objToSave) => ({
   type: GET_CUSTOMER_SUBSCRIBES_EDIT,
   objToSave
})

export const editCustomersSubscribeSuccess = customers => ({
   type: GET_CUSTOMER_SUBSCRIBES_EDIT_SUCCESS,
   payload: customers,
})

export const editCustomersSubscribeFail = error => ({
   type: GET_CUSTOMER_SUBSCRIBES_EDIT_FAIL,
   payload: error,
})