/* Workouts Levels */
export const GET_WORKOUTSLEVELS = "GET_WORKOUTSLEVELS"
export const GET_WORKOUTSLEVELS_SUCCESS = "GET_WORKOUTSLEVELS_SUCCESS"
export const GET_WORKOUTSLEVELS_FAIL = "GET_WORKOUTSLEVELS_FAIL"

/**
 * add Workouts Levels
 */
export const ADD_NEW_WORKOUTSLEVELS = "ADD_NEW_WORKOUTSLEVELS"
export const ADD_WORKOUTSLEVELS_SUCCESS = "ADD_WORKOUTSLEVELS_SUCCESS"
export const ADD_WORKOUTSLEVELS_FAIL = "ADD_WORKOUTSLEVELS_FAIL"

/**
 * Edit Workouts Levels
 */
export const UPDATE_WORKOUTSLEVELS = "UPDATE_WORKOUTSLEVELS"
export const UPDATE_WORKOUTSLEVELS_SUCCESS = "UPDATE_WORKOUTSLEVELS_SUCCESS"
export const UPDATE_WORKOUTSLEVELS_FAIL = "UPDATE_WORKOUTSLEVELS_FAIL"

/**
 * Delete Workouts Levels
 */
export const DELETE_WORKOUTSLEVELS = "DELETE_WORKOUTSLEVELS"
export const DELETE_WORKOUTSLEVELS_SUCCESS = "DELETE_WORKOUTSLEVELS_SUCCESS"
export const DELETE_WORKOUTSLEVELS_FAIL = "DELETE_WORKOUTSLEVELS_FAIL"